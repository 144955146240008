import { Button, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { SearchRequestModel } from '../../../Models/DataModels/Common/SearchModels'
import { GFDatabaseRequest, ConstituentMembershipRequest, EventsInTimeRequest } from '../../../Models/DataModels/Requests/SearchRequests'
import { searchGFDatabase, searchGFDIndices, searchRealEstateDatabase, searchUSStocks, searchUKStocks, searchUniverse, searchConstituentMembership, searchEventsInTime, searchGFDatabase2Point0 } from '../../../Services/SearchService'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { ErrorHandler, ErrorHandlerProps } from '../../Common/Utility/ErrorHandler'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { SubscriptionFilterType } from '../../../Models/DataModels/Common/SubscriptionFilter'
import ExporttoList from '../../Icons/ExporttoListIcon'
import { SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'
import { FileDownloadRequest } from '../../../Models/DataModels/Requests/ToolsRequests'
import { downloadFileFromServer } from '../../../Services/DownloadService'
import { GFDToastSuccess } from '../../Common/Utility/GFDToastify'
import ExportOutputFormatSelectionModal from '../../Common/Modals/ExportOutputFormatSelectionModal'
import { DateFormatValueType } from '../../../Models/DataModels/Common/DateFormatModel'

export interface SearchResultExportProps {
    searchRequestModel: SearchRequestModel | null,
    subSearchTerm: any,
    sortProps: any,
    subscriptionFilter: SubscriptionFilterType,
    totalCount: number,
    displayText?: string,
    signOut: (logoutReason: LogoutReasonType) => void
}

const SearchResultExport = ({
    searchRequestModel,
    subSearchTerm,
    sortProps,
    subscriptionFilter,
    totalCount,
    displayText,
    signOut
}: SearchResultExportProps) => {
    const isDisabled = (): boolean => {
        return (exportInProgress
            || !searchRequestModel
            || !searchRequestModel?.request
            || !searchRequestModel?.requestType
            || !totalCount)
    }

    const isDisabledForEventsInTime = (): boolean => {
        return (totalCount >= 100
            && searchRequestModel?.requestType === SearchDatabaseTypes.EventsInTime)
    }

    const [exportInProgress, setExportInProgress] = useState<boolean>(false)
    const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
    const [showExportOutputFormatModal, setShowExportOutputFormatModal] = useState<boolean>(false)

    const exportSearchResults = (outputDateFormat: DateFormatValueType) => {
        // if (isDisabled() || isDisabledForEventsInTime()) {
        //     return
        // }
        const request: any = {
            ...searchRequestModel?.request,
            pagination: null,
            filterResultsBySubSearchTerm: subSearchTerm,
            sortOrder: sortProps.sortOrder,
            sortField: sortProps.sortField,
            subscriptionFilter,
            dateFormat: outputDateFormat
        }
        setExportInProgress(true)

        switch (searchRequestModel?.requestType) {
            case SearchDatabaseTypes.ConstituentMembership:
                _exportFromConstituentMembership(request as ConstituentMembershipRequest)
                break
            case SearchDatabaseTypes.EventsInTime:
                _exportFromEventsInTime(request as EventsInTimeRequest)
                break
            case SearchDatabaseTypes.GFDatabase2Point0:
                _exportFromGFDatabase2Point0(request as GFDatabaseRequest)
                break
            case SearchDatabaseTypes.GFDatabase:
                _exportFromGFDatabase(request as GFDatabaseRequest)
                break
            case SearchDatabaseTypes.GFDIndices:
                _exportFromGFDIndices(request as GFDatabaseRequest)
                break
            case SearchDatabaseTypes.RealEstate:
                _exportFromRealEstateDatabase(request as GFDatabaseRequest)
                break
            case SearchDatabaseTypes.UKStocks:
                _exportFromUKStocks(request as GFDatabaseRequest)
                break
            case SearchDatabaseTypes.USStocks:
                _exportFromUSStocks(request as GFDatabaseRequest)
                break
            case SearchDatabaseTypes.GFDUniverse:
                _exportFromUniverse(request as GFDatabaseRequest)
                break
            default: // should not hit this
                setExportInProgress(false)
                break
        }
    }

    const errorHandlerProps: ErrorHandlerProps = {
        response: errorResponse,
        signOut: signOut
    }

    const processResponse = (downloadRequest: FileDownloadRequest) => {
        const onComplete = (result: boolean) => {
            if (result) GFDToastSuccess(`${downloadRequest?.filename} downloaded!`)
            setExportInProgress(false)
        }

        downloadFileFromServer(downloadRequest, processErrorResponse, onComplete)
    }

    const processErrorResponse = (notOKResponseModel: NotOKResponseModel | null) => {
        setExportInProgress(false)
        if (notOKResponseModel?.statusCode !== 499) {
            setErrorResponse(notOKResponseModel)
        }
    }

    const _exportFromGFDatabase2Point0 = (searchRequest: GFDatabaseRequest) => {
        searchGFDatabase2Point0(searchRequest, true).then(
            (response: FileDownloadRequest) => {
                processResponse(response)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _exportFromGFDatabase = (searchRequest: GFDatabaseRequest) => {
        searchGFDatabase(searchRequest, true).then(
            (response: FileDownloadRequest) => {
                processResponse(response)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _exportFromGFDIndices = (searchRequest: GFDatabaseRequest) => {
        searchGFDIndices(searchRequest, true).then(
            (response: FileDownloadRequest) => {
                processResponse(response)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _exportFromRealEstateDatabase = (searchRequest: GFDatabaseRequest) => {
        searchRealEstateDatabase(searchRequest, true).then(
            (response: FileDownloadRequest) => {
                processResponse(response)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _exportFromUSStocks = (searchRequest: GFDatabaseRequest) => {
        searchUSStocks(searchRequest, true).then(
            (response: FileDownloadRequest) => {
                processResponse(response)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _exportFromUKStocks = (searchRequest: GFDatabaseRequest) => {
        searchUKStocks(searchRequest, true).then(
            (response: FileDownloadRequest) => {
                processResponse(response)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _exportFromUniverse = (searchRequest: GFDatabaseRequest) => {
        searchUniverse(searchRequest, true).then(
            (response: FileDownloadRequest) => {
                processResponse(response)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _exportFromConstituentMembership = (searchRequest: ConstituentMembershipRequest) => {
        searchConstituentMembership(searchRequest, true).then(
            (response: FileDownloadRequest) => {
                processResponse(response)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const _exportFromEventsInTime = (searchRequest: EventsInTimeRequest) => {
        searchEventsInTime(searchRequest, true).then(
            (response: FileDownloadRequest) => {
                processResponse(response)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const disabledProp = isDisabled() || isDisabledForEventsInTime() ? {
        disabled: true
    } : {}

    const exportLimitation = 'Only search results of 100 events or less will be allowed to be exported.'
    return <>
        <ErrorHandler {...errorHandlerProps} />
        {exportInProgress ?
            <Tooltip title='Export in Progress'>
                <span>
                    <IconButton aria-label='Export in Progress' component='label' style={{
                        ...styles.iconButton,
                        padding: '10px',
                        color: '#1976d2'
                    }}>
                        <CircularProgress aria-label='Export in Progress' />
                    </IconButton>
                </span>
            </Tooltip>
            :
            isDisabledForEventsInTime() ?
                <Typography sx={{ color: 'darkred', fontStyle: 'italic', paddingTop: '36px' }}>
                    {exportLimitation}
                </Typography>
                :
                <Tooltip title={'Export All Search Results'} >
                    <div>
                        <Button
                            variant='text'
                            {...disabledProp}
                            onClick={() => {
                                if (isDisabled() || isDisabledForEventsInTime()) {
                                    return
                                }
                                setShowExportOutputFormatModal(true)
                            }}
                            style={styles.iconButton}
                            aria-label='Export All Search Results'>
                            {displayText && <Typography fontSize={16} paddingRight={'6px'}>{displayText}</Typography>}
                            <ExporttoList
                                style={{ fontSize: '1.25em', width: '30px', height: '30px' }}
                            />
                        </Button>
                    </div>
                </Tooltip>
        }
        <ExportOutputFormatSelectionModal show={showExportOutputFormatModal} setShow={setShowExportOutputFormatModal} exportAction={exportSearchResults} />
    </>
}

const styles = {
    iconButton: {
        marginLeft: '8px',
        marginRight: '8px',
        marginTop: '15px'
    }
}

export default SearchResultExport
