import { createTheme } from '@mui/material/styles'
import { globalStyles } from './GlobalStyles'

const DefaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#6fcad1',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {color: '#000000'}
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "p[class^='MuiTablePagination']": {
            margin: 'auto'
          },
          "p[class^='MuiTablePagination-displayedRows']": {
            display: 'inline'
          }
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "tr:nth-of-type(2n+1)": {
            backgroundColor: 'gainsboro' // grey looking color - previously #ebf0fa
          },
          "tr:hover td": {
            backgroundColor: '#cdf9f4' // light teal leaves color - previously #eefbfc
          },
          "th": {
            fontWeight: 'bold',
            backgroundColor: '#cdf9f4' // light teal leaves color - previously #ebfafa
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
          paddingTop: '0px',
          paddingBottom: '0px'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {fontSize: '1.25em'}
      }
    },
    MuiCheckbox: {
      styleOverrides: {
          colorPrimary: {
              color: "rgba(0,0,0,0.6)",
              "&.Mui-checked": {
                  color: globalStyles.siteThemeColor,
              }
          }
      }
  },
  MuiButtonBase: {
    styleOverrides: {
        root: {
            color: globalStyles.siteThemeColor,
            "&.MuiCheckbox-indeterminate": {
                color: `${globalStyles.siteThemeColor} !important`
            }
        }
    }
  }
  }
})

export default DefaultMaterialTheme
