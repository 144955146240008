export const ConfigKeys = {
    maintenanceMode: 'maintenanceMode',
    maintenanceModeMessage: 'maintenanceModeMessage',
    siteNotificationMessage: 'siteNotificationMessage',
    trialUserAccessMessageHTML: 'trialUserAccessMessageHTML'
}

export enum ApplicationType {
    Web = 0,
    API = 1,
    Excel = 2,
    Unknown = 999
}

export enum ApplicationNameType {
    APICore,
    FinaeonReact
}

export const companyInfo = {
    companyName: 'Finaeon',
    companyLegalName: 'Finaeon, Inc.',
    address: {
        street: '2 Venture',
        number: 'Suite 120',
        city: 'Irvine',
        state: 'CA',
        zip: '92618'
    },
    salesEmailAddress: 'sales@finaeon.com',
    salesPhoneNumberFormatted: '(949) 542-4200',
    salesPhoneNumberDashFormatted: '949-542-4200',
    salesPhoneNumberLink: '+1(949)542-4200'
}

export const trailUserAccount: string = 'tryapi@finaeon.com'

export const AppConfigurations = {
    extraLoggingEnabled: (process.env.REACT_APP_EXTRALOGGING && process.env.REACT_APP_EXTRALOGGING.toLowerCase() === 'true') || false,
    // Max age of Cookie // Example 3600 seconds = 60 minutes
    CookieMaxAgeInSeconds: 3600 as number,
    // Max age of Session // Example 1200 seconds = 20 minutes // 60 recommended for Test
    SessionMaxAgeInSeconds: 1200 as number,
    // Token will be refreshed if token expires in less than TokenRefreshThresholdInMinutes. Value must be lower than CookieMaxAgeInMinutes but higher than WarningCountDownInMinutes.
    // Example: 300 Seconds = 5 minutes, 50 recommended for Test
    TokenRefreshThresholdInSeconds: 300 as number,
    // Count down time of Session Warning in Minutes. Value must be lower than Cookie Max Age. Note: Warning Modal Displays in (CookieMaxAgeInMinutes - WarningCountDownInMinutes) minutes.
    // Example: 120 Seconds = 2 minutes, 40 recommended for Test
    WarningCountDownInSeconds: 120 as number,
    autoTracDataCountLimit: 1200000 as number,
    workbooksPerPage: 100,
    currencyConverterLineGraphCountLimit: 5 as number,
    chartLabelDisplayThreshold: 18 as number,
    dateFormat: 'MM/DD/YYYY',
    workbookNameRegEx: new RegExp('^[a-zA-Z0-9- _().&%?,:+]*$'),
    maximumTickers: 300,
    maximumCustomIndexMembers: 300,
    applicationType: ApplicationType.Web,
    applicationName: 'Finaeon React',
    applicationNameType: ApplicationNameType.FinaeonReact,
    blobURL: process.env.REACT_APP_AZURE_BLOB_URL || 'https://gfdpolicies.blob.core.windows.net/',
    privacyPolicyPDFURL: process.env.REACT_APP_PRIVACY_POLICY_PDF_URL || 'https://gfdpolicies.blob.core.windows.net/legal/PrivacyPolicy.pdf',
    cookiePolicyPDFURL: process.env.REACT_APP_COOKIE_POLICY_PDF_URL || 'https://gfdpolicies.blob.core.windows.net/legal/CookiePolicy.pdf',
    termsPDFURL: process.env.REACT_APP_TERMS_PDF_URL || 'https://gfdpolicies.blob.core.windows.net/legal/Terms.pdf',
    minimumDate: '01/01/1000',
    labelValueStorageExpirationInDays: 7 as number,
    selectedSeriesChartingLimit: 50 as number,
    selectedResutltsBarChartLegendDisplayThreshold: 75 as number,
    chronologyAreaLabelDisplayLimit: 32 as number,
    apiDocsURL: process.env.REACT_APP_APIDOCS_URL || 'https://localhost:44354/'
}

export const chartSizes = {
    small: 240,
    standard: 480,
    medium: 720,
    large: 960
}

export const limitInfoMessage = `Only a maximum of ${AppConfigurations.maximumTickers} tickers can be selected.`

export const regExFor9DigitsPlus2DecimalPlaces = new RegExp('^[0-9]{0,9}(\\.[0-9]{0,2})?$')
export const regExFor3DigitsPlus2DecimalPlaces = new RegExp('^[0-9]{0,3}(\\.{1,1}[0-9]{0,2})?$')
