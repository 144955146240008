import { Autocomplete, Button, ButtonGroup, Divider, MenuItem, Paper, TextField, Select, SelectChangeEvent } from '@mui/material'
import { useState, useEffect } from 'react'
import ChangePasswordModal from '../../Common/Modals/ChangePasswordModal'
import { getUser, editUserInfo } from '../../../Services/BasicInfoService'
import { PopulateCountryRequest } from '../../../Models/DataModels/Requests/PopulationRequests'
import { NewUserInfoRequest } from "../../../Models/DataModels/Requests/NewUserInfoRequests"
import { populateCountry2D } from '../../../Services/FieldPopulationService'
import Grid from '@mui/material/Grid'
import { CustomBtnOutlined, CustomBtnBlue } from '../../Common/GlobalSettings/CustomStyles'

const BasicInfo = () => {
  const [showBasicInfo, setShowBasicInfo] = useState<boolean>(true)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [fullName, setFullName] = useState<string>()

  const [position, setPosition] = useState<string>('')
  const [newPosition, setNewPosition] = useState<string>('')

  const [orgName, setOrgName] = useState<string>()
  const [address, setAddress] = useState<string>('')

  const [street, setStreet] = useState<string>()
  const [newStreet, setNewStreet] = useState<string>()

  const [street2, setStreet2] = useState<string>('')
  const [newStreet2, setNewStreet2] = useState<string>('')

  const [city, setCity] = useState<string>()
  const [newCity, setNewCity] = useState<string>()

  const [state, setState] = useState<string>()
  const [newState, setNewState] = useState<string>()

  const [postalZone, setPostalZone] = useState<string>()
  const [newPostalZone, setNewPostalZone] = useState<string>()

  const [phone, setPhone] = useState<string>('')
  const [newPhone, setNewPhone] = useState<string>('')

  const [email, setEmail] = useState<string>()
  const [orgType, setOrgType] = useState<string>()

  const [country, setCountry] = useState<string>('')
  const [countryCode, setCountryCode] = useState<string>('')
  const [newCountry, setNewCountry] = useState<string>('')
  const [newCountryCode, setNewCountryCode] = useState<string>('')

  const [countryList, setCountryList] = useState<any[]>([])

  useEffect(() => {
    const request: PopulateCountryRequest = {
      database: 'GFDatabase'
    }
    populateCountry2D(request).then(
      (result: any) => {
        setCountryList([...countryList, ...result])
      },
      //Reject promise
      () => {
        console.log('rejected promise!')
      }
    )
    getUser().then(
      (res) => {

        const firstName: string = res?.firstName || ''
        const lastName: string = res?.lastName || ''
        const fullName: string = `${firstName} ${lastName}`.trim()

        setFirstName(firstName)
        setLastName(lastName)
        setFullName(fullName)

        setPosition(res?.position || '')
        setNewPosition(res?.position || '')

        setOrgName(res?.orgName || '')

        setPhone(res?.phone || '')
        setNewPhone(res?.phone || '')

        setEmail(res?.email || '')
        setOrgType(res?.orgType || '')

        //setAddress(res.address)

        var userAddress = res?.address?.split(' \r') || ''
        var plus = 0

        if (userAddress.length === 1) {
          userAddress = res.address.split('\r')
        }

        if (userAddress.length === 1) {
          userAddress = res.address.split(' \n')
        }

        if (userAddress.length === 1) {
          userAddress = res.address.split('\n')
        }

        setStreet(userAddress[0].replace(",", ""))
        setNewStreet(userAddress[0].replace(",", ""))

        if (userAddress.length === 5) {
          plus = 1
          setStreet2(userAddress[1].replace(",", ""))
          setNewStreet2(userAddress[1].replace(",", ""))
        }

        setCity(userAddress[1 + plus].substring(0, userAddress[1 + plus].indexOf(',')))
        setNewCity(userAddress[1 + plus].substring(0, userAddress[1 + plus].indexOf(',')))

        setState(userAddress[1 + plus].substring(userAddress[1 + plus].indexOf(',') + 1,).replace(" ", ""))
        setNewState(userAddress[1 + plus].substring(userAddress[1 + plus].indexOf(',') + 1,).replace(" ", ""))

        setPostalZone(userAddress[2 + plus])
        setNewPostalZone(userAddress[2 + plus])

        setCountryCode(userAddress[3 + plus].replace(" ", ""))
        setNewCountryCode(userAddress[3 + plus].replace(" ", ""))

        if (userAddress.length === 5) {
          setAddress(userAddress[0].replace(",", "") + ', \r' +
            userAddress[1].replace(",", "") + ', \r' +
            userAddress[1 + plus].substring(0, userAddress[1 + plus].indexOf(',')) + ', ' +
            userAddress[1 + plus].substring(userAddress[1 + plus].indexOf(',') + 1,).replace(" ", "") + ' \r' +
            userAddress[2 + plus] + ' \r' +
            userAddress[3 + plus].replace(" ", ""))
        }
        else {
          setAddress(userAddress[0].replace(",", "") + ' \r' +
            userAddress[1 + plus].substring(0, userAddress[1 + plus].indexOf(',')) + ', ' +
            userAddress[1 + plus].substring(userAddress[1 + plus].indexOf(',') + 1,).replace(" ", "") + ' \r' +
            userAddress[2 + plus] + ' \r' +
            userAddress[3 + plus].replace(" ", ""))
        }
      },
      () => {
      }
    )

  }, [])


  //Most likely will pass a useEffect hook into this component with the user info, that will populate both the pages
  const toggleEdit = () => {
    setShowBasicInfo(!showBasicInfo)
    findCountry(countryCode)
  }

  const handleSave = () => {

    setPosition(newPosition)
    setPhone(newPhone)
    setStreet(newStreet)
    setCity(newCity)
    setState(newState)
    setPostalZone(newPostalZone)
    setCountryCode(newCountryCode)
    setCountry(newCountry)
    const userInfo: NewUserInfoRequest = { position: newPosition, phone: newPhone, address: '' }
    if (newStreet2 !== "") {
      setStreet2(newStreet2)
      setAddress(newStreet + ', \n' + newStreet2 + ', \n' + newCity + ', ' + newState + '\n' + newPostalZone + '\n' + newCountryCode)
      userInfo.address = newStreet + '\n' + newStreet2 + '\n' + newCity + ', ' + newState + '\n' + newPostalZone + '\n' + newCountryCode
    }
    else {
      setStreet2("")
      setAddress(newStreet + '\n' + newCity + ', ' + newState + ' \n' + newPostalZone + ' \n' + newCountryCode)
      userInfo.address = newStreet + '\n' + newCity + ', ' + newState + '\n' + newPostalZone + '\n' + newCountryCode
    }
    editUserInfo(userInfo).then(
      result => { },
      () => { }
    ).catch(error => {
      console.error('doLoginRequest Error: ', error.toString())
    })
    setShowBasicInfo(!showBasicInfo)
  }

  const findCountry = (cc: string) => {
    countryList.map((c) => {
      if (c.value === cc) {
        setCountry(c.label)
        setNewCountry(c.label)
      }
    })
  }

  const showChangePassword = () => {

    setShowModal(!showModal)
  }




  return (
    <div>
      {showBasicInfo ?

        <div id='basicInfoCard'>
          <h4>Basic Info</h4>

          <Paper elevation={3} style={{ padding: 5 }}>
            {/* Add flex to each of these divs with space between or something similar to replicate the original */}

            <Grid container columnSpacing={0} columns={{ xs: 1, sm: 1, md: 3 }} style={{ padding: '5px' }}>
              <Grid item xs={1} sm={1} md={1}>
                <Grid container columnSpacing={0} columns={{ xs: 1, sm: 1, md: 2 }} style={{ padding: '5px' }}>
                  <Grid item xs={1} sm={1} md={1}>
                    <strong><p>Full Name: </p></strong>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <p>{fullName}</p>
                  </Grid>
                </Grid>

                <Grid container columnSpacing={0} columns={{ xs: 1, sm: 1, md: 2 }} style={{ padding: '5px' }}>
                  <Grid item xs={1} sm={1} md={1}>
                    <strong><p>Position: </p></strong>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <p>{position}</p>
                  </Grid>
                </Grid>

                <Grid container columnSpacing={0} columns={{ xs: 1, sm: 1, md: 2 }} style={{ padding: '5px' }}>
                  <Grid item xs={1} sm={1} md={1}>
                    <strong><p>OrgName: </p></strong>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <p>{orgName}</p>
                  </Grid>
                </Grid>

                <Grid container columnSpacing={0} columns={{ xs: 1, sm: 1, md: 2 }} style={{ padding: '5px' }}>
                  <Grid item xs={1} sm={1} md={1}>
                    <strong><p>Address: </p></strong>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <p>{address}</p>
                  </Grid>
                </Grid>

                <Grid container columnSpacing={0} columns={{ xs: 1, sm: 1, md: 2 }} style={{ padding: '5px' }}>
                  <Grid item xs={1} sm={1} md={1}>
                    <strong><p>Phone: </p></strong>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <p>{phone}</p>
                  </Grid>
                </Grid>

                <Grid container columnSpacing={0} columns={{ xs: 1, sm: 1, md: 2 }} style={{ padding: '5px' }}>
                  <Grid item xs={1} sm={1} md={1}>
                    <strong><p>Email: </p></strong>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <p>{email}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <ButtonGroup variant='contained' aria-label='outlined primary button group' style={{ padding: '20px' }}>
            <CustomBtnOutlined variant='outlined' onClick={toggleEdit}>Edit</CustomBtnOutlined>
            <CustomBtnBlue variant='contained' style={{ marginLeft: 5 }} onClick={showChangePassword}>Change Password</CustomBtnBlue>
          </ButtonGroup>
        </div>
        :
        <div id='basicInfoEdit'>
          <Paper elevation={3} style={{ padding: 5 }}>
            <h4>Edit my Information</h4>
            <Divider sx={{ borderColor: 'black', marginBottom: 5 }} />
            <div style={basicInfoStyles.textRow}>
              <div style={basicInfoStyles.paddingRight}>
                <TextField id='standard-basic' disabled label='First Name' variant='outlined' value={firstName}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' },

                  }} />
              </div>
              <TextField id='standard-basic' disabled label='Last Name' variant='outlined' value={lastName}
                InputLabelProps={{
                  shrink: true,
                  style: { fontWeight: 'bold' }
                }} />
            </div>
            <div style={basicInfoStyles.textRow}>
              <div style={basicInfoStyles.paddingRight}>
                <TextField id='standard-basic' disabled label='OrgName' variant='outlined' value={orgName}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }} />
              </div>
              <div style={{ width: '100%', maxWidth: 240 }}>
                <TextField
                  label="OrgType"
                  value={orgType}
                  select
                  fullWidth
                  disabled
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                >
                  <MenuItem value={'Business'}>Business</MenuItem>
                  <MenuItem value={'Personal'}>Personal</MenuItem>
                </TextField>
              </div>
            </div>
            <div style={basicInfoStyles.textRow}>
              <div style={{ width: '100%', maxWidth: 240 }}>
                <TextField id='standard-basic' disabled label='Email' variant='outlined' value={email}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                  sx={{
                    width: { md: 400 }
                  }} />
              </div>
            </div>
            <div style={basicInfoStyles.textRow}>
              <div style={basicInfoStyles.paddingRight}>
                <TextField id='standard-basic' label='Position (Optional)' variant='outlined' defaultValue={position}
                  onChange={(e) => {
                    setNewPosition(e.target.value)
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }} />
              </div>
            </div>
            <div style={basicInfoStyles.textRow}>
              <div style={basicInfoStyles.paddingRight}>
                <TextField id='standard-basic' label='Street 1 (Optional)' variant='outlined' defaultValue={street}
                  onChange={(e) => {
                    setNewStreet(e.target.value)
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                />
              </div>
              <TextField id='standard-basic' label='Street 2 (Optional)' variant='outlined' defaultValue={street2}
                onChange={(e) => {
                  setNewStreet2(e.target.value)
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontWeight: 'bold' }
                }} />
            </div>
            <div style={basicInfoStyles.textRow}>
              <div style={basicInfoStyles.paddingRight}>
                <TextField id='standard-basic' label='City' variant='outlined' defaultValue={city}
                  onChange={(e) => {
                    setNewCity(e.target.value)
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }} />
              </div>
              <div style={basicInfoStyles.paddingRight}>
                <TextField id='standard-basic' label='State/Province' variant='outlined' defaultValue={state}
                  onChange={(e) => {
                    setNewState(e.target.value)
                  }}
                  inputProps={{ maxLength: 2 }}

                  InputLabelProps={{
                    shrink: true,

                    style: { fontWeight: 'bold' }
                  }} />
              </div>
              <div style={basicInfoStyles.paddingRight}>
                <TextField id='standard-basic' label='Postal Zone' variant='outlined' defaultValue={postalZone}
                  onChange={(e) => {
                    setNewPostalZone(e.target.value)
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }} />
              </div>

              <Autocomplete
                id='famaSectorSelection'
                defaultValue={{ label: country, value: countryCode }}
                options={countryList}
                getOptionLabel={(option) => option.label}
                onChange={(event: any, newValue: { label: string, value: string }) => {
                  setNewCountryCode(newValue.value)
                  setNewCountry(newValue.label)
                }}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Country" />} />
            </div>
            <div style={basicInfoStyles.textRow}>
              <TextField id='standard-basic' label='Phone (Optional)' variant='outlined' defaultValue={phone}
                onChange={(e) => {
                  setNewPhone(e.target.value)
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontWeight: 'bold' }
                }} />
            </div>
            <div style={{ marginBottom: 10, marginTop: 10 }}>
              <CustomBtnBlue variant='contained' style={{ marginLeft: 10 }} onClick={handleSave}>Save</CustomBtnBlue>
              <CustomBtnOutlined variant='outlined' style={{ marginLeft: 25 }} onClick={toggleEdit}>Cancel</CustomBtnOutlined>
            </div>
          </Paper>
        </div>
      }
      <ChangePasswordModal setShow={setShowModal} showModal={showModal} />
    </div>
  )
}

const basicInfoStyles = {
  textRow: {
    display: 'flex',
    gridRow: '1 / span 2',
    paddingTop: 7
  },
  paddingRight: {
    paddingRight: 5
  },
  dropDown: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: '5%'
  }
}

export default BasicInfo