import { Button, CircularProgress, Grid, IconButton, Link, Paper, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import React, { useMemo, useState } from 'react'
import { CapitalizationResultType } from '../../Capitalization'
import { CapitalizationSummary } from '../../../../../../Models/DataModels/Common/ToolsModel'
import MaterialTable, { MTableBody } from '@material-table/core'
import MaterialTableOptions from '../../../../../Common/GlobalSettings/MaterialTableOptions'
import StyledAccordion from '../../../../../Common/Utility/StyledAccordion'
import StyledAccordionSummary from '../../../../../Common/Utility/StyledAccordionSummary'
import StyledAccordionDetails from '../../../../../Common/Utility/StyledAccordionDetails'
import PieGraph from '../../../../../Icons/PieGraphIcon'
import BarGraph from '../../../../../Icons/BarGraph'
import ExporttoList from '../../../../../Icons/ExporttoListIcon'
import CapSummaryChartDisplayModal, { CapSummaryChartDisplayModalProps, CapSummaryChartTypes } from './CapSummaryChartDisplayModal'
import CapSummaryPercentAreaChart from './CapSummaryPercentAreaChart'
import { NotOKResponseModel } from '../../../../../../Models/DataModels/Common/NotOKResponseModel'
import OutputFormatValue from '../../../../../../Models/DataModels/Common/OutputFormatModel'
import { CreateDataFileRequest, FileDownloadRequest, GetCapitalizationSummaryRequest } from '../../../../../../Models/DataModels/Requests/ToolsRequests'
import { CreateDataFileResponse } from '../../../../../../Models/DataModels/Responses/ToolsResponses'
import { downloadFileFromServer } from '../../../../../../Services/DownloadService'
import { createDownloadDataFile, exportCapitalizationSummary } from '../../../../../../Services/ToolsService'
import { UserInfo } from '../../../../../../Models/DataModels/Common/UserInfoModel'
import { getUserInfoFromCookie } from '../../../../../../Services/CookieAccessService'
import { LabelValuePair } from '../../../../../../Models/DataModels/Common/LabelValuePairModel'
import { CustomBtnOutlined, customTheme } from '../../../../../Common/GlobalSettings/CustomStyles'
import { SortOrderStringType, getComparator, sort } from '../../../../../../Models/DataModels/Common/SortModel'
import EnhancedTableHead from '../../../../../Common/Utility/EnhancedTableHead'
import ExportOutputFormatSelectionModal from '../../../../../Common/Modals/ExportOutputFormatSelectionModal'
import { DateFormatValueType } from '../../../../../../Models/DataModels/Common/DateFormatModel'
import moment from 'moment'

export interface CapitalizationSummaryResultsProps {
  resultType: CapitalizationResultType,
  summaryResult: CapitalizationSummary[],
  summaryCategory: string,
  setErrorResponse: (errorResponse: NotOKResponseModel | null) => void,
  doGetCapitalizationListRequest: (selectedSummaryLabel?: string) => void,
  setSummaryLinkClicked: (summaryLinkClicked: boolean) => void,
  selectedCurrency: LabelValuePair,
  selectedIndex: LabelValuePair,
  previousYears: number,
  currentSummaryRequest: GetCapitalizationSummaryRequest | null
}

const CapitalizationSummaryResults = ({
  resultType,
  summaryResult,
  summaryCategory,
  setErrorResponse,
  doGetCapitalizationListRequest,
  setSummaryLinkClicked,
  selectedCurrency,
  selectedIndex,
  previousYears,
  currentSummaryRequest
}: CapitalizationSummaryResultsProps) => {

  const modifyData = (testdata: CapitalizationSummary[]): any[] => {
    let resultTest: any[] = []
    if (testdata?.length > 0) {
      resultTest = testdata.map((element: CapitalizationSummary) => {
        return {
          ...element,
          listViewHyperLink: (
            <Link
              sx={{
                color: '#0d6efd',
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
              onClick={() => {
                setSummaryLinkClicked(true)
                doGetCapitalizationListRequest(element.sector)
              }}
            >{element.sector}</Link>
          )
        }
      })
    }
    return resultTest
  }

  const summaryWithLinkData: any[] = modifyData(summaryResult)

  const [chartExpanded, setChartExpanded] = useState<boolean>(true)
  const [showChart, setShowChart] = useState<boolean>(false)
  const [selectedSummaryData, setSelectedSummaryData] = useState<CapitalizationSummary[]>([])
  const [selectedChartType, setSelectedChartType] = useState<CapSummaryChartTypes>(CapSummaryChartTypes.Bar)
  const [dataSource] = useState<any[]>(summaryWithLinkData)
  const [exportInProgress, setExportInProgress] = useState<boolean>(false)
  const [showExportOutputFormatModal, setShowExportOutputFormatModal] = useState<boolean>(false)
  const [exportAllClicked, setExportAllClicked] = useState<boolean>(true)

  const handleSetChartExpanded = () => {
    setChartExpanded(!chartExpanded)
  }

  const isShowGraphAccordian = (resultOptions: CapitalizationResultType): boolean => {
    if ((resultOptions & CapitalizationResultType.Chart) === CapitalizationResultType.Chart) {
      return true
    }
    return false
  }

  const exportSummaryRequest = (dateFormat: DateFormatValueType) => {
    if (currentSummaryRequest) {
      currentSummaryRequest.dateFormat = dateFormat
      setExportInProgress(true)
      exportCapitalizationSummary(currentSummaryRequest).then(
        (fileCreateResponse: CreateDataFileResponse) => {
          const downloadRequest: FileDownloadRequest = {
            filename: fileCreateResponse.filename,
            mimeType: fileCreateResponse.mimeType
          }
          downloadFileFromServer(downloadRequest, setErrorResponse)
          setExportInProgress(false)
        },
        (notOKResponseModel: NotOKResponseModel) => {
          setErrorResponse(notOKResponseModel)
          setExportInProgress(false)
        }
      )
    }
  }

  const handleExportAllButton = () => {
    setExportAllClicked(true)
    setShowExportOutputFormatModal(true)
  }

  const renderTitleArea = () => {
    return (
      <Grid container spacing={1} columns={16} justifyContent="flex-start" alignItems='center'>
        <Grid item>
          <h5>Summary</h5>
        </Grid>
        <Grid item>
          <CustomBtnOutlined id='pieChartButton' variant='outlined' size='small' sx={{ fontSize: 11 }} startIcon={<PieGraph style={{ fontSize: '2em' }} />} onClick={() => { showPieChart(summaryResult) }} >
            Chart All - Pie
          </CustomBtnOutlined>
        </Grid>
        <Grid item>
          <CustomBtnOutlined id='barChartButton' variant='outlined' size='small' sx={{ fontSize: 11 }} startIcon={<BarGraph style={{ fontSize: '2em' }} />} onClick={() => { showBarChart(summaryResult) }}>
            Chart All - Bar
          </CustomBtnOutlined>
        </Grid>
        {currentSummaryRequest && summaryResult?.length > 0 && (
          <Grid item>
            <CustomBtnOutlined id='exportAllButton' variant='outlined' size='small' sx={{ fontSize: 11 }} startIcon={<ExporttoList style={{ fontSize: '2em' }} />} onClick={handleExportAllButton} disabled={exportInProgress}>
              Export All
            </CustomBtnOutlined>
          </Grid>
        )}
        {exportInProgress && (
          <Grid item>
            <IconButton aria-label='Export in Progress' component='label' sx={{
              p: '10px',
              color: '#1976d2',
              visibility: 'visible'
            }}>
              <CircularProgress title='Export in Progress' aria-label='Export in Progress' />
            </IconButton>
          </Grid>
        )}
      </Grid>
    )
  }

  const showPieChart = (capSummaryData: CapitalizationSummary[]) => {
    setShowChart(true)
    const dataWithoutTotalEntry: CapitalizationSummary[] = capSummaryData.filter((item: CapitalizationSummary) => item.sector !== 'TOTAL')
    setSelectedSummaryData(dataWithoutTotalEntry)
    setSelectedChartType(CapSummaryChartTypes.Pie)
  }

  const showBarChart = (capSummaryData: CapitalizationSummary[]) => {
    setShowChart(true)
    const dataWithoutTotalEntry: CapitalizationSummary[] = capSummaryData.filter((item: CapitalizationSummary) => item.sector !== 'TOTAL')
    setSelectedSummaryData(dataWithoutTotalEntry)
    setSelectedChartType(CapSummaryChartTypes.Bar)
  }

  const chartDisplayProps: CapSummaryChartDisplayModalProps = {
    showModal: showChart,
    setShow: setShowChart,
    capSummaryData: selectedSummaryData,
    chartType: selectedChartType,
    setErrorResponse: setErrorResponse,
    summaryType: summaryCategory,
    selectedCurrency: selectedCurrency,
    selectedIndex: selectedIndex
  }

  const createDownloadData = (data: CapitalizationSummary[], dateFormat: DateFormatValueType): string[][] => {
    const downloadDataContents: string[][] = []
    downloadDataContents.push(['Market Cap', 'date', summaryCategory, 'pct', 'count', 'top10'])
    data.forEach((value: CapitalizationSummary) => {
      downloadDataContents.push([value.marketCap.toString(), moment(value.date).format(dateFormat.toUpperCase()), value.sector, value.pct.toString(), value.numberOfStks.toString(), value.top10.toString()])
    })
    return downloadDataContents
  }

  const createExportFilename = (): string => {
    const userData: UserInfo | null = getUserInfoFromCookie()
    return `${userData?.firstName || ''}_${userData?.lastName || ''}_NavListExport_excel`.replace(/[/\\?%*:|"<>]/g, '')
  }

  const downloadDataFile = (data: CapitalizationSummary[], dateFormat: DateFormatValueType) => {
    const dataFileRequest: CreateDataFileRequest = {
      fileName: createExportFilename(),
      fileData: createDownloadData(data, dateFormat),
      outputFormat: OutputFormatValue.Excel
    }
    createDownloadDataFile(dataFileRequest).then(
      (fileCreateResponse: CreateDataFileResponse) => {
        const downloadRequest: FileDownloadRequest = {
          filename: fileCreateResponse.filename,
          mimeType: fileCreateResponse.mimeType
        }
        downloadFileFromServer(downloadRequest, setErrorResponse)
      },
      (error: NotOKResponseModel) => {
        setErrorResponse(error)
      }
    )
  }

  const processExportResultToList = (data: CapitalizationSummary[]) => {
    setExportAllClicked(false)
    setSelectedSummaryData(data)
    setShowExportOutputFormatModal(true)
  }

  const executeExportResultToList = (dateFormat: DateFormatValueType) => {
    downloadDataFile(selectedSummaryData, dateFormat)
  }

  const chartTitle: string = `${summaryCategory}: Historical Chart for previous ${previousYears} years (${selectedCurrency.value === '' ? 'Source Currency' : selectedCurrency.label})`

  const columns: any[] = [
    { title: 'id', field: 'sector', hidden: true },
    { title: summaryCategory, field: 'listViewHyperLink', sorting: false },
    { title: 'Pct (% Weight)', field: 'pct', type: 'numeric', sorting: true },
    { title: 'Market Cap', field: 'marketCap', type: 'numeric', sorting: true },
    { title: '# of Stocks', field: 'numberOfStks', type: 'numeric', sorting: true },
    { title: 'Date', field: 'date', type: 'date', sorting: true }
  ]

  const [sortOrder, setSortOrder] = useState<SortOrderStringType>()
  const [sortColumn, setSortColumn] = useState<string>('')
  const sortedData: any[] = useMemo(
    () =>
      sort(dataSource, getComparator(sortOrder, sortColumn)),
    [sortOrder, sortColumn, dataSource],
  )

  return (
    <Paper elevation={3} style={{ padding: 15 }}>
      {isShowGraphAccordian(resultType) &&
        <StyledAccordion expanded={chartExpanded} onChange={handleSetChartExpanded} sx={{ paddingBottom: 3 }}>
          <StyledAccordionSummary
            aria-controls='cap-summary-chart-content'
            id='cap-summary-chart-header'
          >
            <Typography>{chartTitle}</Typography>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <CapSummaryPercentAreaChart previousYears={previousYears} currentSummaryRequest={currentSummaryRequest} chartTitle={chartTitle} setErrorResponse={setErrorResponse} />
          </StyledAccordionDetails>
        </StyledAccordion>}
      <ThemeProvider theme={customTheme}>
        <MaterialTable
          columns={columns}
          data={sortedData}
          title={renderTitleArea()}
          options={{
            ...MaterialTableOptions,
            maxBodyHeight: '500px'
          }}
          actions={[
            {
              tooltip: 'Click to View Comparision Pie Chart',
              icon: () => <PieGraph style={{ fontSize: '1.2em' }} />,
              onClick: (evt, data) => {
                showPieChart(data as CapitalizationSummary[])
              }
            },
            {
              tooltip: 'Click to View Comparision Bar Chart',
              icon: () => <BarGraph style={{ fontSize: '1.2em' }} />,
              onClick: (evt, data) => {
                showBarChart(data as CapitalizationSummary[])
              }
            },
            {
              tooltip: 'Export Search Results to List',
              icon: () => <ExporttoList style={{ fontSize: '1.2em' }} />,
              onClick: (evt, data) => processExportResultToList(data as CapitalizationSummary[])
            }
          ]}
          components={{
            Body: props => (
              (<>
                <MTableBody {...props} />
              </>)
            ),
            Header:
              props => (
                <EnhancedTableHead {...{ sortData: { sortColumn, sortOrder, setSortColumn, setSortOrder }, columns, allowSelection: true, onSelectAll: props?.onAllSelected, selectedCount: props?.selectedCount, rowCount: props?.dataCount }} />
              )
          }}
        />
      </ThemeProvider>
      <CapSummaryChartDisplayModal {...chartDisplayProps} />
      <ExportOutputFormatSelectionModal show={showExportOutputFormatModal} setShow={setShowExportOutputFormatModal} exportAction={exportAllClicked ? exportSummaryRequest : executeExportResultToList} />
    </Paper>
  )
}

export default CapitalizationSummaryResults
