import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { SortOrderStringType, SortOrderStringValue } from "../../../Models/DataModels/Common/SortModel";
import { CSSProperties, ChangeEvent, useState } from "react";
import TrialUserLock, { trialUserSeriesSelectMessage } from "./TrialUserLock";
import SubscriptionModal, { SubscriptionModalProps } from "../Modals/SubscriptionModal";

export type ColumnType = 'numeric' | 'string' | 'datetime'
export const ColumnTypeValue = {
  Numeric: 'numeric' as ColumnType,
  String: 'string' as ColumnType,
  DateTime: 'datetime' as ColumnType,
}
export interface ColumnData {
  title: string
  field: string
  hidden?: boolean
  sorting?: boolean
  type?: ColumnType
  sortField?: string
  width?: string | number
}

export interface DefaultSortData {
  sortOrder: SortOrderStringType
  sortColumn: string
  setSortOrder: (order: SortOrderStringType) => void
  setSortColumn: (column: string) => void
}

export interface CustomSortData {
  onSort: (columnNumber: number) => void
  getDefaultSort: (column: string) => SortOrderStringType
}

const isDefault = (sortData: object): sortData is DefaultSortData => {
  return Boolean(sortData as DefaultSortData)
}

const isCustom = (sortData: object): sortData is CustomSortData => {
  return Boolean(sortData as CustomSortData)
}

export interface TableHeadProps {
  sortData: DefaultSortData | CustomSortData
  columns: ColumnData[]
  selectedCount?: number
  rowCount?: number
  allowSelection?: boolean
  onSelectAll?: (checked: boolean) => void,
  readOnly?: boolean,
  checkIsTrial?: () => boolean
}

const EnhancedTableHead = ({
  sortData,
  columns,
  selectedCount,
  rowCount,
  allowSelection,
  onSelectAll,
  readOnly,
  checkIsTrial
}: TableHeadProps) => {


  const sortHandler = (columnField: string, columnNumber: number) => (event: React.MouseEvent<unknown>) => {
    if (isCustom(sortData) && sortData.onSort) {
      sortData.onSort(columnNumber)
    } else if (isDefault(sortData)) {
      let order = SortOrderStringValue.Ascending
      if (sortData.sortColumn === columnField) {
        switch (sortData.sortOrder) {
          case SortOrderStringValue.Ascending:
            order = SortOrderStringValue.Descending
            break
          case SortOrderStringValue.Descending:
            order = undefined
            break
          default:
            order = SortOrderStringValue.Ascending
            break;
        }
      }
      sortData.setSortColumn(columnField)
      sortData.setSortOrder(order)
    }
  }

  const selectAllHandler = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onSelectAll) onSelectAll(checked)
  }

  const renderColumnHeaders = () => {
    const columnHeaders: any[] = []

    columns.forEach((column, index) => {
      if (!column.hidden) {
        const direction = isCustom(sortData) && sortData.getDefaultSort ? sortData.getDefaultSort(column.field) : (isDefault(sortData) && column.field === sortData.sortColumn ? sortData.sortOrder : undefined)
        const isSorted = Boolean(direction)
        columnHeaders.push(<TableCell
          key={index}
          align={column.type === ColumnTypeValue.Numeric ? 'right' : 'left'}
          sortDirection={direction}
          width={column.width}
        >
          {column.sorting ?? true ? <TableSortLabel
            active={isSorted}
            direction={direction}
            onClick={sortHandler(column.field, index)}
            title={isSorted ? (
              direction === SortOrderStringValue.Descending ? 'Sorted in descending order' : 'Sorted in ascending order'
            ) : ''}
          >
            {column.title}
          </TableSortLabel> : <>{column.title}</>}
        </TableCell>)
      }
    })

    return columnHeaders
  }

  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false)

  const falseFunc = (): boolean => false

  const upgradeModalProps: SubscriptionModalProps = {
    showModal: showUpgradeModal,
    setShow: setShowUpgradeModal,
    checkIsTrial: checkIsTrial || falseFunc
  }

  const renderSelectAll = () => {
    if (allowSelection && onSelectAll) {
      return (
        <TableCell padding='none'>
          {checkIsTrial && checkIsTrial() ?
            <Checkbox
              title={trialUserSeriesSelectMessage}
              onClick={() => {
                setShowUpgradeModal(true)
              }}
              icon={<TrialUserLock checkIsTrial={checkIsTrial} message={trialUserSeriesSelectMessage} />}
              checkedIcon={<TrialUserLock checkIsTrial={checkIsTrial} message={trialUserSeriesSelectMessage} />}
              sx={{ paddingLeft: '5px' }}
            />
            :
            <Checkbox
              color='primary'
              indeterminate={selectedCount !== undefined && rowCount !== undefined && selectedCount > 0 && selectedCount < rowCount}
              checked={selectedCount !== undefined && rowCount !== undefined && rowCount > 0 && selectedCount === rowCount}
              onChange={selectAllHandler}
              inputProps={{
                'aria-label': 'Select All',
              }}
            />
          }
          {showUpgradeModal && <SubscriptionModal {...upgradeModalProps} />}
        </TableCell>
      )
    }
  }

  const readOnlyProp: CSSProperties = readOnly ?? false ? { pointerEvents: 'none' } : {}

  return (
    <TableHead style={{ ...readOnlyProp }}>
      <TableRow>
        {renderSelectAll()}
        {renderColumnHeaders()}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
