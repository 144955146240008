import { Slider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { unixTimestampDateFormatter } from './GraphAndChartUtil'
import moment from 'moment'
import { globalStyles } from '../../Common/GlobalSettings/GlobalStyles'

export interface GraphDateRangeSliderProps {
    minUnixDate: number,
    maxUnixDate: number,
    sliderStart: number,
    sliderEnd: number,
    zoom: (startDate: number, endDate: number) => void
}

interface Mark {
    label: string,
    value: number
}

const GraphDateRangeSlider = ({
    minUnixDate,
    maxUnixDate,
    sliderStart,
    sliderEnd,
    zoom
}: GraphDateRangeSliderProps) => {

    const minDistance: number = 0

    const [sliderRange, setSliderRange] = useState<number[]>([minUnixDate, maxUnixDate])
    const [marks, setMarks] = useState<Mark[]>([])

    const handleSliderChange = (event: Event, newValue: number | number[], activeThumb: number) => {
        // setLabelDisplayState('auto')
        if (!Array.isArray(newValue)) {
            return
        }
        if (sliderRange[0] === newValue[0] && sliderRange[1] === newValue[1]) {
            return
        }

        if (activeThumb === 0) {
            setSliderRange([Math.min(newValue[0], sliderRange[1] - minDistance), sliderRange[1]])
        } else {
            setSliderRange([sliderRange[0], Math.max(newValue[1], sliderRange[0] + minDistance)])
        }
    }

    const handleSliderChangeCommited = () => {
        if (sliderRange.length > 1) {
            zoom(sliderRange[0], sliderRange[1])
        }
    }

    const createMark = (unixDate: number): Mark => {
        return {
            value: unixDate,
            label: moment(unixDate).utc().year().toString()
        }
    }

    useEffect(() => {
        setSliderRange([sliderStart, sliderEnd])
    }, [sliderStart, sliderEnd])

    useEffect(() => {
        const dateRangeUnixDate: number = maxUnixDate - minUnixDate
        const tickNumber: number = 10
        const increments: number = dateRangeUnixDate / tickNumber
        const marksArray: Mark[] = []
        let counter: number = minUnixDate
        while (counter < maxUnixDate) {
            const currentMark: Mark = createMark(counter)
            marksArray.push(currentMark)
            counter += increments
        }
        if (!marksArray.find((value: Mark) => value.value === maxUnixDate)) {
            const lastMark: Mark = createMark(maxUnixDate)
            marksArray.push(lastMark)
        }
        const allYears: string[] = marksArray.map((value: Mark) => value.label?.toString() || '')
        const duplicateYears: string[] = allYears.filter((item: string, index: number) => allYears.indexOf(item) !== index)
        if (duplicateYears && duplicateYears.length > 0) {
            const marksWithFormattedDay: Mark[] = marksArray.map((item: Mark) => {
                return { value: item.value, label: unixTimestampDateFormatter(item.value) }
            })
            setMarks(marksWithFormattedDay)
        } else {
            setMarks(marksArray)
        }
    }, [minUnixDate, maxUnixDate])

    return (
        <Slider
            getAriaLabel={() => 'Adjust Date Range'}
            sx={{ color: globalStyles.siteThemeColor }}
            value={sliderRange}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderChangeCommited}
            valueLabelDisplay='auto'
            valueLabelFormat={(unixTimestampDateValue: number) => unixTimestampDateFormatter(unixTimestampDateValue)}
            min={minUnixDate}
            max={maxUnixDate}
            marks={marks}
        />
    )
}

export default GraphDateRangeSlider
