import React from 'react'
import AlertModal, { AlertModalProps, AlertButtonType } from '../../Common/Modals/AlertModal'
import { companyInfo } from '../../../Models/DataModels/Common/AppConfigurationsModel'

export interface SubscriptionModalProps {
    showModal: boolean,
    setShow: (show: boolean) => void,
    checkIsTrial: () => boolean
}

const SubscriptionModal = ({
    showModal,
    setShow,
    checkIsTrial
}: SubscriptionModalProps) => {
    const renderUpgradePrompt = () => {
        return <>
            <div><b>Subscription type:</b> { checkIsTrial() ? 'Trial' : 'Paid' }</div>
            <br/>
            {
                checkIsTrial() ? (
                <>
                    <div style={{fontSize: '0.9rem'}}>
                        As a Trial User, you have the following limitations and will need to upgrade your subscription to access more features or data.
                        <ul>
                            <li>Only work with/select 1 Trial series at a time <b><i>(Paid Users can work with/select multiple series at a time)</i></b></li>
                            <li>Access to only DOW 30 stocks and Dow Jones Industrial Average (DJIA) full history and 2019-current history for the remaining US Stocks <b><i>(Paid Users have access to whatever data they purchase)</i></b></li>
                            <li>No AutoTrac access which allows you to create workbooks of multiple series to save and download a group of series regularly <b><i>(Paid Users get access)</i></b></li>
                            <li>No API access and can only use the tryapi@finaeon.com test API account to experiment <b><i>(Paid Users can have API access)</i></b></li>
                        </ul>
                    </div>
                    <br/>
                </>
                ) : null
            }
            <div>Contact Sales to upgrade your subscription and get more access!</div>
            <ul>
                <li>Phone: <a href={`tel:${companyInfo.salesPhoneNumberLink}`}>{companyInfo.salesPhoneNumberDashFormatted}</a></li>
                <li>Email: <a href={`mailto:${companyInfo.salesEmailAddress}`}>{companyInfo.salesEmailAddress}</a></li>
            </ul>
        </>
    }

    const upgradeModalProps: AlertModalProps = {
        showModal: showModal,
        setShowModal: setShow,
        AlertTitle: 'Upgrade Subscription',
        AlertContent: renderUpgradePrompt(),
        AlertButtons: [
            {
                type: AlertButtonType.OK,
                display: 'Ok',
                onClick: () => {
                    setShow(false);
                },
                isPrimary: true
            },
            {
                type: AlertButtonType.Cancel,
                display: 'Cancel',
                onClick: () => {
                    setShow(false);
                },
                isPrimary: false
            }
        ],
        onAlertClose: () => {
            return true
        }
    }

  return AlertModal(upgradeModalProps);
}

export default SubscriptionModal