import { ToggleButtonGroup } from '@mui/material'
import React, { useState } from 'react'
import CapitalizationResultListView from './CapitalizationResultListView'
import CapitalizationResultGridView from './CapitalizationResultGridView'
import { CapitalizationData } from '../../../../../../Models/DataModels/Common/ToolsModel'
import { LogoutReasonType } from '../../../../../../Models/DataModels/Requests/AuthRequests'
import { NotOKResponseModel } from '../../../../../../Models/DataModels/Common/NotOKResponseModel'
import { CustomToggle } from '../../../../../Common/GlobalSettings/CustomStyles'
import { UserInfo } from '../../../../../../Models/DataModels/Common/UserInfoModel'

export interface CapitalizationListResultsProps {
    userInfo: UserInfo | null,
    listResult: CapitalizationData[],
    checkIsTrial: () => boolean,
    signOut: (logoutReason: LogoutReasonType) => void,
    setErrorResponse: (errorResponse: NotOKResponseModel | null) => void,
    currentRequest: any
}

const CapitalizationListResults = ({
    userInfo,
    listResult,
    checkIsTrial,
    signOut,
    setErrorResponse,
    currentRequest
}: CapitalizationListResultsProps) => {

    type CapitalizationListResultOptions = 'ListView' | 'GridView'
    const CapitalizationDataOptionsTypes = {
        listView: 'ListView' as CapitalizationListResultOptions,
        gridView: 'GridView' as CapitalizationListResultOptions
    }

    const [capResultView, setCapResultView] = useState<string>(CapitalizationDataOptionsTypes.listView)

    const handleViewToggle = (event: React.MouseEvent<HTMLElement>, newView: string) => {
        if (newView !== null) {
            setCapResultView(newView)
        }
    }

    return (
        <>
            <ToggleButtonGroup
                color='primary'
                value={capResultView}
                exclusive
                onChange={handleViewToggle}
                sx={{ paddingBottom: 2 }}>
                    <CustomToggle value={CapitalizationDataOptionsTypes.listView}>List View</CustomToggle>
                    <CustomToggle value={CapitalizationDataOptionsTypes.gridView}>Grid View</CustomToggle>
            </ToggleButtonGroup>        
            {capResultView === CapitalizationDataOptionsTypes.listView ?
                <CapitalizationResultListView userInfo={userInfo} listResult={listResult} checkIsTrial={checkIsTrial} signOut={signOut} setErrorResponse={setErrorResponse} currentRequest={currentRequest}/>
                :
                <CapitalizationResultGridView listResult={listResult} />}
        </>
    )
}

export default CapitalizationListResults
