import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, styled, Typography } from '@mui/material'
import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const RegistrationMarketing = () => {

    const VisibilityToggleGrid = styled(Grid)(({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }))

    const bulletItems: string[] = [
        'Access 307,545 Global Data Series from 1000 AD to Present',
        'Easily Search and Download Excel Files for Data Analysis and Research',
        'Quickly Sharpen Portfolio Analytics with Robust Historical Comparisons',
    ]

    const generateMarketingListItems = (): JSX.Element[] => {
        const listItemComponents: JSX.Element[] = bulletItems.map((listItemValue: string, index: number) => {
            return (
                <ListItem key={`registerationMarketingItem${index}`}>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                        <CheckCircleOutlineIcon sx={{ color: '#238080' }} />
                    </ListItemIcon>
                    <ListItemText sx={{ fontSize: '0.75rem !important' }}
                        primary={listItemValue}
                    />
                </ListItem>
            )
        })
        return listItemComponents
    }

    return (
        <div>
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                paddingTop={'10%'}
            >
                <div style={{ textAlign: 'center' }}>
                    <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item sx={{ py: 2 }}>
                            <img src={require('../../Images/Finaeon_black_transparentBackground.png')} alt='Finaeon Logo' width={124} height={42} />
                        </Grid>
                    </Grid>
                    <Typography variant='h4' gutterBottom>
                        Try Finaeon For Free
                    </Typography>
                    <Typography variant='subtitle2' sx={{ fontSize: '0.75rem' }}>
                        See the whole picture with 1,000+ years of data.
                    </Typography>
                    <VisibilityToggleGrid container direction='row' sx={{ justifyContent: 'center', alignItems: 'center', paddingTop: '50px', paddingBottom: '35px' }}>
                        <VisibilityToggleGrid item>
                            <img src='https://gfdmarketing.blob.core.windows.net/marketing/UserRegistationMarketing.png' alt='Try Finaeon Free' width={275} height={188} />
                        </VisibilityToggleGrid>
                    </VisibilityToggleGrid>
                    <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item>
                            <List dense={true}>
                                {generateMarketingListItems()}
                            </List>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </div>
    )
}

export default RegistrationMarketing
