import { ChartSearchResultDataRequest } from "../Models/DataModels/Common/ToolsModel"
import { CreateChartDownloadDataRequest, CreateYieldDownloadDataRequest, GraphRequest, GraphYieldRequest, QuikGraphRequest, SeriesChartOptionsRequest } from "../Models/DataModels/Requests/GraphRequests"
import getAPICallResult, { APIRequest, HttpRequest, abortKeys } from "./APIService"

export const graphByFileName = async (request: GraphRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Chart/GetChartData',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const graphYieldBySeries = async (request: GraphYieldRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Chart/GetYieldCurveData',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

const getRandomArbitrary = (min: number, max:number) => {
    return Math.floor((Math.random() * (max - min) + min))
}

export const getSeriesChartOptions = async (request: SeriesChartOptionsRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Chart/ChartOptions/?seriesListString=' + request.seriesListString,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const graphDownload = async (request: CreateChartDownloadDataRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Chart/CreateChartDownloadData',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const createYieldDataFile = async (request: CreateYieldDownloadDataRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Chart/CreateYieldDownloadData',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const chartSearchResultsData = async (request: ChartSearchResultDataRequest) => {
    const apiRequest: APIRequest = {
        relativePath: '/Chart/SearchResultData',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest, false, false, null, abortKeys.searchChartLoading)
}

const dataGenerator = (numberOfRecords: number, tickers: any, startYear: number) => {
    let results: any = []
    let year = startYear
    let valueOfTicker = 10
    //let count = 0
    let isRandomStrongEvent = false
    //let highest = 0


    tickers.forEach((recordName: string, index: any) => {
        let tickerDataArray: any = []
        let randomweight = getRandomArbitrary(1, 15)


        for (let i = 0; i < numberOfRecords; i++) {
            let intermediateObj : any = {}
            let randomStrongEvent = getRandomArbitrary(-50, 50)

            let random = getRandomArbitrary(1, 5)
            isRandomStrongEvent = (getRandomArbitrary(1, 100) > 55 && valueOfTicker > 100) ? true : false
            isRandomStrongEvent ? random += randomStrongEvent : random += 0
            valueOfTicker += random + randomweight
            //highest < valueOfTicker ? highest = valueOfTicker : highest = highest
            intermediateObj.date = year + i
            intermediateObj[recordName] = 
            {
                value: valueOfTicker,
                hasSplit: getRandomArbitrary(1, 100) > 96 ? getRandomArbitrary(1, 3) : 0,
                dividend: getRandomArbitrary(1, 100) > 96 ? true : false,
                eventInTime: 'none'
            }
            tickerDataArray.push(intermediateObj)
        }
        year = startYear
        valueOfTicker = 10
        results.push(tickerDataArray)
    })
    results.push(data3)
    console.log('GRAPH RESULTS: ', results)
    //area line test
    // let areaTest = areaData
    // areaTest.highest = highest

    // areaTest.dataArray.map((a: any) => {
    //     a.area.value = highest
    // })
    // results.push(areaTest)
    //area line test

    return results
}

const data3 = [
    {
      date: 1965,
      area: {Johnson: 1000, Nixon: 1000}
    },
    {
      date: 1966,
      area: {Nixon: 1001}
    },
    {
      date: 1967,
      area: {Nixon: 1000}
    },
    {
      date: 1968,
      area:{Carter: 1000, Nixon: 1000}
    },
    {
      date: 1969,
      area: {Carter: 1001}
    },
    {
      date: 1970,
      area: {Carter: 1000}
    },
  ]

const areaData = {
    type: 'area',
    highest: 0,
    dataArray: 
    [
        {
            date: 1965,
            area: {value: 0, hasSplit: 0, dividend: false, eventInTime: 'none'}
        },
        {
            date: 1966,
            area: {value: 0, hasSplit: 0, dividend: false, eventInTime: 'none'}
        },
        {
            date: 1967,
            area: {value: 0, hasSplit: 0, dividend: false, eventInTime: 'none'}
        }
        
    ]
}

export const graphQuikChart = async (request: QuikGraphRequest) => {
    return dataGenerator(50, ['ibm', 'nke'], 1965)

    // Not called right now? above for stubbing?
    const apiRequest: APIRequest = {
        relativePath: '/MarketPrice/Filename/' + request.searchTerm,
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const demoChart = async () => {
    const apiRequest: APIRequest = {
        relativePath: '/MarketPrice/Filename/nke',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

const GraphService = () => {
    return (
        <div></div>
    )
}