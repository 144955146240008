import React from "react"
import { ReactComponent as InfoSvgIcon } from "../../Images/svg/InfoGraySvg.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const InfoGraySvgIcon = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <InfoSvgIcon />
  </SvgIcon>
)

export default InfoGraySvgIcon