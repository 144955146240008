import MaterialTable from '@material-table/core'
import { IconButton, Tooltip } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import TickerDropZone, { TickerDropZoneProps } from '../../Common/Utility/TickerDropZone'
import { DownloadSettingDictionary, TickerData } from '../../../Models/DataModels/Responses/AutoTracResponses'
import DefaultMaterialTheme from '../../Common/GlobalSettings/DefaultMaterialTheme'
import { CSSProperties } from '@mui/material/styles/createTypography'
import SearchIcon from '@mui/icons-material/Search'
import UploadIcon from '@mui/icons-material/Upload'
import MaterialTableOptions from '../../Common/GlobalSettings/MaterialTableOptions'
import { CreateWorkbookDownloadRequest, WorkbookTickersByIDRequest, WorkbookTickersByNameRequest } from '../../../Models/DataModels/Requests/AutoTracRequests'
import { addTickersToWorkbookByName, deleteTickersFromWorkbook, downloadWorkbook } from '../../../Services/AutoTracService'
import AlertModal, { AlertButtonType, AlertModalProps } from '../../Common/Modals/AlertModal'
import Box from '@mui/material/Box'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import Search, { AutoTracSearchProps } from './Search'
import DownloadOptions, { DownloadOptionsProps } from '../../Common/Utility/DownloadOptions'
import { DefaultDownloadSettings, DownloadSettingsModel, GetDownloadSettingsModel } from '../../../Models/DataModels/Common/DownloadSettingsModel'
import moment from 'moment'
import GFDTablePagination, { GFDTablePaginationProps } from '../../Common/Utility/GFDTablePagination'
import { SortFieldType, SortFields, SortOrderString, SortOrderType } from '../../../Models/DataModels/Common/SortModel'
import { ComponentMessageHandler, ComponentMessageHandlerProps } from '../../Common/Utility/ComponentMessageHandler'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { MessageResponse, MessageResponseTypes, MessageResponseValueType } from '../../../Models/DataModels/Responses/NotOKResponse'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { SortProps } from '../../Search/SubComponents/SearchResults'
import DeleteSelected from '../../Icons/DeleteSelectedIcon'
import DownLoad from '../../Icons/DownLoad'
import { BtnHeaderOutlined, CustomBtnBlue } from '../../Common/GlobalSettings/CustomStyles'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import EnhancedTableHead from '../../Common/Utility/EnhancedTableHead'
import DownloadAction, { DownloadActionProps } from '../../Common/Utility/DownloadAction'
import { AppConfigurations } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import QuickActions, { QuickActionsProps } from '../../Common/Modals/QuickActions'
import DownLoadGreen from '../../Icons/DownLoadGreen'

export interface WorkbookDetailsProps {
  WorkbookName: string | undefined
  RefreshWorkbookDetails: () => void
  WorkbookData: TickerData[]
  TickerCount: number
  TickerDataCountTotal: number
  DownloadSettingDictionary?: DownloadSettingDictionary,
  SeriesMinDate?: moment.Moment | null,
  SeriesMaxDate?: moment.Moment | null,
  setTriggerRefreshDownloadQueue: (value: boolean) => void,
  pageData: GFDTablePaginationProps,
  sortData: {
    sortProps: SortProps,
    setSortProps: (sortProps: SortProps) => void
  },
  checkIsTrial: () => boolean,
  signOut: (logoutReason: LogoutReasonType) => void,
  clearMessagesToggle: boolean,
  clearMessages: () => void
}

const TabValue = {
  Search: 'search',
  FileUpload: 'fileUpload',
  DownloadQueue: 'downloadQueue'
}

const WorkbookContents = ({
  WorkbookName,
  RefreshWorkbookDetails,
  WorkbookData,
  TickerCount,
  TickerDataCountTotal,
  DownloadSettingDictionary,
  SeriesMinDate,
  SeriesMaxDate,
  setTriggerRefreshDownloadQueue,
  pageData,
  sortData,
  checkIsTrial,
  signOut,
  clearMessagesToggle,
  clearMessages
}: WorkbookDetailsProps) => {
  const getDefaultSort = (column: string) => {
    if (['symbol', 'description', 'dataCount'].includes(column) &&
      sortData.sortProps.sortField === SortFields[column]) {
      return SortOrderString[sortData.sortProps.sortOrder]
    }
    return undefined
  }

  const renderQuickActions = (rowData: any) => {
    const quickActionProps: QuickActionsProps = {
      rowData: rowData,
      maxTickerLimit: AppConfigurations.maximumTickers,
      signOut: signOut,
      checkIsTrial: () => false // autotrac page is accessible only to non-trial users  
    }
    return <QuickActions {...quickActionProps} />
  }
  
  const getColumns = () => {
    const columns: any[] = [
      { title: 'Symbol', field: 'symbol', sorting: true },
      { title: 'Description', field: 'description', sorting: true },
      { title: 'Data Count', field: 'dataCount', type: 'numeric', sorting: true },
      { title: '_id', field: 'id', type: 'numeric', hidden: true },
      {
        title: "Quick Actions",
        sorting: false,
        field: "button",
        render: (rowData: any) => renderQuickActions(rowData),
        width: '15%'
      }
    ]
    return columns
  }

  const [tabSelected, setTabSelected] = useState(TabValue.Search)
  const [deleteRequest, setDeleteRequest] = useState<WorkbookTickersByIDRequest | undefined>(undefined)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false)
  const [resetSearchTermToggle, setResetSearchTermToggle] = useState(false)
  const [currentDownloadSettings, setCurrentDownloadSettings] = useState<DownloadSettingsModel>(DefaultDownloadSettings)
  const [savedDownloadSettings, setSavedDownloadSettings] = useState<DownloadSettingsModel | undefined>(undefined)
  const [disableSaveDownloadSettings, setDisableSaveDownloadSettings] = useState(false)
  const [triggerSaveDownloadSettings, setTriggerSaveDownloadSettings] = useState(false)
  const [columns, setColumns] = useState(getColumns())

  const [tickersToDownload, setTickersToDownload] = useState<TickerData[] | null>(null)
  const [triggerProcessDownload, setTriggerProcessDownload] = useState<boolean>(false)

  const [messages, setMessages] = useState<MessageResponse[]>([])
  const [response, setResponse] = useState<NotOKResponseModel | null>()

  const displayMessage = (message: string, type: MessageResponseValueType) => {
    const componentMessage: MessageResponse = {
      message: message as string,
      type
    }
    setResponse(null)
    setMessages([componentMessage])
    return
  }

  const displayMessages = (componentMessages: MessageResponse[]) => {
    setResponse(null)
    setMessages(componentMessages || [])
    return
  }

  const displayResponse = (model: NotOKResponseModel) => {
    if (model) {
      setMessages([])
      setResponse(model as NotOKResponseModel)
      return
    }
  }

  const clearAlertsInCurrentWindow = () => {
    setMessages([])
    setResponse(null)
  }

  const clearAlert = () => {
    clearAlertsInCurrentWindow()
    clearMessages()
  }

  useEffect(() => {
    clearAlertsInCurrentWindow()
  }, [clearMessagesToggle])

  useEffect(() => {
    if (DownloadSettingDictionary) {
      const model: DownloadSettingsModel = GetDownloadSettingsModel(DownloadSettingDictionary, SeriesMinDate, SeriesMaxDate)
      setCurrentDownloadSettings(model)
    } else {
      setCurrentDownloadSettings(DefaultDownloadSettings)
    }
  }, [DownloadSettingDictionary, SeriesMinDate, SeriesMaxDate])

  useEffect(() => {
    if (savedDownloadSettings && WorkbookName) {
      // save downloadsettings and queue download entry
      const downloadRequest = CreateWorkbookDownloadRequest(WorkbookName, savedDownloadSettings)
      downloadWorkbook(downloadRequest)
        .then((result: any) => {
          refreshOnSuccessAfterDownloadRequest()
          displayMessage(`Workbook [${downloadRequest.workbookName}] queued for download.`, MessageResponseTypes.Success)
        },
          (notOKResponseModel: NotOKResponseModel) => {
            displayResponse(notOKResponseModel)
          })
    }
  }, [savedDownloadSettings])

  useEffect(() => {
    setColumns(getColumns())
  }, [WorkbookData])

  const refreshOnSuccessAfterDownloadRequest = () => {
    RefreshWorkbookDetails()
    setShowDownloadModal(false)
    setTriggerRefreshDownloadQueue(true)
  }

  const handleTabChange = (tab: string) => {
    clearAlert()
    setTabSelected(tab)
  }

  const alertModalProps: AlertModalProps = {
    showModal,
    setShowModal,
    AlertTitle: 'Are you sure?',
    AlertContent: `Would you like to delete ${deleteRequest?.tickerIDs?.length || 0} item(s)? This procedure is irreversible. Do you want to proceed?`,
    AlertButtons: [
      {
        type: AlertButtonType.Cancel,
        display: 'Cancel',
        onClick: () => {
          setShowModal(false)
          resetDeleteRequest()
        },
        isPrimary: false,
      },
      {
        type: AlertButtonType.OK,
        display: 'Yes',
        onClick: () => {
          setShowModal(false)
          executeDeleteRequest()
        },
        isPrimary: true,
      },
    ],
    onAlertClose: () => {
      resetDeleteRequest()
      return true
    }
  }

  const resetTriggerDownloadSettings = () => {
    setTriggerSaveDownloadSettings(false)
  }

  const onDownloadOKClick = () => {
    if (!disableSaveDownloadSettings) {
      setTriggerSaveDownloadSettings(true)
    }
  }

  const onDownloadCancelClick = () => {
    setShowDownloadModal(false)
  }

  const downloadOptionsProps: DownloadOptionsProps = {
    downloadSettings: currentDownloadSettings,
    setDisableSaveDownloadSettings: setDisableSaveDownloadSettings,
    saveDownloadSettings: setSavedDownloadSettings,
    triggerSaveDownloadSettings: triggerSaveDownloadSettings,
    resetTriggerDownloadSettings: resetTriggerDownloadSettings,
    seriesMinDate: SeriesMinDate,
    seriesMaxDate: SeriesMaxDate,
    tickers: WorkbookData,
    checkIsTrial,
    signOut
  }

  const getDownloadModalTitle = () => {
    const title = 'Download Options'
    return WorkbookName ? <>
      <div>{title}</div>
      <h5 style={styles.modalTitleWorkbook}>{WorkbookName}</h5>
    </> : title
  }

  const downloadModalProps: AlertModalProps = {
    showModal: showDownloadModal,
    setShowModal: setShowDownloadModal,
    AlertTitle: getDownloadModalTitle(),
    AlertContent: (<DownloadOptions {...downloadOptionsProps}></DownloadOptions>),
    AlertButtons: [
      {
        type: AlertButtonType.Cancel,
        display: 'Cancel',
        onClick: onDownloadCancelClick,
        isPrimary: false,
      },
      {
        type: AlertButtonType.OK,
        display: 'Download',
        onClick: onDownloadOKClick,
        isPrimary: true,
        disable: disableSaveDownloadSettings
      }
    ],
    onAlertClose: () => {
      return true
    },
    Size: 'xl'
  }

  useEffect(() => {
    if (!deleteRequest) return
    setShowModal(true)
  }, [deleteRequest])

  const processDeleteRequest = (data: any) => {
    if (deleteRequest) {
      displayMessage('Previous delete request is in progress!', MessageResponseTypes.Info)
      return
    }

    if (!data || !WorkbookName) return

    const tickerIDs = Object.values(data).map((d: any) => d?.id)
    if (tickerIDs.length === 0) return
    const request: WorkbookTickersByIDRequest = {
      workbookName: WorkbookName || '',
      tickerIDs
    }
    setDeleteRequest(request)
  }

  const resetDeleteRequest = () => {
    setDeleteRequest(undefined)
  }

  const executeDeleteRequest = () => {
    if (!deleteRequest) return
    deleteTickersFromWorkbook(deleteRequest)
      .then((result: any) => {
        result?.messages?.length ?
          displayMessages(result.messages)
          :
          displayMessage(`Ticker(s) deleted from Workbook [${deleteRequest.workbookName}].`, MessageResponseTypes.Success)
        RefreshWorkbookDetails()
        resetDeleteRequest()
      },
        (notOKResponseModel: NotOKResponseModel) => {
          displayResponse(notOKResponseModel)
        })
  }

  const executeAddTickerToWorkbookRequest = (tickerName: string) => {
    if (!WorkbookName) {
      displayMessage('Please select a workbook.', MessageResponseTypes.Info)
      return
    }

    const index = tickerName?.indexOf('~')
    if (index && index >= 1) {
      tickerName = tickerName.substring(0, index - 1)
    }

    tickerName = tickerName?.trim()
    if (!tickerName) return

    const request: WorkbookTickersByNameRequest = {
      workbookName: WorkbookName,
      tickerNames: [tickerName]
    }

    addTickersToWorkbookByName(request)
      .then((result: any) => {
        result?.messages?.length ?
          displayMessages(result.messages)
          :
          displayMessage(`Ticker(s) added to Workbook [${request.workbookName}].`, MessageResponseTypes.Success)
        RefreshWorkbookDetails()
        setResetSearchTermToggle(!resetSearchTermToggle)
      },
        (notOKResponseModel: NotOKResponseModel) => {
          displayResponse(notOKResponseModel)
        })
  }

  const onDownloadWorkbook = () => {
    clearAlert()
    setShowDownloadModal(true)
  }

  const renderSummary = () => {
    const pageTotal: number = (WorkbookData)?.reduce((acum, item) => acum + item?.dataCount, 0) || 0
    return (
      <div style={footerStyles}>
        <div style={footerContentStyles}><b>Page Total: {pageTotal} ({TickerDataCountTotal} Total)</b></div>
      </div>
    )
  }

  const renderTitle = () => {
    const showDowload: boolean = Boolean(WorkbookName && TickerCount > 0)
    const title = WorkbookName || 'No Workbook Selected'

    return (<div>
      <div title={title} style={showDowload ? stylesTitleWithDownload : stylesTitleWithoutDownload}>
        {title}
      </div>

      {showDowload ?
        <div style={{ float: 'right' }}>
          <Tooltip title={'Download workbook: ' + title}>
            <IconButton aria-label='Download Workbook' component='label' onClick={onDownloadWorkbook}>
              <DownLoadGreen style={{ fontSize: '1.2em' }}></DownLoadGreen>
            </IconButton>
          </Tooltip>
        </div>
        : <></>}
    </div>)
  }

  const searchProps: AutoTracSearchProps = {
    isWorkbookSelected: Boolean(WorkbookName),
    addTickerToWorkbook: executeAddTickerToWorkbookRequest,
    resetSearchTermToggle: resetSearchTermToggle
  }

  const dropZoneProps: TickerDropZoneProps = {
    clearAlert,
    displayMessages,
    displayResponse,
    CurrentTickerCount: pageData.totalCount,
    SelectedWorkbook: WorkbookName,
    RefreshWorkbookDetails: RefreshWorkbookDetails
  }

  const onSort = (columnNumber: number) => {
    clearAlert()

    const props: SortProps = {
      sortField: sortData.sortProps.sortField,
      sortOrder: SortOrderType.Ascending
    }

    try {
      switch (columnNumber) {
        case 0:
          props.sortField = SortFieldType.Symbol
          break
        case 1:
          props.sortField = SortFieldType.Description
          break
        case 2:
          props.sortField = SortFieldType.DataCount
          break
        default:
          props.sortField = SortFieldType.None
          break
      }

      if (props.sortField === sortData.sortProps.sortField) {
        if (sortData.sortProps.sortOrder === SortOrderType.Ascending) {
          props.sortOrder = SortOrderType.Descending
        } else if (sortData.sortProps.sortOrder === SortOrderType.Descending) {
          props.sortField = SortFieldType.None
          props.sortOrder = SortOrderType.None
        }
      }

      sortData.setSortProps(props)
    }
    catch (e) {
      console.log('Exception while sorting: ', e)
    }
  }

  const componentMessageHandlerProps: ComponentMessageHandlerProps = {
    messages,
    setMessages,
    response,
    signOut
  }

  const processDownloadRequest = (rowData: any) => {
    if (!rowData) return

    const tickers = rowData.map((row: any) => {
      const value = {
        id: row?.id,
        symbol: row?.symbol,
        description: row?.description
      } as TickerData
      return value
    })

    setTickersToDownload(tickers)
    setTriggerProcessDownload(true)
  }

  const renderDownloadAction = () => {
    const downloadActionProps: DownloadActionProps = {
      showButton: false,
      downloadTriggerProps: {
        triggerProcessDownload,
        resetTriggerProcessDownload: () => setTriggerProcessDownload(false),
        resetRowData: () => setTickersToDownload(null)
      },
      isComposite: false,
      rowData: tickersToDownload,
      maxTickerLimit: AppConfigurations.maximumTickers || 300,
      checkIsTrial,
      signOut: signOut
    }
    return <DownloadAction {...downloadActionProps} />
  }

  return (
    <div style={styles.container as CSSProperties}>
      <Box style={styles.item as CSSProperties}>
        <TabContext value={tabSelected}>
          <div style={{ width: '100%', height: '100%', display: 'flex', overflow: 'auto', flexDirection: 'row', flexWrap: 'nowrap' }}>
            <TabPanel value={TabValue.Search} style={{ padding: 0, flexBasis: '0', flexGrow: '1', display: 'flex', overflow: 'auto' }}>
              <div style={{ flexBasis: '90%' }}>
                <Search {...searchProps} />
              </div>
            </TabPanel>
            <TabPanel value={TabValue.FileUpload} style={{ padding: 0, flexBasis: '0', flexGrow: '1', display: 'flex', overflow: 'auto' }}>
              <div style={{ flexBasis: '90%', border: '1 solid brown' }}>
                <TickerDropZone {...dropZoneProps} />
              </div>
            </TabPanel>
            <div style={{ flexGrow: '0', flexBasis: 'auto', margin: '8px', alignContent: 'flex-end' }}>
              <CustomBtnBlue variant={"contained"} startIcon={<SearchIcon />} onClick={() => handleTabChange(TabValue.Search)} style={{ ...tabSelected === TabValue.Search ? { display: 'none' } : { display: 'inline-block' } }} >
                Back to Search
              </CustomBtnBlue>
              {tabSelected === TabValue.FileUpload ? <></>
                : <Tooltip
                  style={{ marginRight: '2px' }}
                  title={'This widget can be used as a fast way to search multiple symbols in the GFD Universe Database. Click on File Upload. Drag and drop or choose any .csv file you created previously. These files, as indicated by their .csv file extension, should contain comma-separated-values of the symbols you want to search for.'}
                >
                  <HelpOutlineIcon style={{ fontSize: '1.25em', marginLeft: '5px', marginRight: '5px', fill: '#8e95ce' }} />
                </Tooltip>
              }
              <BtnHeaderOutlined variant={"outlined"} endIcon={<UploadIcon />} onClick={() => handleTabChange(TabValue.FileUpload)} style={{ ...tabSelected === TabValue.FileUpload ? { display: 'none' } : { display: 'inline-block' } }} >
                File Upload
              </BtnHeaderOutlined>
            </div>
          </div>
        </TabContext>
      </Box>

      <div style={styles.item as CSSProperties}>
        <ComponentMessageHandler {...componentMessageHandlerProps} />
      </div>

      <div style={styles.fillItem as CSSProperties}>
        <ThemeProvider theme={DefaultMaterialTheme}>
          {renderDownloadAction()}
          <MaterialTable
            style={{ width: '100%', overflow: 'auto', border: '1px solid rgb(224, 224, 224)', }}
            columns={columns}
            data={WorkbookData || []}
            title={renderTitle()}
            options={{
              ...MaterialTableOptions,
              paginationPosition: 'top',
              maxBodyHeight: undefined
            }}
            actions={[
              {
                tooltip: 'Remove Selected Tickers from Workbook',
                icon: () => <DeleteSelected style={{ fontSize: '1.2em' }} />,
                onClick: (evt, data) => {
                  clearAlert()
                  processDeleteRequest(data)
                }
              },
              {
                tooltip: 'Download Selected Tickers from Workbook',
                icon: () => <DownLoadGreen style={{ fontSize: '1.2em' }} />,
                onClick: (evt, data) => {
                  clearAlert()
                  processDownloadRequest(data)
                }
              }
            ]}
            components={{
              Pagination: props => (<GFDTablePagination {...pageData} />),
              Header:
                props => (
                  <EnhancedTableHead {...{ sortData: { getDefaultSort, onSort }, columns, allowSelection: true, onSelectAll: props?.onAllSelected, selectedCount: props?.selectedCount, rowCount: props?.dataCount }} />
                )
            }}
          />
        </ThemeProvider>
      </div>

      <div style={styles.item as CSSProperties}>
        {renderSummary()}
      </div>
      <AlertModal {...alertModalProps} />
      <AlertModal {...downloadModalProps} />
    </div>
  )
}

const footerStyles: CSSProperties = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}

const footerContentStyles: CSSProperties = {
  flexGrow: 1,
  flexBasis: 1,
  flexDirection: 'column',
  width: '100%',
  alignContent: 'right',
  alignItems: 'right',
  textAlign: 'right',
  paddingTop: '8px',
  paddingLeft: '16px',
  paddingRight: '16px',
}

const stylesTitleWithoutDownload: CSSProperties = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '1rem',
  padding: '8px'
}

const stylesTitleWithDownload: CSSProperties = {
  ...stylesTitleWithoutDownload,
  float: 'left',
  maxWidth: '85%'
}

const styles = {
  modalTitleWorkbook: {
    fontStyle: 'italic',
    marginTop: '8px'
  },
  tabPanel: {
    downloadQueue: {
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingTop: '0px',
      paddingBottom: '8px'
    }
  },
  container: {
    width: '100%',
    height: '100%',
    maxHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  item: {
    width: '100%',
    flexGrow: '0',
    flexBasis: 'auto',
    marginBottom: '4px',
  },
  fillItem: {
    width: '100%',
    flexBasis: '0',
    flexGrow: '1',
    display: 'flex',
    overflow: 'auto',
  },
}

export default WorkbookContents
