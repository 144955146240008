import React, { useState } from 'react'
import { IconButton } from '@mui/material'
import SubscriptionModal, { SubscriptionModalProps } from '../Modals/SubscriptionModal'
import LockedCheckbox from '../../Icons/LockedCheckbox'

interface TrialUserLockProps {
    checkIsTrial: () => boolean,
    message: string
}

export const trialUserSeriesSelectMessage: string = 'Trial users cannot select more than 1 series.'
export const trialUserDownloadOptionsMessage: string = 'Trial users have limited download options.'

const TrialUserLock = ({ 
    checkIsTrial,
    message 
}: TrialUserLockProps) => {

    const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false)

    const upgradeModalProps: SubscriptionModalProps = {
        showModal: showUpgradeModal,
        setShow: setShowUpgradeModal,
        checkIsTrial: checkIsTrial
    }

    return (
        <>
            <IconButton aria-disabled
                title={message}
                size='small'
                onClick={() => {
                    setShowUpgradeModal(true)
                }}
            >
                <LockedCheckbox sx={{ width: '17.5px', height: '17.5px' }} />
            </IconButton>
            {showUpgradeModal && <SubscriptionModal {...upgradeModalProps} />}
        </>
    )
}

export default TrialUserLock
