import React, { useEffect, useState } from 'react'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import OutputFormatValue, { OutputFormatValueType } from '../../../Models/DataModels/Common/OutputFormatModel'
import { ChartSearchResultDataRequest, ChartSearchResultDataResponse, ChartTypes, SearchResultData } from '../../../Models/DataModels/Common/ToolsModel'
import { CreateDataFileRequest, FileDownloadRequest } from '../../../Models/DataModels/Requests/ToolsRequests'
import { CreateDataFileResponse } from '../../../Models/DataModels/Responses/ToolsResponses'
import { abortRequest, abortKeys } from '../../../Services/APIService'
import { downloadFileFromServer } from '../../../Services/DownloadService'
import { createDownloadDataFile } from '../../../Services/ToolsService'
import GraphDownloadOptions, { GraphDownloadOptionsProps } from '../../Common/Modals/GraphDownloadOptions/GraphDownloadOptions'
import { GFDToastError, GFDToastInfo } from '../../Common/Utility/GFDToastify'
import { createSearchResultsDownloadData } from './SearchResultsChartUtil'
import SelectedSearchResultsBarChart, { SelectedSearchResultsBarChartProps } from './SelectedSearchResultsBarChart'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { chartSearchResultsData } from '../../../Services/GraphService'
import { Autocomplete, ClickAwayListener, Grid, IconButton, TextField, Tooltip } from '@mui/material'
import { Button } from 'react-bootstrap'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { AppConfigurations } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import { SearchDatabaseTypes, nativeCurrencyEntry } from '../../../Models/DataModels/Common/FieldPopulationModel'
import { LabelValuePair } from '../../../Models/DataModels/Common/LabelValuePairModel'
import { getLocallyStoredCurrencies } from '../../../Services/DropDownValuesService'
import GraphwLines from '../../Icons/GraphwLines'
import PieGraph from '../../Icons/PieGraphIcon'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ErrorHandler, ErrorHandlerProps } from '../../Common/Utility/ErrorHandler'
import { useLocation, useNavigate } from 'react-router-dom'
import { bases, pathParts, paths } from '../../../Models/DataModels/Common/RedirectionModel'
import GraphQuickSearch from './GraphQuickSearch'
import { getLocalStorageOrDefault, localStorageKeys } from '../../../Services/LocalStorageService'
import SelectToolbarLineGraphModal from '../../Common/Modals/SelectToolbarLineGraphModal'
import SelectedSearchResultsPieChart, { SelectedSearchResultsPieChartProps } from './SelectedSearchResultsPieChart'
import BarGraph from '../../Icons/BarGraph'
import { seriesMaxDate, seriesMinDate, validDateInRange } from '../../../Services/UtilityService'
import SelectToolbarSelectedResultsChartSetDateModal, { SelectToolbarSelectedResultsChartSetDateModalProps } from '../../Common/Modals/SelectToolbarSelectedResultsChartSetDateModal'
import { getSessionStorageOrDefault, sessionStorageKeys } from '../../../Services/SessionStorageService'
import { RefreshResponse } from '../../../Models/DataModels/Responses/AuthResponses'
import SessionTimer, { SessionTimerProps } from '../../Common/Modals/SessionTimer/SessionTimer'
import { ComponentMessageHandler, ComponentMessageHandlerProps } from '../../Common/Utility/ComponentMessageHandler'
import { MessageResponse } from '../../../Models/DataModels/Responses/NotOKResponse'
import LoadingScreen from '../../Common/Modals/LoadingScreen'
import { globalStyles } from '../../Common/GlobalSettings/GlobalStyles'
import { CustomBtnBlue } from '../../Common/GlobalSettings/CustomStyles'

export interface SearchResultsChartDisplayProps {
    checkIsTrial: () => boolean,
    signOut: (logoutReason: LogoutReasonType) => void,
    processRefreshTokenResponse: (response: RefreshResponse) => void
}

const SearchResultsChartDisplay = ({
    checkIsTrial,
    signOut,
    processRefreshTokenResponse
}: SearchResultsChartDisplayProps) => {

    const errorMessages = {
        noResults: 'No data available for the requested date'
    }

    const imageID: string = 'searchResultsChartImage'
    const location: any = useLocation()
    const navigate = useNavigate()
    const isPieChart = location.pathname.includes(pathParts.graph.pieChart)

    const [executeOnceDelayedProcessing, setExecuteOnceDelayedProcessing] = useState<boolean>(false)

    const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
    const [showLoadingModalWithCancel, setShowLoadingModalWithCancel] = useState<boolean>(false)
    const [selectedSeriesMinDate, setSelectedSeriesMinDate] = useState<moment.Moment>(seriesMinDate)
    const [selectedSeriesMaxDate, setSelectedSeriesMaxDate] = useState<moment.Moment>(seriesMaxDate)
    const [currencyList, setCurrencyList] = useState<LabelValuePair[]>([nativeCurrencyEntry])
    const [selectedCurrency, setSelectedCurrency] = useState<LabelValuePair | null>(null)

    const [searchResultData, setSearchResultData] = useState<SearchResultData[]>([])
    const [title, setTitle] = useState<string>('')
    const [closeDate, setCloseDate] = useState<moment.Moment | null>(location?.state?.selectedResultsChartDateISOString && moment(location.state.selectedResultsChartDateISOString).isValid() ? moment(location.state.selectedResultsChartDateISOString) : null)
    const [previousRequestCloseDate, setPreviousRequestCloseDate] = useState<moment.Moment | null>(null)

    const [showComparisonModal, setShowComparisonModal] = useState<boolean>(false)
    const [showSetDateModal, setShowSetDateModal] = useState<boolean>(false)
    const [chartToggled, setChartToggled] = useState<boolean>(false)

    const [messages, setMessages] = useState<MessageResponse[]>([])

    const cancelAction = () => {
        abortRequest(abortKeys.searchChartLoading)
        GFDToastInfo('Charting cancelled')
    }

    const downloadDataFile = (format: OutputFormatValueType) => {
        const dataFileRequest: CreateDataFileRequest = {
            fileName: 'Search Results',
            fileData: createSearchResultsDownloadData(searchResultData),
            outputFormat: format
        }
        createDownloadDataFile(dataFileRequest).then(
            (fileCreateResponse: CreateDataFileResponse) => {
                const downloadRequest: FileDownloadRequest = {
                    filename: fileCreateResponse.filename,
                    mimeType: fileCreateResponse.mimeType
                }
                downloadFileFromServer(downloadRequest, setErrorResponse)
            },
            (error: NotOKResponseModel) => {
                setErrorResponse(error)
            }
        )
    }

    const downloadCSV = () => {
        downloadDataFile(OutputFormatValue.CSV)
    }

    const downloadExcel = () => {
        downloadDataFile(OutputFormatValue.Excel)
    }

    const graphDownloadOptionsProps: GraphDownloadOptionsProps = {
        downloadCSV: downloadCSV,
        downloadExcel: downloadExcel,
        imageIDValue: imageID
    }

    const componentMessageHandlerProps: ComponentMessageHandlerProps = {
        messages,
        setMessages,
        response: errorResponse,
        signOut
    }

    const errorHandlerProps: ErrorHandlerProps = {
        response: errorResponse,
        signOut: signOut
    }

    const selectedSearchResultsBarChartProps: SelectedSearchResultsBarChartProps = {
        imageID: imageID,
        chartTitle: title,
        searchData: searchResultData
    }

    const selectedSearchResultsPieChartProps: SelectedSearchResultsPieChartProps = {
        imageID: imageID,
        chartTitle: title,
        searchData: searchResultData
    }

    const sessionTimerProps: SessionTimerProps = {
        processRefreshTokenResponse,
        signOut: signOut
    }

    const isFromSessionStorage = (): boolean => {
        return [bases.searchHome, bases.searchHomeAlternative].includes(location?.state?.lastPathFromCharts) ||
            location?.state?.lastPathFromCharts?.includes(pathParts.search.searchSingle) ||
            location?.state?.lastPathFromCharts?.includes(pathParts.selectedResults) ||
            location?.state?.lastPathFromCharts?.includes(pathParts.tools.famaFrench)
    }

    const getSavedGraphSeries = () => {
        return isFromSessionStorage() ?
            getSessionStorageOrDefault(sessionStorageKeys.selectedResultsForChartsData, [])
            :
            getLocalStorageOrDefault(localStorageKeys.selectedResultsData, true, [])
    }

    const requestCloseDateData = () => {
        const savedGraphSeries: any[] = getSavedGraphSeries()
        const seriesIDs: number[] = savedGraphSeries.map((value: any) => value.id)
        const compareLimit: number = AppConfigurations.selectedSeriesChartingLimit
        const targetSeriesIDs: number[] = seriesIDs.length > compareLimit ? seriesIDs.slice(0, compareLimit) : seriesIDs

        if (seriesIDs.length > compareLimit) {
            GFDToastInfo(`${compareLimit} series from selected results were selected`)
        }

        setMessages([])
        setSearchResultData([])
        setTitle('')

        if (targetSeriesIDs.length === 0) {
            GFDToastError('No entries selected')
            return
        }

        setShowLoadingModalWithCancel(true)

        const closeDateRequest: moment.Moment = moment().utc()
        const currentDateTime: moment.Moment = moment()
        closeDateRequest.set('year', closeDate?.year() || currentDateTime.year());
        closeDateRequest.set('month', closeDate && closeDate.month() >= 0 ? closeDate.month() : currentDateTime.month())
        closeDateRequest.set('date', closeDate?.date() || currentDateTime.date());

        const request: ChartSearchResultDataRequest = {
            seriesIDs: targetSeriesIDs,
            selectedDate: closeDateRequest || null,
            currency: selectedCurrency ? selectedCurrency.value : nativeCurrencyEntry.value,
            currencyName: selectedCurrency ? selectedCurrency.label : nativeCurrencyEntry.label
        }

        setPreviousRequestCloseDate(closeDate)

        chartSearchResultsData(request).then(
            (response: ChartSearchResultDataResponse) => {
                if (response) {
                    setTitle(response.title)
                    setSearchResultData(response.searchResultData)
                    if (response.searchResultData.length === 0) {
                        GFDToastError(errorMessages.noResults)
                    }
                } else {
                    setTitle('')
                    setSearchResultData([])
                    GFDToastError(errorMessages.noResults)
                }
                setShowLoadingModalWithCancel(false)
            },
            (notOKResponseModel: NotOKResponseModel) => {
                if (notOKResponseModel?.statusCode !== 499) {
                    setErrorResponse(notOKResponseModel)
                }
                setShowLoadingModalWithCancel(false)
            }
        )
        setExecuteOnceDelayedProcessing(false)
    }

    const goBack = () => {
        const fromGraphNavPayload: any = {
            state: {
                prevPage: location.pathname,
                ...(location?.state?.engineOption && { engineOption: location.state.engineOption }),
                ...(location?.state?.lastPathFromCharts && { lastPathFromCharts: location.state.lastPathFromCharts })
            }
        }
        if (location?.state?.lastPathFromCharts) {
            navigate(location.state.lastPathFromCharts, fromGraphNavPayload)
        } else if (location?.state?.engineOption) {
            navigate(pathParts.search.searchSingle + location.state.engineOption, fromGraphNavPayload)
        } else {
            navigate(paths.searchHome.base, fromGraphNavPayload)
        }
    }

    const graphSearchAction = (seachTerm: string) => {
        const fromGraphNavPayload: any = { state: { ...location?.state, prevPage: location.pathname } }
        navigate(`${paths.graph.lineGraph}/${seachTerm}`, fromGraphNavPayload)
    }

    const selectToolbarLineGraphModalProps = {
        showLineGraphModal: showComparisonModal,
        setShowLineGraphModal: setShowComparisonModal,
        selectedResults: getSavedGraphSeries(),
        engineOption: location?.state?.engineOption || SearchDatabaseTypes.GFDUniverse,
        ...(location?.state?.lastPathFromCharts && { lastPathFromCharts: location.state.lastPathFromCharts })
    }

    const selectToolbarSelectedResultsChartSetDateModalProps: SelectToolbarSelectedResultsChartSetDateModalProps = {
        chartType: isPieChart ? ChartTypes.Bar : ChartTypes.Pie,
        engineOption: location?.state?.engineOption || SearchDatabaseTypes.GFDUniverse,
        show: showSetDateModal,
        setShow: setShowSetDateModal,
        defaultDate: closeDate || moment(),
        toggled: true,
        ...(location?.state?.lastPathFromCharts && { lastPathFromCharts: location.state.lastPathFromCharts })
    }

    const triggerRequest = () => {
        if (!validDateInRange(closeDate) || isSameDateAsPrevious()) {
            return
        }
        requestCloseDateData()
    }

    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        triggerRequest()
    }

    const isSameDateAsPrevious = (): boolean => {
        if (closeDate === previousRequestCloseDate) {
            return true
        }
        return false
    }

    const handleViewComparisonChartButton = () => {
        const savedGraphSeries: any[] = getSavedGraphSeries()
        if (savedGraphSeries.length === 1) {
            graphSearchAction(savedGraphSeries[0].symbol)
        } else {
            setShowComparisonModal(true)
        }
    }

    useEffect(() => {
        setExecuteOnceDelayedProcessing(true)
    }, [])

    useEffect(() => {
        if (!executeOnceDelayedProcessing) {
            return
        }
        getLocallyStoredCurrencies().then((value: LabelValuePair[] | null) => value && setCurrencyList(value), () => { })
        requestCloseDateData()
    }, [executeOnceDelayedProcessing])

    useEffect(() => {
        if (!selectedCurrency) {
            return
        }
        requestCloseDateData()
    }, [selectedCurrency])

    useEffect(() => {
        const newDate: moment.Moment | null = location?.state?.selectedResultsChartDateISOString && moment(location.state.selectedResultsChartDateISOString).isValid() ? moment(location.state.selectedResultsChartDateISOString) : null
        setCloseDate(newDate)
        if (location?.state?.toggled) {
            setChartToggled(true)
        }
    }, [location])

    useEffect(() => {
        if (chartToggled) {
            triggerRequest()
            setChartToggled(false)
        }
    }, [chartToggled])

    useEffect(() => {
        if (searchResultData.length === 0) {
            return
        }
        const savedGraphSeries: any[] = getSavedGraphSeries()
        const startDates: Date[] = []
        const endDates: Date[] = []
        savedGraphSeries.forEach((savedSeries: any) => {
            if (savedSeries?.startDate) {
                startDates.push(new Date(savedSeries.startDate))
            }
            if (savedSeries?.endDate) {
                endDates.push(new Date(savedSeries.endDate))
            }
        })
        if (startDates.length > 0) {
            const sortedStartDates: Date[] = startDates.sort((a: Date, b: Date) => a.getTime() - b.getTime())
            const savedSeriesEarliest: moment.Moment = moment(sortedStartDates[0])
            setSelectedSeriesMinDate(savedSeriesEarliest)
        }
        if (endDates.length > 0) {
            const sortedEndDates: Date[] = endDates.sort((a: Date, b: Date) => a.getTime() - b.getTime())
            const savedSeriesLatest: moment.Moment = moment(sortedEndDates[sortedEndDates.length - 1])
            setSelectedSeriesMaxDate(savedSeriesLatest)
        }
    }, [searchResultData])

    return (
        <React.Fragment>
            <ComponentMessageHandler {...componentMessageHandlerProps} />
            <Grid container columns={16} justifyContent='flex-start' spacing={1} sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 1, paddingRight: 1 }}>
                <Grid item md={2}>
                    <Grid container justifyContent='flex-start' alignItems='center'>
                        <Grid item>
                            <Tooltip title='Go Back'>
                                <CustomBtnBlue id='barChartBackButton' variant='contained' style={{ marginRight: 5, backgroundColor: globalStyles.siteThemeColor, color: '#FFFFFF' }} onClick={goBack}>
                                    <ArrowBackIcon />
                                </CustomBtnBlue>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <IconButton
                                title={'Click to View Comparison Chart'}
                                onClick={handleViewComparisonChartButton}
                            >
                                <GraphwLines style={{ height: '32px', width: '32px' }} />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            {!isPieChart ?
                                <IconButton
                                    title={'Click to View Comparison Pie Chart'}
                                    onClick={() => { setShowSetDateModal(true) }}
                                >
                                    <PieGraph style={{ height: '32px', width: '32px' }} />
                                </IconButton>
                                :
                                <IconButton
                                    title={'Click to View Comparison Bar Chart'}
                                    onClick={() => { setShowSetDateModal(true) }}
                                >
                                    <BarGraph style={{ height: '32px', width: '32px' }} />
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={5}>
                    <Grid container justifyContent='flex-start' spacing={1} columns={16}>
                        <Grid item md={7}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <ClickAwayListener onClickAway={handleClickOutside} disableReactTree={true}>
                                    <DatePicker
                                        label={'Set Date'}
                                        slotProps={{
                                            textField: {
                                                id: 'searchResultChartSetDate',
                                                size: 'small',
                                                onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
                                                    if (e.key === 'Enter') {
                                                        triggerRequest()
                                                    }
                                                }
                                            },
                                            field: {
                                                clearable: true,
                                                onClear: () => {
                                                    setCloseDate(null)
                                                }
                                            }
                                        }}
                                        value={closeDate}
                                        onChange={(newValue: moment.Moment | null) => {
                                            if (validDateInRange(newValue)) {
                                                setCloseDate(newValue as moment.Moment)
                                            }
                                        }}
                                        sx={{ fontWeight: 'bold' }}
                                        minDate={selectedSeriesMinDate}
                                        maxDate={selectedSeriesMaxDate}
                                    />
                                </ClickAwayListener>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={9}>
                            <Autocomplete
                                disablePortal
                                id='selectChartCurrency'
                                options={currencyList}
                                value={selectedCurrency}
                                onChange={(e, value) => {
                                    if (value) {
                                        setSelectedCurrency(value)
                                    } else {
                                        setSelectedCurrency(nativeCurrencyEntry)
                                    }
                                }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label='Select Currency'
                                        size='small'
                                    />
                                }
                                disabled={false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <GraphQuickSearch graphSearchAction={graphSearchAction} />
                </Grid>
                <Grid item md={3}>
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <GraphDownloadOptions {...graphDownloadOptionsProps} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {!isPieChart ? <SelectedSearchResultsBarChart {...selectedSearchResultsBarChartProps} /> : <SelectedSearchResultsPieChart {...selectedSearchResultsPieChartProps} />}
            <LoadingScreen showModal={showLoadingModalWithCancel} setShowModal={setShowLoadingModalWithCancel} cancelAction={cancelAction} />
            <ErrorHandler {...errorHandlerProps} />
            {showComparisonModal && <SelectToolbarLineGraphModal modalProps={selectToolbarLineGraphModalProps} />}
            {showSetDateModal && <SelectToolbarSelectedResultsChartSetDateModal {...selectToolbarSelectedResultsChartSetDateModalProps} />}
            <SessionTimer {...sessionTimerProps} />
        </React.Fragment>
    )
}

export default SearchResultsChartDisplay
