import {
    ChangePasswordRequest,
    CheckExistingOrgRequest,
    CheckSeriesAccessRequest,
    RegisterUserRequest,
    SearchUserRequest
} from "../Models/DataModels/Requests/AccountRequests"
import getAPICallResult, { APIRequest, HttpRequest, url } from "./APIService"

export const changePassword = async (request: ChangePasswordRequest) => {
    const apiRequest: APIRequest = {
        relativePath: 'User/ResetPassword',
        method: HttpRequest.Put,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// get access info
export const getAccessInfo = async () => {
    const apiRequest: APIRequest = {
        relativePath: 'User/Access',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const getUserEngineAccessibilities = async () => {
    const apiRequest: APIRequest = {
        relativePath: 'User/Access/EngineAccessibilities',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const getUserToolAccessibilities = async () => {
    const apiRequest: APIRequest = {
        relativePath: 'User/Access/ToolAccessibilities',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const checkUserSeriesAccess = async (request: CheckSeriesAccessRequest) => {
    const apiRequest: APIRequest = {
        relativePath: 'User/Access/CheckSeriesAccess',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

// get activity info
export const getActivityInfo = async () => {
    const apiRequest: APIRequest = {
        relativePath: 'User/Activity',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const getOrganizationActivityInfo = async () => {
    const apiRequest: APIRequest = {
        relativePath: 'User/OrganizationActivity',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

// get quota info
export const getQuotaInfo = async () => {
    const apiRequest: APIRequest = {
        relativePath: 'User/quota',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const getOrganizationQuotaInfo = async () => {
    const apiRequest: APIRequest = {
        relativePath: 'User/OrganizationQuota',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

// get quota breakdown info
export const getQuotaBreakdownInfo = async () => {
    const apiRequest: APIRequest = {
        relativePath: 'User/quota/BreakDown',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

// is manager
export const getIsManager = async () => {
    const apiRequest: APIRequest = {
        relativePath: 'User/IsManager',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

// has subscription
export const getHasSubscription = async () => {
    const apiRequest: APIRequest = {
        relativePath: 'User/HasSubscription',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const getHasUSOrUKSubscription = async () => {
    const apiRequest: APIRequest = {
        relativePath: 'User/HasUSOrUKStockSubscription',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const checkAnonymousAccessRegistration = async () => {
    const apiRequest: APIRequest = {
        relativePath: 'User/Register/CheckAnonymousAccess',
        method: HttpRequest.Get,
        isAnonymous: true
    }
    return getAPICallResult(apiRequest, true)
}

export const CheckExistingOrg = async (request: CheckExistingOrgRequest) => {
    const apiRequest: APIRequest = {
        relativePath: 'User/Register/CheckExistingOrg',
        method: HttpRequest.Post,
        requestObject: request,
        isAnonymous: true
    }
    return getAPICallResult(apiRequest)
}

export const registerUser = async (request: RegisterUserRequest) => {
    const apiRequest: APIRequest = {
        relativePath: 'User/Register',
        method: HttpRequest.Post,
        requestObject: request,
        isAnonymous: true
    }
    return getAPICallResult(apiRequest)
}

export const searchUser = async (request: SearchUserRequest) => {
    const apiRequest: APIRequest = {
        relativePath: 'User/Search',
        method: HttpRequest.Post,
        requestObject: request
    }
    return getAPICallResult(apiRequest)
}

export const fetchRecentlyLoadedUsers = async () => {
    const apiRequest: APIRequest = {
        relativePath: 'User/RecentlyLoadedUsers',
        method: HttpRequest.Get
    }
    return getAPICallResult(apiRequest)
}

export const agreeCookie = async (authToken: any) => {

    const apiRequest: APIRequest = {
        relativePath: 'User/CookieAgreed',
        method: HttpRequest.Put
    }

    return getAPICallResult(apiRequest, false, false, authToken)
}
