import { useEffect, useState } from "react"
import CapitalizationResults, { CapitalizationResultsProps } from "./CapitalizationSubComponents/ResultScreen/CapitalizationResults"
import CapitalizationStartScreen, { CapitalizationStartScreenProps } from "./CapitalizationSubComponents/StartScreen/CapitalizationStartScreen"
import { CapitalizationData, CapitalizationSummary, ToolsAccesibilityProps, ToolsStockProps } from "../../../../Models/DataModels/Common/ToolsModel"
import { SearchConstMemberTypes, blankEntry, allEntry, SearchDatabaseTypes, SOUCurrencyEntry, CurrencyGroups } from "../../../../Models/DataModels/Common/FieldPopulationModel"
import { LabelValuePair } from "../../../../Models/DataModels/Common/LabelValuePairModel"
import { PopulateConstituentIndicesRequest, PopulateCountryRequest } from "../../../../Models/DataModels/Requests/PopulationRequests"
import { populateConstituentIndices, populateCountry } from "../../../../Services/FieldPopulationService"
import moment from "moment"
import { LogoutReasonType } from "../../../../Models/DataModels/Requests/AuthRequests"
import { ErrorHandler, ErrorHandlerProps } from "../../../Common/Utility/ErrorHandler"
import { NotOKResponseModel } from "../../../../Models/DataModels/Common/NotOKResponseModel"
import CapitalizationIcon from "../../../Icons/CapitalizationIcon"
import { Grid } from "@mui/material"
import { GetCapitalizationListRequest, GetCapitalizationSummaryRequest } from "../../../../Models/DataModels/Requests/ToolsRequests"
import { GetCapitalizationListResponse } from "../../../../Models/DataModels/Responses/ToolsResponses"
import { getCapitalizationList } from "../../../../Services/ToolsService"
import { GFDToastError } from "../../../Common/Utility/GFDToastify"
import { getLocallyStoredExchangeCountries, getLocallyStoredGroupCurrencies, getLocallyStoredIndustries, getLocallyStoredSectors } from "../../../../Services/DropDownValuesService"
import { UserInfo } from "../../../../Models/DataModels/Common/UserInfoModel"
import LoadingScreen from "../../../Common/Modals/LoadingScreen"

export enum CapitalizationResultType {
  List = 1 << 0,
  Summary = 1 << 1,
  Chart = 1 << 2
}

export interface CapitalizationProps {
  userInfo: UserInfo | null,
  checkIsTrial: () => boolean,
  signOut: (logoutReason: LogoutReasonType) => void,
  accessibilityProps: ToolsAccesibilityProps,
  stocksProps: ToolsStockProps
}

export type SummaryOptions = 'Sector' | 'Industry' | 'Country'

export const SummaryOptionsTypes = {
  Sector: 'Sector' as SummaryOptions,
  Industry: 'Industry' as SummaryOptions,
  Country: 'Country' as SummaryOptions
}

export const summaryInfoOptions: string[] = [
  SummaryOptionsTypes.Sector,
  SummaryOptionsTypes.Industry,
  SummaryOptionsTypes.Country
]

export const DefaultHistorySummaryPreviousYears: number = 10

const Capitalization = ({
  userInfo,
  checkIsTrial,
  signOut,
  accessibilityProps,
  stocksProps
}: CapitalizationProps) => {

  const operatingCountrySpecialOptions: LabelValuePair[] = [
    allEntry,
    { label: 'All x/USA', value: 'All x/USA' },
    { label: 'All x/UK', value: 'All x/UK' },
    { label: 'All x/UK and USA', value: 'All x/UK and USA' }
  ]

  const EuropeEntry: LabelValuePair = { label: 'EUROPE (RANGE VARIES)', value: 'EUR' }

  const [exchangeListLoading, setExchangeListLoading] = useState<boolean>(false)

  const PreviousMonthIndex: number = moment().month() - 1

  const requestPopulateIndex = () => {
    const request: PopulateConstituentIndicesRequest = {
      category: SearchConstMemberTypes.MajorUS
    }

    populateConstituentIndices(request).then(
      (resIndices: LabelValuePair[]) => {
        setIndexOptions([...resIndices])
      },
      //Reject promise
      (notOKResponseModel: NotOKResponseModel) => {
        setErrorResponse(notOKResponseModel)
      }
    )
  }

  const requestPopulateListingCountry = () => {
    if (accessibilityProps.canUseCapitalizationTool) {
      setExchangeListLoading(true)
      getLocallyStoredExchangeCountries().then((value: LabelValuePair[] | null) => {
        let filteredList: LabelValuePair[] = []
        if (value) {
          filteredList = value
          if (stocksProps.hasUKStocksAccess || stocksProps.hasGFDIndicesAccess) {
            filteredList.push(EuropeEntry)
          }
        }
        if (!stocksProps.hasUSStocksAccess && !stocksProps.hasGFDIndicesAccess) {
          filteredList = filteredList.filter((exchange: any) => {
            return exchange.value !== 'USA' && exchange.value !== 'CAN'
          })
        }
        if (!stocksProps.hasUKStocksAccess && !stocksProps.hasGFDIndicesAccess) {
          filteredList = filteredList.filter((exchange: any) => {
            return exchange.value === 'USA' || exchange.value === 'CAN'
          })
        }
        filteredList.sort((a: any, b: any) => {
          return a.label.localeCompare(b.label)
        })
        setListingCountryOptions([allEntry, ...filteredList])
        setExchangeListLoading(false)
      },
        (notOKResponseModel: NotOKResponseModel) => {
          setErrorResponse(notOKResponseModel)
          setExchangeListLoading(false)
        }
      )
    }
  }

  const requestPopulateOperatingCountry = () => {
    const request: PopulateCountryRequest = {
      database: SearchDatabaseTypes.Unknown
    }
    populateCountry(request).then(
      (resOperatingCountries: LabelValuePair[]) => {
        resOperatingCountries.unshift(...operatingCountrySpecialOptions)
        setOperatingCountryOptions([...resOperatingCountries])
      },
      //Reject promise
      (notOKResponseModel: NotOKResponseModel) => {
        setErrorResponse(notOKResponseModel)
      }
    )
  }

  const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
  const [showStartScreen, setShowStartScreen] = useState<boolean>(true)
  const [summaryLinkClicked, setSummaryLinkClicked] = useState<boolean>(false)
  const [selectedMonth, setSelectedMonth] = useState<number>((PreviousMonthIndex < 0 ? 11 : PreviousMonthIndex) + 1)
  const [selectedYear, setSelectedYear] = useState<number>(moment().year())
  const [indexOptions, setIndexOptions] = useState<LabelValuePair[]>([])
  const [listingCountryOptions, setListingCountryOptions] = useState<LabelValuePair[]>([allEntry])
  const [operatingCountryOptions, setOperatingCountryOptions] = useState<LabelValuePair[]>([allEntry])
  const [sectorOptions, setSectorOptions] = useState<LabelValuePair[]>([])
  const [industryOptions, setIndustryOptions] = useState<LabelValuePair[]>([])
  const [currencyOptions, setCurrencyOptions] = useState<LabelValuePair[]>([SOUCurrencyEntry])
  const [selectedIndex, setSelectedIndex] = useState<LabelValuePair>(blankEntry)
  const [selectedCurrency, setSelectedCurrency] = useState<LabelValuePair>(SOUCurrencyEntry)
  const [capResultType, setCapResultType] = useState<CapitalizationResultType>(CapitalizationResultType.List)
  const [listResult, setListResult] = useState<CapitalizationData[]>([])
  const [summaryResult, setSummaryResult] = useState<CapitalizationSummary[]>([])
  const [constituentSelected, setConstituentSelected] = useState<boolean>(true)
  const [selectedListingCountry, setSelectedListingCountry] = useState<LabelValuePair>(allEntry)
  const [selectedOperatingCountry, setSelectedOperatingCountry] = useState<LabelValuePair>(allEntry)
  const [selectedSector, setSelectedSector] = useState<LabelValuePair>(blankEntry)
  const [selectedIndustry, setSelectedIndustry] = useState<LabelValuePair>(blankEntry)
  const [yearOverYearInputFieldsEnabled, setYearOverYearInputFieldsEnabled] = useState<boolean>(false)
  const [yearOverYearStartDate, setYearOverYearStartDate] = useState<moment.Moment>(moment().subtract(1, 'years'))
  const [selectedSummaryInfo, setSelectedSummaryInfo] = useState<string>(summaryInfoOptions[0])
  const [historicalChartChecked, setHistoricalChartChecked] = useState<boolean>(false)
  const [summaryPreviousYears, setSummaryPreviousYears] = useState<number>(DefaultHistorySummaryPreviousYears)
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)
  const [currentRequest, setCurrentRequest] = useState<any>()
  const [currentSummaryRequest, setCurrentSummaryRequest] = useState<GetCapitalizationSummaryRequest | null>(null)

  useEffect(requestPopulateIndex, [])
  useEffect(requestPopulateListingCountry, [accessibilityProps.canUseCapitalizationTool])
  useEffect(requestPopulateOperatingCountry, [])

  useEffect(() => {
    getLocallyStoredSectors(SearchDatabaseTypes.Unknown).then((value: LabelValuePair[] | null) => value && setSectorOptions(value), () => { })
    getLocallyStoredIndustries(SearchDatabaseTypes.Unknown, '').then((value: LabelValuePair[] | null) => value && setIndustryOptions(value), () => { })
  }, [])

  useEffect(() => {
    getLocallyStoredIndustries(SearchDatabaseTypes.UKStocks, selectedSector.value).then((value: LabelValuePair[] | null) => value && setIndustryOptions(value), () => { })
  }, [selectedSector])

  useEffect(() => {
    getLocallyStoredGroupCurrencies(CurrencyGroups.Capitalization).then((value: LabelValuePair[] | null) => value && setCurrencyOptions(value), () => { })
  }, [])

  useEffect(() => {
    accessibilityProps.loadRequestInProgress ? setShowLoadingModal(true) : setShowLoadingModal(false)
  }, [accessibilityProps.loadRequestInProgress])

  const doGetCapitalizationListRequest = (selectedSummaryLabel?: string) => {

    const capDateMoment: moment.Moment = moment({ year: selectedYear, month: selectedMonth - 1 })

    let sectorCode: string | null = null
    let industryCode: string | null = null
    let operatingCountryCode: string | null = null

    if (selectedSummaryLabel) {
      switch (selectedSummaryInfo) {
        case SummaryOptionsTypes.Sector:
          sectorCode = sectorOptions.find((value: LabelValuePair) => value.label.toLowerCase() === selectedSummaryLabel.toLowerCase())?.value || null
          break
        case SummaryOptionsTypes.Industry:
          industryCode = industryOptions.find((value: LabelValuePair) => value.label.toLowerCase() === selectedSummaryLabel.toLowerCase())?.value || null
          break
        case SummaryOptionsTypes.Country:
          operatingCountryCode = operatingCountryOptions.find((value: LabelValuePair) => value.label.toLowerCase() === selectedSummaryLabel.toLowerCase())?.value || null
          break
        default:
          break
      }
    }

    const getCapListRequest: GetCapitalizationListRequest = {
      date: capDateMoment.toDate(),
      index: selectedIndex.value,
      operatingCountry: operatingCountryCode || selectedOperatingCountry.value,
      listingCountry: selectedListingCountry.value,
      sector: sectorCode || selectedSector.value,
      industry: industryCode || selectedIndustry.value,
      currency: selectedCurrency.value
    }
    setCurrentRequest(getCapListRequest)

    if (yearOverYearInputFieldsEnabled) {
      const YOYStartDateMoment: moment.Moment = moment({ year: yearOverYearStartDate.year(), month: yearOverYearStartDate.month() })

      if (getCapListRequest.date) {
        if (YOYStartDateMoment > capDateMoment) {
          GFDToastError('Start Date cannot be after market cap date!')
          return
        }
      }
      getCapListRequest.startDate = YOYStartDateMoment.toDate()
    }

    setShowLoadingModal(true)
    getCapitalizationList(getCapListRequest).then(
      (response: GetCapitalizationListResponse) => {
        const capDataList: CapitalizationData[] = response.dataList
        if (capDataList.length > 0) {
          setListResult(capDataList)
          setCapResultType(CapitalizationResultType.List)
          setShowStartScreen(false)
        } else {
          GFDToastError('No Result')
        }
        setShowLoadingModal(false)
      },
      //Reject promise
      (notOKResponseModel: NotOKResponseModel) => {
        setErrorResponse(notOKResponseModel)
        setShowLoadingModal(false)
      }
    )
  }

  const startScreenProps: CapitalizationStartScreenProps = {
    setErrorResponse: setErrorResponse,
    setShowStartScreen: setShowStartScreen,
    selectedMonth: selectedMonth,
    setSelectedMonth: setSelectedMonth,
    selectedYear: selectedYear,
    setSelectedYear: setSelectedYear,
    indexOptions: indexOptions,
    listingCountryOptions: listingCountryOptions,
    operatingCountryOptions: operatingCountryOptions,
    sectorOptions: sectorOptions,
    industryOptions: industryOptions,
    currencyOptions: currencyOptions,
    selectedIndex: selectedIndex,
    setSelectedIndex: setSelectedIndex,
    selectedCurrency: selectedCurrency,
    setSelectedCurrency: setSelectedCurrency,
    setCapResultType: setCapResultType,
    setSummaryResult: setSummaryResult,
    constituentSelected: constituentSelected,
    setConstituentSelected: setConstituentSelected,
    selectedListingCountry: selectedListingCountry,
    setSelectedListingCountry: setSelectedListingCountry,
    selectedOperatingCountry: selectedOperatingCountry,
    setSelectedOperatingCountry: setSelectedOperatingCountry,
    selectedSector: selectedSector,
    setSelectedSector: setSelectedSector,
    selectedIndustry: selectedIndustry,
    setSelectedIndustry: setSelectedIndustry,
    yearOverYearInputFieldsEnabled: yearOverYearInputFieldsEnabled,
    setYearOverYearInputFieldsEnabled: setYearOverYearInputFieldsEnabled,
    yearOverYearStartDate: yearOverYearStartDate,
    setYearOverYearStartDate: setYearOverYearStartDate,
    selectedSummaryInfo: selectedSummaryInfo,
    setSelectedSummaryInfo: setSelectedSummaryInfo,
    historicalChartChecked: historicalChartChecked,
    setHistoricalChartChecked: setHistoricalChartChecked,
    summaryPreviousYears: summaryPreviousYears,
    setSummaryPreviousYears: setSummaryPreviousYears,
    setShowLoadingModal: setShowLoadingModal,
    doGetCapitalizationListRequest: doGetCapitalizationListRequest,
    canUseCapTool: accessibilityProps.canUseCapitalizationTool,
    exchangeListLoading: exchangeListLoading,
    setCurrentSummaryRequest: setCurrentSummaryRequest
  }

  const resultScreenProps: CapitalizationResultsProps = {
    userInfo,
    setShowStartScreen: setShowStartScreen,
    capResultType: capResultType,
    listResult: listResult,
    summaryResult: summaryResult,
    summaryCategory: selectedSummaryInfo,
    checkIsTrial,
    signOut: signOut,
    setErrorResponse: setErrorResponse,
    doGetCapitalizationListRequest: doGetCapitalizationListRequest,
    setSummaryLinkClicked: setSummaryLinkClicked,
    selectedCurrency: selectedCurrency,
    selectedIndex: selectedIndex,
    previousYears: summaryPreviousYears,
    currentRequest: currentRequest,
    currentSummaryRequest: currentSummaryRequest
  }

  const errorHandlerProps: ErrorHandlerProps = {
    response: errorResponse,
    signOut: signOut
  }

  useEffect(() => {
    if (summaryLinkClicked) {
      setShowStartScreen(false)
      setSummaryLinkClicked(false)
      setCapResultType(CapitalizationResultType.Summary)
    } else if (showStartScreen) {
      setListResult([])
      setSummaryResult([])
    }
  }, [showStartScreen])

  return (
    <div>
      <Grid container spacing={0} columns={3}>
        <Grid item sm={2} md={2} lg={1} flexGrow={1}>
          <div style={Styles.headerWrapper}>
            <CapitalizationIcon style={Styles.customIcon}></CapitalizationIcon>
            <h3 style={{ fontWeight: 'bold' }}>Capitalization</h3>
          </div>
        </Grid>
      </Grid>

      <ErrorHandler {...errorHandlerProps} />
      <LoadingScreen showModal={showLoadingModal} setShowModal={setShowLoadingModal} />
      <div style={Styles.optionsWrapper}>
        {showStartScreen ?
          !accessibilityProps.loadRequestInProgress && accessibilityProps.canUseCapitalizationTool !== null && <CapitalizationStartScreen {...startScreenProps} />
          :
          <CapitalizationResults {...resultScreenProps} />
        }
      </div>
    </div>
  )
}

const Styles = {
  customIcon: {
    width: '30px',
    height: '30px',
    marginRight: '10px'
  },

  headerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    width: '100%',
    background: 'linear-gradient(90deg, #81e5ca, #c7fdee)',
    padding: '12px 20px 8px 20px',
    borderRadius: '40px'
  },

  optionsWrapper: {
    paddingTop: '20px'
  }
}

export default Capitalization
