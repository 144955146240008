import { IconButton } from "@mui/material"
import { LogoutReasonType } from "../../../Models/DataModels/Requests/AuthRequests"
import DownLoad from "../../Icons/DownLoad"
import DownloadModal, { DownloadModalProps } from "../Modals/DownloadModal"
import { DownloadRequestSource } from "../../../Models/DataModels/Requests/AutoTracRequests"
import { TickerData } from "../../../Models/DataModels/Responses/AutoTracResponses"
import { useEffect, useState } from "react"
import AlertTickerLimitModal, { AlertTickerLimitModalProps } from "../Modals/AlertTickerLimitModal"
import DownLoadGreen from "../../Icons/DownLoadGreen"

export interface DownloadTriggerProps {
    triggerProcessDownload: boolean,
    resetTriggerProcessDownload: () => void,
    resetRowData: () => void
}

export interface DownloadActionProps {
    showButton?: boolean,
    directDownload?: boolean,
    downloadTriggerProps?: DownloadTriggerProps,
    isComposite: boolean,
    rowData: TickerData[] | null,
    maxTickerLimit: number,
    checkIsTrial: () => boolean,
    signOut: (logoutReason: LogoutReasonType) => void
}

const DownloadAction = ({
    showButton,
    directDownload,
    downloadTriggerProps,
    isComposite,
    rowData,
    maxTickerLimit,
    checkIsTrial,
    signOut
}: DownloadActionProps) => {
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false)
    const [selectedTickersForDownload, setSelectedTickersForDownload] = useState<TickerData[]>([])
    const [tickerLimitAlertMessage, setTickerLimitAlertMessage] = useState(`A maximum of ${maxTickerLimit} series is allowed per workbook. Please try with ${maxTickerLimit} series or less.`)
    const [showTickerLimitModal, setShowTickerLimitModal] = useState<boolean>(false)

    useEffect(() => {
        if (downloadTriggerProps?.triggerProcessDownload && rowData) {
            processDownload(rowData)
            if (downloadTriggerProps?.resetTriggerProcessDownload) downloadTriggerProps.resetTriggerProcessDownload()
            if (downloadTriggerProps?.resetRowData) downloadTriggerProps.resetRowData()
        }
    }, [downloadTriggerProps?.triggerProcessDownload, rowData])
    

    const processDownload = (data: any) => {
        if (!data || !data?.length) return

        let tickers: TickerData[] = Object.values(data)
        tickers = tickers.filter(ticker => ticker.id)

        // insert error pop up
        setSelectedTickersForDownload(tickers)

        if (tickers.length === 0) return
        if (tickers.length > maxTickerLimit) {
            setTickerLimitAlertMessage(`A maximum of ${maxTickerLimit} series is allowed per download. ${tickers.length} series have been selected. Please try with ${maxTickerLimit} or less.`)
            setShowTickerLimitModal(true)
            return
        }

        setShowDownloadModal(true)
    }

    const downloadModalProps: DownloadModalProps = {
        tickers: selectedTickersForDownload,
        checkIsTrial,
        signOut,
        showDownloadModal,
        setShowDownloadModal,
        downloadRequestSource: DownloadRequestSource.OnDemand,
        directDownload: directDownload ?? selectedTickersForDownload?.length === 1,
        isComposite
    }

    const alertTickerLimitModalProps: AlertTickerLimitModalProps = {
        showTickerLimitModal,
        setShowTickerLimitModal,
        tickerLimitAlertMessage
    }

    const renderDownloadAction = () => {
        return (
            <IconButton
                title={'Download'}
                onClick={() => processDownload([rowData])}
                size='small'
            >
                <DownLoadGreen />
            </IconButton>
        )
    }

    return (
        <>
            {(showButton ?? true) && renderDownloadAction()}
            {showDownloadModal && <DownloadModal {...downloadModalProps} />}
            {showTickerLimitModal && <AlertTickerLimitModal {...alertTickerLimitModalProps} />}
        </>
    )
}

export default DownloadAction
