import { Grid } from '@mui/material'
import { LogoutReasonType } from '../../Models/DataModels/Requests/AuthRequests'
import RegistrationForm from './RegistrationForm'
import RegistrationMarketing from './RegistrationMarketing'

export interface RegisterProps {
    signOut: (logoutReason: LogoutReasonType) => void
}

const Register = ({
    signOut
}: RegisterProps) => {

    return (
        <>
            <Grid container columns={16} >
                <Grid item md={8} sx={{ backgroundColor: 'white' }}>
                    <RegistrationMarketing />
                </Grid>
                <Grid item md={8}>
                    <RegistrationForm signOut={signOut} />
                </Grid>
            </Grid>
        </>
    )
}

export default Register
