import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import Tooltip from '@mui/material/Tooltip'
import { useEffect, useState } from 'react'
import { SearchOptionsData } from '../../../Models/DataModels/Common/SearchModels'
import SearchOptionModal from '../Modals/SearchOptionModal'
import { AutoCompleteRequest } from '../../../Models/DataModels/Requests/SearchRequests'
import { autoComplete } from '../../../Services/SearchService'
import { AutoCompleteData } from '../../../Models/DataModels/Responses/SearchResponses'
import { Autocomplete, TextField } from '@mui/material'
import { Box, Button, Grid } from '@mui/material'
import { SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'
import { globalStyles } from '../GlobalSettings/GlobalStyles'
import { CustomBtnBlue } from '../GlobalSettings/CustomStyles'

export interface TickerSearchProps {
  defaultSearchOptions?: SearchOptionsData,
  onSetSearchTerm: (searchTerm: string, searchOptions: SearchOptionsData) => void,
  onAcceptSearchTerm?: (searchTerm: string, searchOptions: SearchOptionsData) => void,
  resetSearchTermToggle: boolean
}

const AutoTracTickerSearch = ({
  defaultSearchOptions,
  onSetSearchTerm,
  onAcceptSearchTerm,
  resetSearchTermToggle
}: TickerSearchProps) => {
  const [searchOption, setSearchOption] = useState<string>(defaultSearchOptions?.searchOption || 'contains')
  const [sortOption, setSortOption] = useState<string>(defaultSearchOptions?.sortOption || 'popular')
  const [searchOptionsData, setSearchOptionsData] = useState<SearchOptionsData>({ searchOption: searchOption, sortOption: sortOption })

  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalDescription, setModalDescription] = useState<string>('')

  const defaultOptions: [any] = [{ label: '' }]
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<[any]>(defaultOptions)
  const [searchTerm, setSearchTerm] = useState<string>('')

  useEffect(() => {
    onSetSearchTerm(searchTerm, { searchOption: searchOption, sortOption: sortOption })
  }, [searchTerm, searchOption, sortOption])

  useEffect(() => {
    setSearchTerm('')
  }, [resetSearchTermToggle])

  const autoCompleteDataFactory = (label: string, value: string): AutoCompleteData => {
    return { label: label, value: value }
  }

  const onOptionChange = (e: React.SyntheticEvent<Element, Event>) => {
    const optionValue = e?.currentTarget?.textContent || ''
    setSearchTerm(optionValue)
    if (!optionValue) {
      setAutoCompleteOptions(defaultOptions)
    } else {
      if (optionValue.length && onAcceptSearchTerm) {
        onAcceptSearchTerm(optionValue, { searchOption: searchOption, sortOption: sortOption })
      }
    }
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault()
    autoCompleteForSearch(e.target.value)
  }

  const onInputKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (searchTerm.length && onAcceptSearchTerm) {
        onAcceptSearchTerm(searchTerm, { searchOption: searchOption, sortOption: sortOption })
      }
    }
  }

  const showOptionsModal = () => {
    setSearchOptionsData({ searchOption: searchOption, sortOption: sortOption })
    setModalDescription('Search Options')
    setShowModal(true)
  }

  const setShowModalProp = (show: boolean) => {
    if (searchTerm) autoCompleteForSearch(searchTerm)
    setShowModal(show)
  }

  const autoCompleteForSearch = (textValue: string) => {
    const req: AutoCompleteRequest = {
      searchTerm: textValue,
      database: SearchDatabaseTypes.GFDUniverse,
      matchOption: searchOption,
      sortOption: sortOption
    }
    autoComplete(req).then(
      (res) => {
        let autoValues: [any] = ['']
        res.map((a: any) => {
          autoValues.push(autoCompleteDataFactory(a, a))
        })
        autoValues.shift()
        setAutoCompleteOptions(autoValues)
      },
      () => { })
    setSearchTerm(textValue)
  }

  const modalProps = {
    showModal: showModal,
    setShowModal: setShowModalProp,
    modalDescription: modalDescription,
  }

  const searchOptionsProps = {
    setSearchOption: setSearchOption,
    setSortOption: setSortOption,
    searchOptionsData: searchOptionsData
  }

  return (
    <>
      <Tooltip title='Search Settings'>
        <CustomBtnBlue variant='contained' style={{ borderRadius: '5px 3px 0px 5px', width: '5%' }} onClick={showOptionsModal}>
          <SettingsIcon />
        </CustomBtnBlue>
      </Tooltip>

      <Autocomplete
        disablePortal
        id='universeQuickSearchBar'
        value={searchTerm}
        options={autoCompleteOptions}
        style={{ width: '100%' }}
        size='small'
        onChange={onOptionChange}
        clearOnBlur={false}
        renderInput={(params) => <TextField {...params}
          label='GFD Universe Quik Search: Use Search Options to refine results'
          value={searchTerm}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
        />}
      />

      <SearchOptionModal modalProps={modalProps} searchOptionsProps={searchOptionsProps} />
    </>
  )
}

export default AutoTracTickerSearch

