import { CSSProperties, ReactNode, useEffect, useState } from "react"
import { CAPERatioIndex } from "../../../../../Models/DataModels/Common/CAPERatioModel"
import { populateCAPERatioIndices } from "../../../../../Services/FieldPopulationService"
import { Alert, Autocomplete, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, styled } from "@mui/material"
import { CAPERatioRequest, CapeRequestTypes } from "../../../../../Models/DataModels/Requests/ToolsRequests"
import { MessageResponseTypes, MessageResponseValueType } from "../../../../../Models/DataModels/Responses/NotOKResponse"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { AppConfigurations } from "../../../../../Models/DataModels/Common/AppConfigurationsModel"
import moment from "moment"
import DownUpGraphIcon from "../../../../Icons/DownUpGraph"
import GraphData from "../../../../Icons/GraphDataIcon"
import DownloadIcon from "../../../../Icons/DownloadIcon"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { CustomBtnBlue, DownloadBtn, CheckboxBlue, CustomToggle, SecondaryThemeSiteThemeHoverButton } from "../../../../Common/GlobalSettings/CustomStyles"
import { ToolsAccesibilityProps } from "../../../../../Models/DataModels/Common/ToolsModel"
import React from "react"
import { globalStyles } from "../../../../Common/GlobalSettings/GlobalStyles"
import DateFormatModel, { DateFormatValue, DateFormatValueType } from "../../../../../Models/DataModels/Common/DateFormatModel"

export interface CAPERatioInputProps {
    viewChart: (request: CAPERatioRequest, comparisonRequest?: CAPERatioRequest) => void,
    download: (request: CAPERatioRequest, retainGraph: boolean) => void,
    requestInProgress: boolean,
    displayMessage: (message: string, type: MessageResponseValueType) => void
    resetMessage: () => void,
    setCapeRequests: (requests: CAPERatioRequest[]) => void,
    accessibilityProps: ToolsAccesibilityProps
}

export const CAPERatioInputs = ({
    viewChart,
    download,
    requestInProgress,
    displayMessage,
    resetMessage,
    setCapeRequests,
    accessibilityProps
}: CAPERatioInputProps) => {
    const tooltip = {
        CAPERatio: 'CAPE (Cyclically Adjusted Price to Earnings) Ratio is the average price to earnings ratio over a specific time period while adjusting for inflation.',
        excessCAPEYield: 'The Excess CAPE Yield (ECY) is determined by taking the inverse CAPE Ratio (1/CAPE) of a company or index and then subtracting the yield on the ten year bond of the home country for that company or index.',
        bullAndBearMarkets: 'A period in time where the stock market rises 50 percent from the previous market low (Bull) or drops 20 percent from the previous market high (Bear).',
        CAPEPeriod: 'The time period used to evaluate the average price to earnings in the CAPE Ratio formula.'
    }

    const yearOptions: { [key: string]: number } = {
        '2 Years': 2,
        '3 Years': 3,
        '5 Years': 5,
        '10 Years': 10,
        '20 Years': 20,
        '30 Years': 30,
        '50 Years': 50
    }

    const defaultCAPERatioIndex: CAPERatioIndex = { label: 'Select Country and Index to Calculate Ratio', value: '' }
    const defaultCompareCAPERatioIndex: CAPERatioIndex = { label: 'Select Country and Index to Compare Ratio', value: '' }

    const minimumDate = moment(AppConfigurations.minimumDate, AppConfigurations.dateFormat)
    const maximumDate = moment()

    const [CAPERatioIndices, setCAPERatioIndices] = useState<CAPERatioIndex[]>([defaultCAPERatioIndex])
    const [compareCAPERatioIndices, setCompareCAPERatioIndices] = useState<CAPERatioIndex[]>([defaultCompareCAPERatioIndex])
    const [CAPERatioIndex, setCAPERatioIndex] = useState<CAPERatioIndex>(defaultCAPERatioIndex)
    const [CAPEPeriod, setCAPEPeriod] = useState<number>(2)
    const [compareCAPERatio, setCompareCAPERatio] = useState<boolean>(false)
    const [compareCAPERatioIndex, setCompareCAPERatioIndex] = useState<CAPERatioIndex>(defaultCompareCAPERatioIndex)
    const [compareCAPEPeriod, setCompareCAPEPeriod] = useState<number>(2)
    const [excessCAPEYield, setExcessCAPEYield] = useState<boolean>(false)
    const [showBullAndBearMarkets, setShowBullAndBearMarkets] = useState<boolean>(false)

    const [startDate, setStartDate] = useState<moment.Moment | null>(null)
    const [endDate, setEndDate] = useState<moment.Moment | null>(null)
    const [startDateError, setStartDateError] = useState<string | null>(null)
    const [endDateError, setEndDateError] = useState<string | null>(null)

    const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
    const [dateFormat, setDateFormat] = useState(DateFormatValue.AmericanWithDashes)

    const renderDateFormat = () => {
        return Object.keys(DateFormatModel).map(
            (format: any, index: number) => <MenuItem key={format} value={DateFormatModel[format]}>{format}</MenuItem>
        )
    }

    useEffect(() => {
        populateCAPERatioIndices().then((response: CAPERatioIndex[]) => {
            setCAPERatioIndices([defaultCAPERatioIndex, ...response])
            setCompareCAPERatioIndices([defaultCompareCAPERatioIndex, ...response])
        },
            //Reject promise
            () => {
                console.log('rejected promise!')
            })
    }, [])

    const isValid = () => {
        if (!CAPERatioIndex || !CAPERatioIndex.value) {
            displayMessage('Select Country and Index to Calculate Ratio', MessageResponseTypes.Error)
            return false
        }

        if (compareCAPERatio) {
            if (!compareCAPERatioIndex || !compareCAPERatioIndex.value) {
                displayMessage('Select Country and Index to Compare Ratio', MessageResponseTypes.Error)
                return false
            }

            if (CAPERatioIndex.value === compareCAPERatioIndex.value && CAPEPeriod === compareCAPEPeriod) {
                displayMessage('Select a different Country and Index, or CAPE Period to Compare Ratio', MessageResponseTypes.Error)
                return false
            }
        }

        resetMessage()
        return true
    }

    const getDates = () => {
        const dates: any = {}
        if (startDate) {
            dates.startDate = startDate
        }
        if (endDate) {
            dates.endDate = endDate
        }
        return dates
    }

    const handleDataRequest = () => {
        if (!isValid()) return
        const dates = getDates()

        const request: CAPERatioRequest = {
            requestType: CapeRequestTypes.Data,
            isExcessCAPEYield: excessCAPEYield,
            parameterSetList: [
                {
                    index: CAPERatioIndex.value,
                    years: [CAPEPeriod],
                }
            ],
            fetchBearMarketEvents: showBullAndBearMarkets,
            ...dates,
            dateFormat: dateFormat
        }

        if (compareCAPERatio && compareCAPERatioIndex) {
            const compareRequest: CAPERatioRequest = {
                requestType: CapeRequestTypes.Data,
                isExcessCAPEYield: excessCAPEYield,
                parameterSetList: [
                    {
                        index: compareCAPERatioIndex?.value,
                        years: [compareCAPEPeriod]
                    }
                ],
                fetchBearMarketEvents: showBullAndBearMarkets,
                ...dates,
                dateFormat: dateFormat
            }
            setCapeRequests([request, compareRequest])
            viewChart(request, compareRequest)
        } else {
            setCapeRequests([request])
            viewChart(request)
        }
    }

    const handleDownloadRequest = () => {
        if (!isValid()) return

        const parameterSetList = [
            {
                index: CAPERatioIndex.value,
                years: [CAPEPeriod]
            }
        ]

        if (compareCAPERatio && compareCAPERatioIndex) {
            parameterSetList.push(
                {
                    index: compareCAPERatioIndex?.value,
                    years: [compareCAPEPeriod]
                }
            )
        }

        const request: CAPERatioRequest = {
            requestType: CapeRequestTypes.Download,
            isExcessCAPEYield: excessCAPEYield,
            parameterSetList,
            fetchBearMarketEvents: false,
            ...getDates(),
            dateFormat: dateFormat
        }

        download(request, false)
    }

    const renderYears = () => {
        return Object.keys(yearOptions).map(
            (option: string, index) => <CustomToggle size='small' key={index} value={yearOptions[option]}>{option}</CustomToggle>
        )
    }

    const updateStartDate = (inputDate: moment.Moment | null) => {
        resetMessage()
        setStartDate(inputDate)
    }

    const updateEndDate = (inputDate: moment.Moment | null) => {
        resetMessage()
        setEndDate(inputDate)
    }

    const validateDates = () => {
        return (!startDate
            || !endDate
            || !startDate.isValid()
            || !endDate.isValid()
            || startDate <= endDate)
    }

    const startDateErrorString = '"Start Date" must be less than or equal to "End Date"'

    const endDateErrorString = '"End Date" must be greater than or equal to "Start Date"'

    const invalidDateErrorString = 'Invalid date'

    const minimumStartDateErrorString = `"Start Date" cannot be less than minimum date available ${minimumDate?.format(AppConfigurations.dateFormat)}`

    const maximumStartDateErrorString = `"Start Date" cannot be greater than maximum date available ${maximumDate?.format(AppConfigurations.dateFormat)}`

    const minimumEndDateErrorString = `"End Date" cannot be less than minimum date available ${minimumDate?.format(AppConfigurations.dateFormat)}`

    const maximumEndDateErrorString = `"End Date" cannot be greater than maximum date available ${maximumDate?.format(AppConfigurations.dateFormat)}`

    const runStartDateChecks = () => {
        if (!startDate) {
            setStartDateError(null)
        } else if (!startDate.isValid()) {
            setStartDateError(invalidDateErrorString)
        } else if (startDate < minimumDate) {
            setStartDateError(minimumStartDateErrorString)
        } else if (!validateDates()) {
            setStartDateError(startDateErrorString)
        } else if (startDate > maximumDate) {
            setStartDateError(maximumStartDateErrorString)
        } else {
            setStartDateError(null)
        }
    }

    const runEndDateChecks = () => {
        if (!endDate) {
            setEndDateError(null)
        } else if (!endDate.isValid()) {
            setEndDateError(invalidDateErrorString)
        } else if (endDate > maximumDate) {
            setEndDateError(maximumEndDateErrorString)
        } else if (!validateDates()) {
            setEndDateError(endDateErrorString)
        } else if (endDate < minimumDate) {
            setEndDateError(minimumEndDateErrorString)
        } else {
            setEndDateError(null)
        }
    }

    useEffect(() => {
        if (requestInProgress || !setDisableSubmit) return
        const disable: boolean = Boolean(startDateError || endDateError)
        setDisableSubmit(disable)
    }, [startDateError, endDateError])

    useEffect(() => {
        runStartDateChecks()
        runEndDateChecks()
    }, [startDate, endDate])

    const DateOptions = {
        'StartDate': {
            label: '(Optional) Start Date',
            value: startDate,
            onChange: updateStartDate,
            dateError: startDateError
        },
        'EndDate': {
            label: '(Optional) End Date',
            value: endDate,
            onChange: updateEndDate,
            dateError: endDateError
        }
    }

    const renderDateOptions = () => {
        return Object.values(DateOptions).map(value =>
            <DatePicker
                key={value.label}
                label={value.label}
                value={value.value}
                minDate={minimumDate}
                maxDate={maximumDate}
                onChange={(newValue) => {
                    value.onChange(newValue as moment.Moment)
                }}
                sx={{ marginRight: '8px', fontWeight: 'bold' }}
                slotProps={{
                    textField: {
                        error: value.dateError !== null,
                        helperText: value.dateError
                    },
                    field: { clearable: true, onClear: () => value.onChange(null) },
                }}
            />
        )
    }

    const renderByDate = () => {
        return (<>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                {renderDateOptions()}
            </LocalizationProvider>
        </>)
    }

    const handleChangeDateFormat = (
        event: SelectChangeEvent<DateFormatValueType>,
        child: ReactNode,
    ) => {
        setDateFormat(event.target.value as DateFormatValueType)
    }

    const readOnlyProp: CSSProperties = (requestInProgress ?? false) ? { pointerEvents: 'none' } : {}

    return (
        <>
            <Grid container spacing={0} columns={3}>
                <Grid item sm={2} md={2} lg={1} flexGrow={1}>
                    <div style={Styles.headerWrapper}>
                        <DownUpGraphIcon style={Styles.customIcon}></DownUpGraphIcon>
                        <h3
                            title={tooltip.CAPERatio}
                            style={{ fontWeight: 'bold' }}
                        >CAPE Ratios
                        </h3>

                    </div>
                </Grid>
            </Grid>

            {accessibilityProps.loadRequestInProgress ?
                <IconButton aria-label='Checking access...' component='label'>
                    <CircularProgress style={globalStyles.circularProgress} title='Checking access...' aria-label='Checking access...' />
                </IconButton>
                :
                accessibilityProps.canUseCapeTool ?
                    <React.Fragment>
                        <div style={{ ...readOnlyProp }}>
                            <div style={{ marginTop: '20px' }}>
                                <FormControlLabel
                                    style={Styles.FormControlLabel}
                                    title={tooltip.excessCAPEYield}
                                    control={<CheckboxBlue
                                        checked={excessCAPEYield}
                                        onChange={(event, checked) => {
                                            resetMessage()
                                            setExcessCAPEYield(checked)
                                        }} />}
                                    label={
                                        <>
                                            <Typography>Excess CAPE Yield
                                                <Tooltip
                                                    style={{ marginLeft: '2px' }}
                                                    title={tooltip.excessCAPEYield}
                                                >
                                                    <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                                                </Tooltip>
                                            </Typography>
                                        </>
                                    }
                                    labelPlacement='end'
                                />

                                <FormControlLabel
                                    style={{ ...Styles.FormControlLabel }}
                                    title={tooltip.bullAndBearMarkets}
                                    control={<CheckboxBlue
                                        checked={showBullAndBearMarkets}
                                        onChange={(event, checked) => {
                                            resetMessage()
                                            setShowBullAndBearMarkets(checked)
                                        }} />}
                                    label={
                                        <>
                                            <Typography>Show Bull and Bear Market Events
                                                <Tooltip
                                                    style={{ marginLeft: '2px' }}
                                                    title={tooltip.bullAndBearMarkets}
                                                >
                                                    <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                                                </Tooltip>
                                            </Typography>
                                        </>
                                    }
                                    labelPlacement='end'
                                />
                            </div>

                            <Autocomplete
                                disablePortal
                                onChange={(e, value) => {
                                    resetMessage()
                                    setCAPERatioIndex(value || defaultCAPERatioIndex)
                                }}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                getOptionLabel={(option) => option.label || ''}
                                options={CAPERatioIndices}
                                value={CAPERatioIndex}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={'Select Country and Index to Calculate Ratio'}
                                        variant='outlined'
                                        margin='dense'
                                    />
                                )}
                            />

                            <FormLabel
                                id='yearLabel'
                                key='yearLabel'
                                style={Styles.FormLabel}
                                title={tooltip.CAPEPeriod}
                            >
                                <>
                                    <Typography>CAPE Period
                                        <Tooltip
                                            style={{ marginLeft: '2px' }}
                                            title={tooltip.CAPEPeriod}
                                        >
                                            <HelpOutlineIcon style={{ fill: '#8e95ce' }} />
                                        </Tooltip>
                                    </Typography>
                                </>
                            </FormLabel>
                            <ToggleButtonGroup
                                color='primary'
                                style={{ marginLeft: '8px', flexWrap: 'wrap' }}
                                value={CAPEPeriod}
                                exclusive
                                onChange={(e, value) => {
                                    resetMessage()
                                    if (value) setCAPEPeriod(value)
                                }}
                                aria-label='select CAPEPeriod'
                            >
                                {renderYears()}
                            </ToggleButtonGroup>

                            <div style={{ ...Styles.CompareOptionsBox }}>
                                <FormControlLabel
                                    style={{ ...Styles.FormControlLabel, fontWeight: 'bold' }}
                                    control={<CheckboxBlue
                                        checked={compareCAPERatio}
                                        onChange={(event, checked) => {
                                            resetMessage()
                                            setCompareCAPERatio(checked)
                                        }} />}
                                    label={'Compare Index'}
                                    labelPlacement='end'
                                />

                                <div style={compareCAPERatio ? {} : { display: 'none' }}>
                                    <Autocomplete
                                        disablePortal
                                        onChange={(e, value) => {
                                            resetMessage()
                                            setCompareCAPERatioIndex(value || defaultCompareCAPERatioIndex)
                                        }}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        getOptionLabel={(option) => option.label || ''}
                                        options={compareCAPERatioIndices}
                                        value={compareCAPERatioIndex}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={'Select Country and Index to Compare Ratio'}
                                                variant='outlined'
                                                margin='dense'
                                            />
                                        )}
                                    />

                                    <FormLabel
                                        id='compareYearLabel'
                                        key='compareYearLabel'
                                        style={Styles.FormLabel}
                                    >
                                        CAPE Period
                                    </FormLabel>
                                    <ToggleButtonGroup
                                        color='primary'
                                        style={{ marginLeft: '8px', marginTop: '4px', marginRight: '8px', marginBottom: '8px', flexWrap: 'wrap' }}
                                        value={compareCAPEPeriod}
                                        exclusive
                                        onChange={(e, value) => {
                                            resetMessage()
                                            if (value) setCompareCAPEPeriod(value)
                                        }}
                                        aria-label='select CAPEPeriod to compare'
                                    >
                                        {renderYears()}
                                    </ToggleButtonGroup>
                                </div>
                            </div>

                            <div>
                                {renderByDate()}
                            </div>
                            <div>
                                <FormControl variant='outlined' sx={{ minWidth: 200, marginTop: '8px', marginBottom: '8px' }}>
                                    <InputLabel id='date-format-select-label'>Date Format</InputLabel>
                                    <Select
                                        labelId='date-format-select-label'
                                        id='date-format-select'
                                        value={dateFormat}
                                        label='Date Format'
                                        onChange={handleChangeDateFormat}
                                    >
                                        {renderDateFormat()}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div>
                            <SecondaryThemeSiteThemeHoverButton
                                style={{ marginRight: '16px' }}
                                key={'graphKey'}
                                variant='contained'
                                onClick={handleDataRequest}
                                startIcon={<GraphData />}
                                {...(requestInProgress || disableSubmit ? { disabled: true } : {})}
                            >
                                Graph Data
                            </SecondaryThemeSiteThemeHoverButton>

                            <DownloadBtn
                                key={'downloadKey'}
                                variant='contained'
                                onClick={handleDownloadRequest}
                                startIcon={<DownloadIcon />}
                                {...(requestInProgress || disableSubmit ? { disabled: true } : {})}
                            >
                                Download Data
                            </DownloadBtn>

                            <IconButton title='Request in Progress'
                                aria-label='Request in Progress' component='label' sx={{
                                    p: '10px',
                                    color: '#1976d2',
                                    visibility: requestInProgress ? 'visible' : 'hidden'
                                }}>
                                <CircularProgress title='Request in Progress' aria-label='Request in Progress' />
                            </IconButton>
                        </div>
                    </React.Fragment>
                    : accessibilityProps.canUseCapeTool === null ?
                    <React.Fragment>
                        <Alert style={{ padding: '0px 0px 0px 8px' }}
                            severity='error'
                        >
                            Access check failed.
                        </Alert>
                    </React.Fragment> :
                    <React.Fragment>
                        <Alert style={{ padding: '0px 0px 0px 8px' }}
                            severity='error'
                        >
                            Sorry, the Cape Ratios Tool is only available to GFDatabase subscribers.
                        </Alert>
                    </React.Fragment>}
        </>
    )
}

const Styles = {
    CompareOptionsBox: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'lightgray',
        borderRadius: '4px',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '16px',
        paddingRight: '16px',
        marginBottom: '16px',
        marginTop: '16px'
    },

    FormControlLabel: {
        marginRight: '28px'
    },

    CheckBox: {
        paddingRight: '4px',
        margin: '0px',
        color: globalStyles.siteThemeColor
    },

    FormLabel: {
        color: 'rgb(33,37,41)',
        margin: '0px'
    },

    customIcon: {
        width: '30px',
        height: '30px',
        marginRight: '10px',
        color: '#bcb1e0'
    },

    headerWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        background: 'linear-gradient(90deg, #bcb1e0, #e5e0f8)',
        padding: '12px 20px 8px 20px',
        borderRadius: '40px',
        marginBottom: '8px'
    }
}