import { Box, Link } from '@mui/material'
import React from 'react'
import { helpPageStyles } from '../../HelpPageStyles'

const EZProxy = () => {
    return (
        <>
            <Box
                sx={helpPageStyles.heading}>
                <div>
                    <h2 style={{ textDecoration: 'underline' }}>EZProxy</h2>
                </div>
            </Box>
            <div style={{ textAlign: 'left' }}>
                <p>
                    In order for this site to work with EZProxy, Finaeon EZProxy stanza must be added to the organication's EZProxy config file.
                    Finaeon Stanza can be retrieved from the folllowing link: <Link href='https://help.oclc.org/Library_Management/EZproxy/EZproxy_database_stanzas/Database_stanzas_F/Finaeon' underline='always' target='_blank' rel='noreferrer'>EZ Proxy Finaeon Stanza</Link>
                </p>
            </div>
        </>
    )
}

export default EZProxy
