import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ResponsiveContainer, ComposedChart, CartesianGrid, Legend, ReferenceArea, Tooltip, XAxis, YAxis, Line } from 'recharts'
import { chartSizes } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import { CustomBtn } from '../../Common/GlobalSettings/CustomStyles'
import GraphDownloadOptions, { GraphDownloadOptionsProps } from '../../Common/Modals/GraphDownloadOptions/GraphDownloadOptions'
import { graphCompanyLogoSVG, rechartsSourceTextLabelObject } from '../../Common/Modals/GraphUtil'
import { ComponentMessageHandler, ComponentMessageHandlerProps } from '../../Common/Utility/ComponentMessageHandler'
import { addUnixTimeStampProperty, CustomizedDot, customTicks, CustomTooltip, replaceSpecialDotSeparator, unixTimestampDateFormatter } from './GraphAndChartUtil'
import GraphDateRangeSlider, { GraphDateRangeSliderProps } from './GraphDateRangeSlider'
import Zoom from '../../Icons/ZoomIcon'
import { ChartDefinition, ChartMetaData, ChartScaleTypes, ChartView } from '../../../Models/DataModels/Common/ToolsModel'
import { globalStyles } from '../../Common/GlobalSettings/GlobalStyles'
import { MessageResponse } from '../../../Models/DataModels/Responses/NotOKResponse'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import OutputFormatValue, { OutputFormatValueType } from '../../../Models/DataModels/Common/OutputFormatModel'
import { GraphRequest, CreateChartDownloadDataRequest } from '../../../Models/DataModels/Requests/GraphRequests'
import { FileDownloadRequest } from '../../../Models/DataModels/Requests/ToolsRequests'
import { CreateChartDownloadDataResponse } from '../../../Models/DataModels/Responses/GraphResponses'
import { graphByFileName, graphDownload } from '../../../Services/GraphService'
import { GFDToastError, GFDToastInfo } from '../../Common/Utility/GFDToastify'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import moment from 'moment'
import { downloadFile } from '../../../Services/ToolsService'
import { ScaleType } from 'recharts/types/util/types'
import { ErrorHandler, ErrorHandlerProps } from '../../Common/Utility/ErrorHandler'
import { getNextChartDarkColor, getNextChartLightColor } from '../../../Services/ColorService'
import LoadingScreen from '../../Common/Modals/LoadingScreen'

export interface SimpleGraphProps {
    seriesName: string,
    signOut: (logoutReason: LogoutReasonType) => void
}

let zoomedGraphRequest: GraphRequest | null = null

const SimpleGraph = ({
    seriesName,
    signOut
}: SimpleGraphProps) => {

    const originalGraphRequest: GraphRequest = {
        primaryTicker: seriesName
    }

    const [executeOnce, setExecuteOnce] = useState<boolean>(false)
    const [lastResponse, setLastResponse] = useState<ChartDefinition | null>(null)
    const [graphingOptions, setGraphingOptions] = useState<GraphRequest | null>(null)

    const [refAreaLeft, setRefAreaLeft] = useState<any>('')
    const [refAreaRight, setRefAreaRight] = useState<any>('')

    const [dataSource, setDataSource] = useState<any[]>([])
    const [metaDataArray, setMetaDataArray] = useState<ChartMetaData[]>([])

    const [zoomedResponse, setZoomedResponse] = useState<ChartDefinition | null>(null)

    const [displayScaleChangedMessage, setDisplayScaleChangedMessage] = useState<boolean>(false)
    const [chartMessages, setChartMessages] = useState<MessageResponse[]>([])

    const [sliderMinDate, setSliderMinDate] = useState<number>(0)
    const [sliderMaxDate, setSliderMaxDate] = useState<number>(0)
    const [sliderStart, setSliderStart] = useState<number>(0)
    const [sliderEnd, setSliderEnd] = useState<number>(0)

    const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)
    const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()

    const zoomOutButtonEnabled = () => {
        return Boolean(zoomedResponse)
    }

    const handleButtonStyle = () => {
        if (zoomOutButtonEnabled()) {
            return { backgroundColor: globalStyles.siteThemeColor, color: '#FFF' }
        } else {
            return globalStyles.disabledButton
        }
    }

    const createZoomGraphRequest = (graphStartDate: moment.Moment, graphEndDate: moment.Moment): GraphRequest | null => {
        const graphRequest: GraphRequest | null = structuredClone(graphingOptions)
        if (graphRequest?.settings) {
            graphRequest.settings.isCustomDate = true
            graphRequest.settings.startDate = graphStartDate.toISOString()
            graphRequest.settings.endDate = graphEndDate.toISOString()
        }
        return graphRequest
    }

    const zoom = () => {
        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            setRefAreaLeft('')
            setRefAreaRight('')
            return
        }

        let refStartDate: moment.Moment = moment(refAreaLeft)
        let refEndDate: moment.Moment = moment(refAreaRight)

        if (!refStartDate.isValid() || !refEndDate.isValid()) {
            return
        }

        if (refStartDate > refEndDate) {
            refStartDate = moment(refAreaRight)
            refEndDate = moment(refAreaLeft)
        }

        const graphRequest: GraphRequest | null = structuredClone(graphingOptions)
        if (graphRequest?.settings) {
            graphRequest.settings.isCustomDate = true
            graphRequest.settings.startDate = refStartDate.toISOString()
            graphRequest.settings.endDate = refEndDate.toISOString()
        }

        doGraphRequest(graphRequest)

        zoomedGraphRequest = graphRequest

        setRefAreaLeft('')
        setRefAreaRight('')
    }

    const zoomBySlider = (startDateUnixTimestamp: number, endDateUnixTimestamp: number) => {
        const sliderZoomStartDate: moment.Moment = moment(startDateUnixTimestamp)
        const sliderZoomEndDate: moment.Moment = moment(endDateUnixTimestamp)
        const sliderZoomGraphRequest: GraphRequest | null = createZoomGraphRequest(sliderZoomStartDate, sliderZoomEndDate)
        doGraphRequest(sliderZoomGraphRequest)
        zoomedGraphRequest = sliderZoomGraphRequest
    }

    const doGraphRequest = (graphRequest: GraphRequest | null) => {
        if (!graphRequest) {
            return
        }
        setShowLoadingModal(true)
        graphByFileName(graphRequest).then(
            (dateFilteredResponse: ChartDefinition) => {
                setChartData(dateFilteredResponse)
                setZoomedResponse(dateFilteredResponse)
                setShowLoadingModal(false)
                setChartMessages(dateFilteredResponse?.chartMessages || [])
            },
            (error: NotOKResponseModel) => {
                setErrorResponse(error)
                setShowLoadingModal(false)
                setChartMessages([])
            }
        )
    }

    const zoomOut = () => {
        setChartData(lastResponse)
        zoomedGraphRequest = null
        setZoomedResponse(null)
        setChartMessages(lastResponse?.chartMessages || [])
    }

    const setChartData = (chartDef: ChartDefinition | null) => {
        if (chartDef?.charts && chartDef.charts.length > 0 && chartDef.charts[0]?.dataSources?.length > 0) {
            const primaryChartDataPointsWithUnixtimeStampDate: any[] = addUnixTimeStampProperty(chartDef.charts[0].dataSources[0])
            setDataSource(primaryChartDataPointsWithUnixtimeStampDate)
            setMetaDataArray(chartDef.charts[0].metaDataArray)
        } else {
            setDataSource([])
            setMetaDataArray([])
        }
    }

    const determineScaleType = (): ScaleType => {
        let firstChartView: ChartView | null = null
        let requestObject: GraphRequest | null = null
        if (zoomedResponse) {
            firstChartView = zoomedResponse?.charts && zoomedResponse.charts.length > 0 && zoomedResponse.charts[0] ? zoomedResponse.charts[0] : null
            requestObject = zoomedResponse?.chartRequest || null
        } else {
            firstChartView = lastResponse?.charts && lastResponse.charts.length > 0 && lastResponse.charts[0] ? lastResponse.charts[0] : null
            requestObject = lastResponse?.chartRequest || null
        }
        const optionScaleType: ScaleType = requestObject?.settings?.scaleType === ChartScaleTypes.Log ? 'log' : 'linear'
        if (optionScaleType === 'log' && firstChartView?.scaleType === ChartScaleTypes.Linear) {
            return 'linear'
        }
        return optionScaleType
    }

    const generateYAxis = () => {
        const arrayOfJSXYAxis: JSX.Element[] = []

        const scaleType: ScaleType = determineScaleType()

        metaDataArray.forEach((metaData: ChartMetaData, chartIndex: number) => {
            const chartName: string = metaData.chartName
            const dataKey: string = `${chartName}.value`
            const isPrimaryScale: boolean = metaData.isPrimaryScale
            const yAxisIdValue: string = isPrimaryScale ? 'left' : 'right'
            const orientationValue: 'left' | 'right' = isPrimaryScale ? 'left' : 'right'

            let domainRange: any[] = ['auto', 'auto']

            if (chartIndex === 0) {
                if (scaleType === 'log') {
                    domainRange = ['auto', 'auto']
                } else {
                    if (zoomedResponse) {
                        const zoomedPrimaryScaleMin = zoomedResponse?.charts[0]?.graphPrimaryScaleMin && zoomedResponse?.charts[0]?.graphPrimaryScaleMin < 0 ? zoomedResponse?.charts[0]?.graphPrimaryScaleMin : 0
                        const zoomedPrimaryScaleMax = zoomedResponse?.charts[0]?.graphPrimaryScaleMax || 'dataMax'
                        domainRange = [zoomedPrimaryScaleMin, zoomedPrimaryScaleMax]
                    } else {
                        const primaryScaleMin = lastResponse?.charts && lastResponse.charts.length > 0 && lastResponse.charts[0] && lastResponse?.charts[0]?.graphPrimaryScaleMin && lastResponse?.charts[0]?.graphPrimaryScaleMin < 0 ? lastResponse?.charts[0]?.graphPrimaryScaleMin : 0
                        const primaryScaleMax = lastResponse?.charts && lastResponse.charts.length > 0 && lastResponse.charts[0] && lastResponse?.charts[0]?.graphPrimaryScaleMax || 'dataMax'
                        domainRange = [primaryScaleMin, primaryScaleMax]
                    }
                }
                arrayOfJSXYAxis.push(<YAxis key={`${chartName}YAxis${chartIndex}`} yAxisId={yAxisIdValue} orientation={orientationValue} scale={scaleType} dataKey={dataKey} domain={domainRange} interval={'preserveStartEnd'} allowDecimals={false} tickCount={3} tick={customTicks} />)
            }
        })
        return arrayOfJSXYAxis
    }

    const generateCharts = () => {
        const JSXChartsArray: JSX.Element[] = []
        metaDataArray.forEach((metaData: ChartMetaData, chartIndex: number) => {
            const chartName: string = metaData.chartName
            const chartDisplayName: string = replaceSpecialDotSeparator(chartName)
            const dataKey: string = `${chartName}.value`
            const series: string[] = graphingOptions?.comparison?.symbols ? graphingOptions.comparison.symbols : []
            const isPrimaryScale: boolean = metaData.isPrimaryScale
            const color = getNextChartDarkColor(chartIndex)
            const yAxisIdValue: string = isPrimaryScale ? 'left' : 'right'

            if (chartIndex === 0 && graphingOptions?.settings) {
                switch (graphingOptions.settings.chartType) {
                    default:
                        JSXChartsArray.push(<Line key={`${chartName}Line${chartIndex}`} yAxisId={yAxisIdValue} name={chartDisplayName} type='monotone' dataKey={dataKey} stroke={color} dot={<CustomizedDot />} connectNulls />)
                        break
                }
            }
        })

        return JSXChartsArray
    }

    const imageID: string = 'graphRef'

    const downloadDataFile = (format: OutputFormatValueType) => {
        const downloadGraphRequest: GraphRequest | null = zoomedGraphRequest || graphingOptions
        if (!downloadGraphRequest) {
            return
        }
        const graphCSVDownloadRequest: CreateChartDownloadDataRequest = {
            chartRequest: downloadGraphRequest,
            format: format
        }
        setShowLoadingModal(true)
        graphDownload(graphCSVDownloadRequest).then(
            (fileCreateResponse: CreateChartDownloadDataResponse) => {
                const downloadRequest: FileDownloadRequest = {
                    filename: fileCreateResponse.filename,
                    mimeType: fileCreateResponse.mimeType
                }
                downloadFile(downloadRequest).then(
                    async (fileDownloadResponse: any) => {
                        const b = await fileDownloadResponse.blob()
                        let downloadLink = document.createElement('a')
                        let url = URL.createObjectURL(b)
                        downloadLink.href = url
                        downloadLink.download = downloadRequest.filename
                        downloadLink.click()
                    },
                    //Reject promise
                    (notOKResponseModel: NotOKResponseModel) => {
                        setErrorResponse(notOKResponseModel)
                    }
                ).catch(err => GFDToastError(err))
                setShowLoadingModal(false)
            },
            (error: NotOKResponseModel) => {
                setErrorResponse(error)
                setShowLoadingModal(false)
            }
        )
    }

    const downloadCSV = () => {
        downloadDataFile(OutputFormatValue.CSV)
    }

    const downloadExcel = () => {
        downloadDataFile(OutputFormatValue.Excel)
    }

    const graphDownloadOptionsProps: GraphDownloadOptionsProps = {
        downloadCSV: downloadCSV,
        downloadExcel: downloadExcel,
        imageIDValue: imageID
    }

    const dateRangeSliderProps: GraphDateRangeSliderProps = {
        minUnixDate: sliderMinDate,
        maxUnixDate: sliderMaxDate,
        sliderStart,
        sliderEnd,
        zoom: zoomBySlider
    }

    const componentMessageHandlerProps: ComponentMessageHandlerProps = {
        messages: chartMessages,
        setMessages: setChartMessages,
        signOut: (logoutReason: LogoutReasonType) => { }
    }

    const errorHandlerProps: ErrorHandlerProps = {
        response: errorResponse,
        signOut: signOut
    }

    useEffect(() => {
        setExecuteOnce(true)
    }, [])

    useEffect(() => {
        if (executeOnce) {
            setShowLoadingModal(true)
            graphByFileName(originalGraphRequest).then(
                (dateFilteredResponse: ChartDefinition) => {
                    setLastResponse(dateFilteredResponse)
                    setChartData(dateFilteredResponse)
                    setZoomedResponse(dateFilteredResponse)
                    setShowLoadingModal(false)
                    setChartMessages(dateFilteredResponse?.chartMessages || [])
                },
                (error: NotOKResponseModel) => {
                    setErrorResponse(error)
                    setShowLoadingModal(false)
                    setChartMessages([])
                }
            )
            setExecuteOnce(false)
        }
    }, [executeOnce])

    useEffect(() => {
        let firstChartView: ChartView | null = null
        let requestObject: GraphRequest | null = null
        if (zoomedResponse) {
            firstChartView = zoomedResponse?.charts && zoomedResponse.charts.length > 0 && zoomedResponse.charts[0] ? zoomedResponse.charts[0] : null
            requestObject = zoomedResponse?.chartRequest || null
            if (dataSource.length > 1) {
                setSliderStart(dataSource[0].dateUnixTimeStamp)
                setSliderEnd(dataSource[dataSource.length - 1].dateUnixTimeStamp)
            }
        } else {
            firstChartView = lastResponse?.charts && lastResponse.charts.length > 0 && lastResponse.charts[0] ? lastResponse.charts[0] : null
            requestObject = lastResponse?.chartRequest || null
            if (dataSource.length > 1) {
                setSliderMinDate(dataSource[0].dateUnixTimeStamp)
                setSliderMaxDate(dataSource[dataSource.length - 1].dateUnixTimeStamp)
                setSliderStart(dataSource[0].dateUnixTimeStamp)
                setSliderEnd(dataSource[dataSource.length - 1].dateUnixTimeStamp)
            }
        }
        const optionScaleType: ScaleType = requestObject?.settings?.scaleType === ChartScaleTypes.Log ? 'log' : 'linear'
        if (optionScaleType === 'log' && firstChartView?.scaleType === ChartScaleTypes.Linear) {
            setDisplayScaleChangedMessage(true)
        }
    }, [dataSource])

    useEffect(() => {
        if (displayScaleChangedMessage) {
            GFDToastInfo('Scale automatically adjusted to Linear')
            GFDToastInfo('Note: Scale settings in Chart Options was set to Linear')
            setDisplayScaleChangedMessage(false)
        }
    }, [displayScaleChangedMessage])

    useEffect(() => {
        setChartData(lastResponse)
        setGraphingOptions(lastResponse?.chartRequest || null)
        zoomedGraphRequest = null
        setZoomedResponse(null)
        setChartMessages(lastResponse?.chartMessages || [])
    }, [lastResponse])

    return (
        <>
            <LoadingScreen showModal={showLoadingModal} setShowModal={setShowLoadingModal} />
            {(dataSource?.length > 0 || zoomedResponse) && (
                <Grid container columns={12} justifyContent='flex-end' sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    <Grid item md={4}>
                        <Grid container justifyContent='center'>
                            <Grid item>
                                <CustomBtn startIcon={<Zoom />} variant='contained' style={handleButtonStyle()} onClick={zoomOut} disabled={!zoomOutButtonEnabled()}>Zoom Out</CustomBtn>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4}>
                        <GraphDownloadOptions {...graphDownloadOptionsProps} />
                    </Grid>
                </Grid>
            )}
            <ComponentMessageHandler {...componentMessageHandlerProps} />
            <ResponsiveContainer id={imageID} height={chartSizes.medium} className='large-graph-container prevent-select'>
                <ComposedChart
                    width={1000}
                    height={600}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    data={dataSource}
                    barCategoryGap={0}
                    barGap={0}
                    onMouseDown={(e: any) => {
                        if (e && e.activeLabel) {
                            setRefAreaLeft(e.activeLabel)
                        }
                    }}
                    onMouseMove={(e: any) => {
                        if (e && e.activeLabel) {
                            refAreaLeft && setRefAreaRight(e.activeLabel)
                        }
                    }}
                    onMouseUp={(e: any) => {
                        zoom()
                    }}
                >
                    <CartesianGrid />
                    <XAxis type='number' domain={['dataMin', 'dataMax']} dataKey='dateUnixTimeStamp' scale='time' tickFormatter={unixTimestampDateFormatter} allowDuplicatedCategory={true} label={rechartsSourceTextLabelObject} allowDataOverflow />
                    {generateYAxis()}
                    <Legend
                        // onClick={handleLegendClick}
                        wrapperStyle={{
                            padding: '0 2rem',
                            overflow: 'auto',
                            maxHeight: 140
                        }}
                    />
                    {generateCharts()}
                    {dataSource.length > 0 && <Tooltip content={<CustomTooltip />} cursor={{ stroke: 'red', strokeWidth: 1 }} />}
                    {graphCompanyLogoSVG()}
                    {refAreaLeft && refAreaRight && (
                        <ReferenceArea
                            yAxisId='left'
                            x1={refAreaLeft}
                            x2={refAreaRight}
                            strokeOpacity={0.3}
                        />
                    )}
                </ComposedChart>
            </ResponsiveContainer>
            {(dataSource?.length > 0 || zoomedResponse) && (
                <ResponsiveContainer className='no-background'>
                    <div style={{ paddingLeft: 100, paddingRight: 50 }}>
                        <GraphDateRangeSlider {...dateRangeSliderProps} />
                    </div>
                </ResponsiveContainer>
            )}
            <ErrorHandler {...errorHandlerProps} />
        </>
    )
}

export default SimpleGraph
