import { Autocomplete, Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LabelValuePair } from '../../../Models/DataModels/Common/LabelValuePairModel'
import { SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'
import { AutoCompleteRequest } from '../../../Models/DataModels/Requests/SearchRequests'
import { autoCompleteLabelValues } from '../../../Services/SearchService'
import { BtnGraphSearch, SecondaryThemeSiteThemeHoverButton } from '../../Common/GlobalSettings/CustomStyles'

export interface GraphQuickSearchProps {
    graphSearchAction: (searchTerm: string) => void
}

const GraphQuickSearch = ({
    graphSearchAction
}: GraphQuickSearchProps) => {

    const [autoCompleteOptionsGraph, setAutoCompleteOptionsGraph] = useState<LabelValuePair[]>([])

    const [searchTermGraph, setSearchTermGraph] = useState<string>('')
    const [commitGraphSearch, setCommitGraphSearch] = useState<boolean>(false)

    const setValueForAutoComplete = (textValue: string) => {
        const req: AutoCompleteRequest = {
            searchTerm: textValue,
            database: SearchDatabaseTypes.GFDUniverse,
            matchOption: 'Contains',
            sortOption: 'Popular'
        }
        autoCompleteLabelValues(req).then((res) => {
            setAutoCompleteOptionsGraph(res)
        }, () => { })

        setSearchTermGraph(textValue)
    }

    useEffect(() => {
        if (commitGraphSearch) {
            graphSearchAction(searchTermGraph)
            setCommitGraphSearch(false)
        }
    }, [commitGraphSearch])

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            paddingBottom: 5
        }}>
            <SecondaryThemeSiteThemeHoverButton id='graphSearch' variant='contained' style={{ borderRadius: '5px 0px 0px 5px' }} onClick={() => { graphSearchAction(searchTermGraph) }} disabled={false}>
                Graph
            </SecondaryThemeSiteThemeHoverButton>
            <Autocomplete
                disablePortal
                id='graphQuikSearch'
                options={autoCompleteOptionsGraph}
                sx={{ width: '100%' }}
                size='small'
                onChange={(e, value: LabelValuePair | null) => {
                    if (value) {
                        setSearchTermGraph(value.value)
                        setCommitGraphSearch(true)
                    } else {
                        setSearchTermGraph('')
                    }
                }}
                renderInput={(params) => <TextField {...params}
                    label='GFD Graph Quik Search, Start Typing and Select an Option to Graph'
                    onChange={(e) => {
                        e.preventDefault()
                        setValueForAutoComplete(e.target.value)
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                        if (e.key === 'Enter') {
                            setCommitGraphSearch(true)
                        }
                    }}
                />}
                disabled={false}
            />
        </div>
    )
}

export default GraphQuickSearch
