import { Autocomplete, Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import SelectListSubheader from "../Common/Utility/ListSubHeader"
import SearchHelp from "./HelpSubComponents/SearchHelp"
import { useNavigate, useParams } from "react-router-dom"
import { useRef, useState } from "react"
import { pathParts } from "../../Models/DataModels/Common/RedirectionModel"
import NavigationIcon from '@mui/icons-material/Navigation'
import keywords from "../Common/Utility/HelpKeywords"
import GraphHelp from "./HelpSubComponents/GraphHelp"
import FinancialToolsHelp from "./HelpSubComponents/FinancialToolsHelp"
import AutoTracHelp from "./HelpSubComponents/AutoTracHelp"
import ActionsHelp from "./HelpSubComponents/ActionsHelp"
import { LogoutReasonType } from "../../Models/DataModels/Requests/AuthRequests"
import { RefreshResponse } from "../../Models/DataModels/Responses/AuthResponses"
import SessionTimer, { SessionTimerProps } from "../Common/Modals/SessionTimer/SessionTimer"
import { BtnHeaderOutlined, CustomFab } from "../Common/GlobalSettings/CustomStyles"
import ConfigHelp from "./HelpSubComponents/ConfigHelp"

export interface HelpProps {
  signOut: (logoutReason: LogoutReasonType) => void,
  processRefreshTokenResponse: (response: RefreshResponse) => void
}

const Help = ({
  signOut,
  processRefreshTokenResponse
}: HelpProps) => {

  const navigate = useNavigate()
  const { helpSection } = useParams()
  const { helpSubSection } = useParams()

  const [helpNavigation, setHelpNavigation] = useState<string>(pathParts.help.searchHelpItems.basic)
  const [helpTerm, setHelpTerm] = useState<any>({ label: '', option: '' })

  const menuNav = useRef<HTMLInputElement>(null)

  const sessionTimerProps: SessionTimerProps = {
    processRefreshTokenResponse,
    signOut: signOut
  }

  const onOptionChange = (event: React.SyntheticEvent, value: any) => {
    if (value?.value) {
      setHelpTerm(value?.label)
      navigate(value?.value)
    } else {
      setHelpTerm({ label: '', option: '' })
    }
  }

  const handleChange = (e: SelectChangeEvent) => {
    setHelpNavigation(e.target.value)
    navigate(e.target.value)
  }

  const executeScrollMenu = () => menuNav?.current?.scrollIntoView()

  const getSectionDropDownFirstEntry = (): string => {
    switch (helpSection) {
      case pathParts.help.searchHelp:
        return pathParts.help.searchHelpItems.basic
      case pathParts.help.graphing.trim():
        return pathParts.help.graphingSections.basic
      case pathParts.help.autoTrac.trim():
        return pathParts.help.autoTracSections.basic
      case pathParts.help.financialTools.trim():
        return pathParts.help.financialToolsSections.basic
      case pathParts.help.actions.trim():
        return pathParts.help.actionsSections.basic
      default:
        return pathParts.help.searchHelpItems.basic
    }
  }

  const onNavigateClick = () => {
    setHelpNavigation(getSectionDropDownFirstEntry())
    setHelpTerm({ label: '', option: '' })
    if (helpSection) {
      navigate(helpSection as string)
    } else {
      navigate(pathParts.help.helpDefault)
    }
    executeScrollMenu()
  }

  const renderHelpPage = () => {
    switch (helpSection) {
      case pathParts.help.searchHelp:
        return <SearchHelp subSection={helpSubSection}></SearchHelp>
      case pathParts.help.graphing.trim():
        return <GraphHelp subSection={helpSubSection || ''} />
      case pathParts.help.autoTrac.trim():
        return <AutoTracHelp subSection={helpSubSection || ''} />
      case pathParts.help.financialTools.trim():
        return <FinancialToolsHelp subSection={helpSubSection || ''} />
      case pathParts.help.actions.trim():
        return <ActionsHelp subSection={helpSubSection || ''} />
      case pathParts.help.config.trim():
        return <ConfigHelp subSection={helpSubSection || ''} />
      default:
        return <SearchHelp subSection={helpSubSection}></SearchHelp>
    }
  }

  return (
    <>
      <div style={{ height: document.documentElement.scrollHeight }}>
        <SessionTimer {...sessionTimerProps} />
        <Grid container columns={17} style={helpStyles.grid} ref={menuNav}>
          <Grid item md={3} style={helpStyles.grid}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id='searchSectionSelectLabel'><p style={{ fontSize: 18 }}><strong>Help Navigation</strong></p></InputLabel>
              <Select
                labelId='searchSectionSelectLabel'
                id='searchSectionSelect'
                size='small'
                value={helpNavigation}
                label='Search Sections'
                onChange={handleChange}
              >
                <SelectListSubheader>Search</SelectListSubheader>
                <MenuItem value={pathParts.help.searchHelpItems.basic}>Basic Searching Methods</MenuItem>
                <MenuItem value={pathParts.help.searchHelpItems.advanced}>Advanced Search Using Engine Options</MenuItem>
                <MenuItem value={pathParts.help.searchHelpItems.USUKStock}>US and UK Stock Search</MenuItem>
                <MenuItem value={pathParts.help.searchHelpItems.eventsInTime}>Events-In-Time</MenuItem>
                <MenuItem value={pathParts.help.searchHelpItems.constituentMembership}>Constituent Membership</MenuItem>
                <MenuItem value={pathParts.help.searchHelpItems.toolsSearch}>Using Tools to Find Series</MenuItem>

                <SelectListSubheader>Graphing</SelectListSubheader>
                <MenuItem value={pathParts.help.graphingSections.basic}>Basic Graph</MenuItem>
                <MenuItem value={pathParts.help.graphingSections.advanced}>Advanced Graphing with Options</MenuItem>
                <MenuItem value={pathParts.help.graphingSections.tools}>Tool Specific Graphs</MenuItem>
                <MenuItem value={pathParts.help.graphingSections.tips}>Tips and Tricks</MenuItem>

                <SelectListSubheader>AutoTrac</SelectListSubheader>
                <MenuItem value={pathParts.help.autoTracSections.basic}>Basic Workbooks and Download Queue</MenuItem>
                <MenuItem value={pathParts.help.autoTracSections.advanced}>Advanced Workbook</MenuItem>
                <MenuItem value={pathParts.help.autoTracSections.tips}>AutoTrac Tips and Tricks</MenuItem>

                <SelectListSubheader>Financial Tools</SelectListSubheader>
                <MenuItem value={pathParts.help.financialToolsSections.basic}>Basic Financial Tools</MenuItem>
                <MenuItem value={pathParts.help.financialToolsSections.CAPEratio}>CAPE Ratio Tool</MenuItem>
                <MenuItem value={pathParts.help.financialToolsSections.capitalization}>Capitalization</MenuItem>
                <MenuItem value={pathParts.help.financialToolsSections.currencyConverter}>Currency Converter</MenuItem>
                <MenuItem value={pathParts.help.financialToolsSections.famaFrench}>Fama-French Stock Screener</MenuItem>
                <MenuItem value={pathParts.help.financialToolsSections.myIndices}>Custom Indices</MenuItem>
                <MenuItem value={pathParts.help.financialToolsSections.yieldCurve}>Yield Curve</MenuItem>

                <SelectListSubheader>Actions</SelectListSubheader>
                <MenuItem value={pathParts.help.actionsSections.basic}>Basic Actions Overview</MenuItem>
                <MenuItem value={pathParts.help.actionsSections.toolbar}>Actions Toolbar</MenuItem>
                <MenuItem value={pathParts.help.actionsSections.quickActions}>Quick Actions</MenuItem>

                <SelectListSubheader>Config</SelectListSubheader>
                <MenuItem value={pathParts.help.configSections.ezproxy}>EZ Proxy</MenuItem>

              </Select>
            </FormControl>
          </Grid>
          <Grid item md={1} style={helpStyles.grid}>
          </Grid>
          <Grid item md={13} style={helpStyles.grid}>
            <Box sx={helpStyles.rowStyle}>
              <Autocomplete
                disablePortal
                id='universeQuickSearchBar'
                value={helpTerm}
                options={keywords}
                style={{ width: '50%' }}
                size='small'
                onChange={onOptionChange}
                clearOnBlur={false}
                renderInput={(params) => <TextField {...params}
                  label={'Search for key words in the help sections...'}
                />}
              />

              <Box>
              </Box>
              <Box sx={{ paddingLeft: 2 }}>
                <BtnHeaderOutlined variant={"outlined"} size='medium' endIcon={<OndemandVideoIcon />} onClick={() => {

                }}>
                  Video Tutorials
                </BtnHeaderOutlined>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomFab variant="extended" sx={helpStyles.sticky} onClick={onNavigateClick}>
          <NavigationIcon sx={{ mr: 1 }} />
          Navigate back to menu
        </CustomFab>
        {/* Navigation will jump to each section, and hyperlinks (which lead to diff parts of a section, basic search then advanced search etc..) will be used at the top of each section to navigate per section */}
        {renderHelpPage()}
      </div>
    </>
  )
}

const helpStyles = {
  rowStyle: {
    display: 'flex',
    flex: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  grid: {
    marginTop: '5px'
  },
  sticky: {
    position: 'sticky',
    top: 0,
    marginTop: 3
  }
}

export default Help
