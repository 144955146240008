import { Menu, MenuItem, IconButton } from '@mui/material'
import React, { useState } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import DownLoad from '../../../Icons/DownLoad'
import DownLoadGreen from '../../../Icons/DownLoadGreen'

enum SupportedImageTypes {
    PNG,
    JPEG,
    SVG
}

export interface GraphImageDownloadButtonProps {
    imageIDValue: string
}

const GraphImageDownloadButton = ({
    imageIDValue
}: GraphImageDownloadButtonProps) => {

    const [anchorSaveImageMenu, setAnchorSaveImageMenu] = useState<null | HTMLElement>(null)
    const openSaveImageMenu = Boolean(anchorSaveImageMenu)

    const imageNode: HTMLElement | null = document.getElementById(imageIDValue)
    const HTMLToImage = require('html-to-image')

    const createFileName = (name: string, imageType: SupportedImageTypes): string => {
        switch (imageType) {
            case SupportedImageTypes.PNG:
                return `${name}.png`
            case SupportedImageTypes.JPEG:
                return `${name}.jpeg`
            case SupportedImageTypes.SVG:
                return `${name}.svg`
            default:
                return name
        }
    }

    const downloadLink = (dataUrl: string, filename: string, imageType: SupportedImageTypes) => {
        const link = document.createElement('a')
        link.download = createFileName(filename, imageType)
        link.href = dataUrl
        link.click()
    }

    const downloadFile = (filename: string, imageType: SupportedImageTypes) => {
        switch (imageType) {
            case SupportedImageTypes.PNG:
                HTMLToImage.toPng(imageNode, {skipFonts: true})
                    .then((dataUrl: string) => {
                        downloadLink(dataUrl, filename, imageType)
                    })
                    .catch((err: any) => {
                        console.log(err)
                    })
                break
            case SupportedImageTypes.JPEG:
                HTMLToImage.toJpeg(imageNode, { quality: 0.95, skipFonts: true })
                    .then((dataUrl: string) => {
                        downloadLink(dataUrl, filename, imageType)
                    })
                    .catch((err: any) => {
                        console.log(err)
                    })
                break
            case SupportedImageTypes.SVG:
                HTMLToImage.toSvg(imageNode, {skipFonts: true})
                    .then((dataUrl: string) => {
                        downloadLink(dataUrl, filename, imageType)
                    })
                    .catch((err: any) => {
                        console.log(err)
                    })
                break
            default:
                break
        }
    }

    const handleSaveImageMenuMouseOver = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorSaveImageMenu(event.currentTarget)
    }

    const handleSaveImageMenuClose = () => {
        setAnchorSaveImageMenu(null)
    }
    
    return (
        <div>
            <IconButton
                id='graphImageDownloadButton'
                onMouseOver={handleSaveImageMenuMouseOver}
                size='small'
                sx={{ ml: 2 }}
                title='Download Image'
                aria-label='Download Image'
                aria-controls={openSaveImageMenu ? 'graphImageDownloadButton' : undefined}
                aria-haspopup='true'
                aria-expanded={openSaveImageMenu ? 'true' : undefined}
            >
                <DownLoadGreen style={{ width: '32px', height: '32px' }}></DownLoadGreen>
            </IconButton>
            <Menu
                id='saveImageMenu'
                aria-labelledby='graphImageDownloadButton'
                anchorEl={anchorSaveImageMenu}
                open={openSaveImageMenu}
                onClose={handleSaveImageMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{ onMouseLeave: handleSaveImageMenuClose }}
            >
                <MenuItem onClick={() => {
                    handleSaveImageMenuClose()
                    downloadFile('graph', SupportedImageTypes.PNG)
                }}>PNG</MenuItem>
                <MenuItem onClick={() => {
                    handleSaveImageMenuClose()
                    downloadFile('graph', SupportedImageTypes.JPEG)
                }}>JPG</MenuItem>
                <MenuItem onClick={() => {
                    handleSaveImageMenuClose()
                    downloadFile('graph', SupportedImageTypes.SVG)
                }}>SVG</MenuItem>
            </Menu>
        </div>
    )
}

export default GraphImageDownloadButton
