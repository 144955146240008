import WorkbookContents, { WorkbookDetailsProps } from './SubComponents/WorkbookContents'
import WorkbookSelection, { WorkbookSelectionProps } from './SubComponents/WorkbookSelection'
import { canAccessPowerDownloads, getWorkbookDetails } from '../../Services/AutoTracService'
import { CSSProperties, useEffect, useState } from 'react'
import { DownloadQueueEntry, DownloadSettingDictionary, DownloadSettingDictionarySerializer, TickerData, WorkbookDetails } from '../../Models/DataModels/Responses/AutoTracResponses'
import { getAuthTokenFromCookie, getUserInfoFromCookie } from '../../Services/CookieAccessService'
import { RedirectHome, RedirectLogin } from '../../Services/RedirectionService'
import { GFDToastError } from '../Common/Utility/GFDToastify'
import moment from 'moment'
import { DownloadSettingsDateFormat } from '../../Models/DataModels/Common/DownloadSettingsModel'
import { isAnonymous, UserInfo } from '../../Models/DataModels/Common/UserInfoModel'
import DownloadQueue, { DownloadQueueProps } from './SubComponents/DownloadQueue'
import { LogoutReasonType } from '../../Models/DataModels/Requests/AuthRequests'
import { PaginationRequest } from '../../Models/DataModels/Requests/SearchRequests'
import { SortFieldType, SortOrderType } from '../../Models/DataModels/Common/SortModel'
import { GFDTablePaginationProps } from '../Common/Utility/GFDTablePagination'
import { WorkbookRequest } from '../../Models/DataModels/Requests/AutoTracRequests'
import { SortProps } from '../Search/SubComponents/SearchResults'
import PowerDownloads, { PowerDownloadsProps } from './SubComponents/PowerDownloads'
import { RefreshResponse } from '../../Models/DataModels/Responses/AuthResponses'
import SessionTimer, { SessionTimerProps } from '../Common/Modals/SessionTimer/SessionTimer'
import { globalStyles } from '../Common/GlobalSettings/GlobalStyles'

export interface AutoTracProps {
  userInfo: UserInfo | null,
  checkIsTrial: () => boolean,
  signOut: (logoutReason: LogoutReasonType) => void,
  processRefreshTokenResponse: (response: RefreshResponse) => void
}

const oneMinuteInMilliseconds = 60 * 1000

const AutoTrac = ({ userInfo, checkIsTrial, signOut, processRefreshTokenResponse }: AutoTracProps) => {
  const [copiedDownloadQueueEntries, setCopiedDownloadQueueEntries] = useState<Map<number, DownloadQueueEntry>>(new Map<number, DownloadQueueEntry>())
  const [selectedWorkbook, setSelectedWorkbook] = useState<string>()
  const [workbookList, setWorkbookList] = useState<string[]>([])
  const [workbookDetails, setWorkbookDetails] = useState<TickerData[]>([])
  const [workbookDownloadSettingsDictionary, setWorkbookDownloadSettingsDictionary] = useState<DownloadSettingDictionary>()
  const [tickerCount, setTickerCount] = useState<number>(0)
  const [tickerDataCountTotal, setTickerDataCountTotal] = useState<number>(0)
  const [refreshWorkbookDetailsToggle, setRefreshWorkbookDetailsToggle] = useState<boolean>(true)
  const [expandAll] = useState<boolean>(true)
  const [seriesMinDate, setSeriesMinDate] = useState<moment.Moment | null>()
  const [seriesMaxDate, setSeriesMaxDate] = useState<moment.Moment | null>()
  const [triggerRefreshDownloadQueue, setTriggerRefreshDownloadQueue] = useState(false)
  const [triggerRefreshAndShowDownloadQueue, setTriggerRefreshAndShowDownloadQueue] = useState(false)

  const [pageNumberWorkbookList, setPageNumberWorkbookList] = useState(1)
  const [perPageCountWorkbookList, setPerPageCountWorkbookList] = useState(100)
  const [totalCountWorkbookList, setTotalCountWorkbookList] = useState<number>(0)
  const [sortProps, setSortProps] = useState<SortProps>({ sortField: SortFieldType.None, sortOrder: SortOrderType.None })

  const [clearMessagesToggle, setClearMessagesToggle] = useState(false)
  const [accessPowerDownloads, setAccessPowerDownloads] = useState(false)

  const messageToggleProps = {
    clearMessagesToggle,
    clearMessages: () => {
      setClearMessagesToggle(!clearMessagesToggle)
    }
  }

  const resetWorkbook = () => {
    setWorkbookDetails([])
    setWorkbookDownloadSettingsDictionary(undefined)
  }

  const displayError = (error: string) => {
    GFDToastError(error)
  }

  const resetTriggerRefreshDownloadQueue = () => {
    setTriggerRefreshDownloadQueue(false)
  }

  const resetTriggerRefreshAndShowDownloadQueue = () => {
    setTriggerRefreshAndShowDownloadQueue(false)
  }

  const getPaginationRequest = (resetPageNumber: boolean, requestTotalCount: boolean): PaginationRequest => {
    let page = pageNumberWorkbookList
    if (!page || page <= 0) {
      page = 1
    }

    let perPage = perPageCountWorkbookList || 100

    return {
      pageNumber: resetPageNumber ? 1 : page,
      perPageCount: perPage,
      isTotalCountRequested: requestTotalCount
    } as PaginationRequest
  }

  const getWorkbookRequest = (resetPageNumber: boolean, requestTotalCount: boolean): WorkbookRequest => {
    return {
      workbookName: selectedWorkbook || '',
      pagination: getPaginationRequest(resetPageNumber, requestTotalCount),
      sortField: sortProps.sortField,
      sortOrder: sortProps.sortOrder
    }
  }

  const processWorkbookResponse = (result: WorkbookDetails) => {
    setSeriesMinDate(moment(result.SeriesMinDate, DownloadSettingsDateFormat))
    setSeriesMaxDate(moment(result.SeriesMaxDate, DownloadSettingsDateFormat))

    if (result?.TotalDataCount || result?.TotalDataCount === 0) {
      setTickerDataCountTotal(result.TotalDataCount)
    }

    const tickerData: TickerData[] = result?.Metadata as TickerData[]
    setWorkbookDetails(tickerData)

    if (result?.Pagination) {
      if (pageNumberWorkbookList !== result?.Pagination?.PageNumber) {
        setPageNumberWorkbookList(result?.Pagination?.PageNumber)
      }

      if (perPageCountWorkbookList !== result?.Pagination?.PerPageCount) {
        setPerPageCountWorkbookList(result?.Pagination?.PerPageCount)
      }

      if ((result?.Pagination?.TotalCount || result?.Pagination?.TotalCount === 0) && totalCountWorkbookList !== result?.Pagination?.TotalCount) {
        setTotalCountWorkbookList(result?.Pagination?.TotalCount)
      }
    } else {
      setPageNumberWorkbookList(0)
      setTotalCountWorkbookList(0)
    }

    const downloadSettingsData: DownloadSettingDictionary = (result?.DictionarySerializer as DownloadSettingDictionarySerializer)?.dictionary
    setWorkbookDownloadSettingsDictionary(downloadSettingsData)
  }

  useEffect(() => {
    canAccessPowerDownloads().then(
      (result: any) => {
        setAccessPowerDownloads(result)
      },
      () => { }
    )

    const interval = setInterval(() => {
      console.log('refreshing download queue...')
      if (!triggerRefreshDownloadQueue) setTriggerRefreshDownloadQueue(true)
    }, oneMinuteInMilliseconds)

    return () => clearInterval(interval) // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  useEffect(() => {
    if (selectedWorkbook === null || selectedWorkbook?.trim() === '' || selectedWorkbook === undefined) {
      resetWorkbook()
      return
    }

    getWorkbookDetails(getWorkbookRequest(false, true))
      .then((result: WorkbookDetails) => {
        processWorkbookResponse(result)
      },
        //Reject promise
        () => {
          displayError('Failed to get workbook details!')
          resetWorkbook()
        })
  }, [refreshWorkbookDetailsToggle, selectedWorkbook, workbookList, pageNumberWorkbookList, perPageCountWorkbookList, sortProps])

  useEffect(() => {
    if (workbookDetails?.length > 0) {
      setTickerCount(workbookDetails.length)
    } else {
      setTickerCount(0)
      setTickerDataCountTotal(0)
    }
  }, [workbookDetails])

  const refreshWorkbookList = (workbookList: string[]) => {
    setWorkbookList(workbookList)
  }

  const refreshWorkbookDetails = () => {
    setRefreshWorkbookDetailsToggle(!refreshWorkbookDetailsToggle)
  }

  const selectWorkbook = (workbookName: string | undefined) => {
    if (workbookName !== selectedWorkbook) {
      setSelectedWorkbook(workbookName)
    }
  }

  const workbookSelectionProps: WorkbookSelectionProps = {
    userInfo,
    refreshWorkbookDetails,
    onRefreshWorkbookList: refreshWorkbookList,
    onSelectWorkbook: selectWorkbook,
    ExpandAll: expandAll,
    signOut,
    ...messageToggleProps,
    copiedDownloadQueueEntries
  }

  const pageData: GFDTablePaginationProps = {
    perPageCount: perPageCountWorkbookList,
    pageNumber: pageNumberWorkbookList,
    totalCount: totalCountWorkbookList,
    setPageNumber: setPageNumberWorkbookList,
    setPerPageCount: setPerPageCountWorkbookList
  }

  const sortData = {
    sortProps,
    setSortProps
  }

  const workbookDetailsProps: WorkbookDetailsProps = {
    WorkbookName: selectedWorkbook,
    RefreshWorkbookDetails: refreshWorkbookDetails,
    WorkbookData: workbookDetails,
    TickerCount: tickerCount,
    TickerDataCountTotal: tickerDataCountTotal,
    DownloadSettingDictionary: workbookDownloadSettingsDictionary,
    SeriesMinDate: seriesMinDate,
    SeriesMaxDate: seriesMaxDate,
    setTriggerRefreshDownloadQueue: (value: boolean) => {
      if (triggerRefreshAndShowDownloadQueue !== value) setTriggerRefreshAndShowDownloadQueue(value)
    },
    pageData,
    sortData,
    checkIsTrial,
    signOut,
    ...messageToggleProps
  }

  const auth: any = getAuthTokenFromCookie()
  if (!auth) {
    return RedirectLogin()
  }

  const user: UserInfo | null = getUserInfoFromCookie()
  if (!user || user?.isTrialUser || isAnonymous(user)) {
    return RedirectHome()
  }

  const downloadQueueProps: DownloadQueueProps = {
    SelectedWorkbook: selectedWorkbook,
    TriggerRefreshDownloadQueue: triggerRefreshDownloadQueue,
    ResetTriggerRefreshDownloadQueue: resetTriggerRefreshDownloadQueue,
    triggerRefreshAndShowDownloadQueue,
    resetTriggerRefreshAndShowDownloadQueue,
    checkIsTrial,
    signOut,
    ...messageToggleProps,
    copiedDownloadQueueEntries
  }

  const powerDownloadProps: PowerDownloadsProps = {
    refreshDownloadQueue: () => { if (!triggerRefreshAndShowDownloadQueue) setTriggerRefreshAndShowDownloadQueue(true) },
    signOut
  }

  const sessionTimerProps: SessionTimerProps = {
    processRefreshTokenResponse,
    signOut: signOut
  }

  return (
    <div style={{ ...Styles.Container, ...globalStyles.mainPageBody } as CSSProperties}>
      {accessPowerDownloads && <div style={Styles.PowerDownloads as CSSProperties}>
        <PowerDownloads {...powerDownloadProps} />
      </div>}
      <div style={Styles.Workbook as CSSProperties}>
        <div style={Styles.WorkbookSelection as CSSProperties}>
          <WorkbookSelection {...workbookSelectionProps} />
        </div>
        <div style={Styles.WorkbookDetails as CSSProperties}>
          <WorkbookContents {...workbookDetailsProps} />
        </div>
      </div>
      <div style={Styles.DownloadQueue as CSSProperties}>
        <DownloadQueue {...downloadQueueProps} />
      </div>
      <SessionTimer {...sessionTimerProps} />
    </div>
  )
}

const Styles = {
  Container: {
    width: '100%',
    height: 'calc(100vh - 64px - 16px)', // viewport height - header height - margin top and bottom
    margin: '0',
    display: 'flex',
    flexDirection: 'column'
  },
  PowerDownloads: {
    width: 'calc(100% - 8px)',
    border: '1px solid rgb(224, 224, 224)',
    marginLeft: '4px',
    marginRight: '4px',
    flexGrow: 0,
    flexBasis: 'auto'
  },
  Workbook: {
    width: 'calc(100% - 8px)',
    margin: '0px 4px 4px 4px',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1
  },
  WorkbookSelection: {
    width: 'calc(30% - 4px)',
    height: '100%',
    flexBasis: 'auto',
    marginRight: '4px',
    border: '1px solid rgb(224, 224, 224)',
  },
  WorkbookDetails: {
    height: '100%',
    flexGrow: 1,
    border: '1px solid rgb(224, 224, 224)',
  },
  DownloadQueue: {
    width: 'calc(100% - 8px)',
    border: '1px solid rgb(224, 224, 224)',
    marginLeft: '4px',
    marginRight: '4px',
    flexGrow: 0,
    maxHeight: '30%',
    flexBasis: 'auto'
  }
}

export default AutoTrac
