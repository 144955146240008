import React from "react"
import { ReactComponent as LockedSvgIcon } from "../../Images/svg/checkbox_locked.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const LockedCheckbox = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <LockedSvgIcon />
    </SvgIcon>
)

export default LockedCheckbox
