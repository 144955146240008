import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GFDatabaseRequest } from '../../../Models/DataModels/Requests/SearchRequests'
import { searchUniverse } from '../../../Services/SearchService'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { ErrorHandler, ErrorHandlerProps } from '../../Common/Utility/ErrorHandler'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import ExporttoList from '../../Icons/ExporttoListIcon'
import { FileDownloadRequest } from '../../../Models/DataModels/Requests/ToolsRequests'
import { downloadFileFromServer } from '../../../Services/DownloadService'
import { GFDToastSuccess } from '../../Common/Utility/GFDToastify'
import { SearchFieldType } from '../../../Models/DataModels/Common/SearchFieldType'
import { DateFormatValue, DateFormatValueType } from '../../../Models/DataModels/Common/DateFormatModel'
import { globalStyles } from '../../Common/GlobalSettings/GlobalStyles'

export interface SelectedSearchResultExportProps {
    selectedResults: number[],
    displayForActionOnly?: boolean,
    fireAction?: boolean,
    resetFireAction?: () => void,
    exportDateFormat?: DateFormatValueType
    signOut: (logoutReason: LogoutReasonType) => void
}

const SelectedSearchResultExport = ({
    selectedResults,
    displayForActionOnly,
    fireAction,
    resetFireAction,
    exportDateFormat,
    signOut
}: SelectedSearchResultExportProps) => {
    const isDisabled = (): boolean => {
        return (exportInProgress)
    }

    const [exportInProgress, setExportInProgress] = useState<boolean>(false)
    const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()

    useEffect(() => {
        if (fireAction) {
            exportSearchResults()
            if (resetFireAction) resetFireAction()
        }
    }, [fireAction])

    const exportSearchResults = () => {
        if (isDisabled() || !selectedResults || !selectedResults?.length) {
            return
        }

        const request: GFDatabaseRequest = {
            searchOptions: {
                matchOption: 'ExactMatch'
            },
            searchField: SearchFieldType.ID,
            searchTerm: selectedResults.join(','),
            dateFormat: exportDateFormat || DateFormatValue.AmericanWithDashes
        }

        setExportInProgress(true)

        _exportFromUniverse(request)
    }

    const errorHandlerProps: ErrorHandlerProps = {
        response: errorResponse,
        signOut: signOut
    }

    const processResponse = (downloadRequest: FileDownloadRequest) => {
        const onComplete = (result: boolean) => {
            if (result) GFDToastSuccess(`${downloadRequest?.filename} downloaded!`)
            setExportInProgress(false)
        }

        downloadFileFromServer(downloadRequest, processErrorResponse, onComplete)
    }

    const processErrorResponse = (notOKResponseModel: NotOKResponseModel | null) => {
        setExportInProgress(false)
        if (notOKResponseModel?.statusCode !== 499) {
            setErrorResponse(notOKResponseModel)
        }
    }

    const _exportFromUniverse = (searchRequest: GFDatabaseRequest) => {
        searchUniverse(searchRequest, true).then(
            (response: FileDownloadRequest) => {
                processResponse(response)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                processErrorResponse(notOKResponseModel)
            }
        )
    }

    const disabledProp = isDisabled() ? {
        disabled: true
    } : {}

    const renderDisplayForActionOnly = () => {
        return exportInProgress ? <CircularProgress title='Export in Progress' aria-label='Export in Progress' />
            :
            <ExporttoList
                style={{ fontSize: '1.25em' }}
            />
    }

    const renderDisplay = () => {
        return exportInProgress ?
            <Tooltip title='Export in Progress'>
                <IconButton aria-label='Export in Progress' component='label' style={{
                    color: '#1976d2'
                }}>
                    <CircularProgress sx={{ color: globalStyles.siteThemeColor }} title='Export in Progress' aria-label='Export in Progress' />
                </IconButton>
            </Tooltip>
            :
            <Tooltip title='Export Selected'>
                <IconButton
                    {...disabledProp}
                    onClick={exportSearchResults}
                    aria-label='Export Selected'
                >
                    <ExporttoList
                        style={{ fontSize: '1.25em' }}
                    />
                </IconButton>
            </Tooltip>
    }

    return <>
        <ErrorHandler {...errorHandlerProps} />
        {displayForActionOnly ?
            renderDisplayForActionOnly()
            :
            renderDisplay()
        }
    </>
}

export default SelectedSearchResultExport
