import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { configHelpParts, pathParts } from '../../../Models/DataModels/Common/RedirectionModel'
import EZProxy from './ConfigHelpSections/EZProxy'
import { Box, Link, List, ListItem } from '@mui/material'
import { helpPageStyles } from '../HelpPageStyles'

export interface ConfigHelpProps {
    subSection: string
}

const ConfigHelp = ({
    subSection
}: ConfigHelpProps) => {

    const ezProxySection = useRef<HTMLInputElement>(null)

    const navigate = useNavigate()

    const executeScrollEZProxy = () => ezProxySection?.current?.scrollIntoView()

    const subSectionSwitch = () => {
        switch (subSection) {
            case configHelpParts.configSections.ezproxy:
                executeScrollEZProxy()
                break
            default:
                break
        }
    }

    useEffect(() => {
        setTimeout(subSectionSwitch, 200);
    }, [subSection])

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                p: 1,
                m: 1,
                borderRadius: 1
            }}>
            <Box style={{ width: 1200 }}>
                <List>
                    <ListItem sx={helpPageStyles.pointer}>
                        <p><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Keywords:</span> EZ Proxy</p>
                    </ListItem>
                    <ListItem sx={helpPageStyles.pointer}>
                        <Link onClick={() => { navigate(pathParts.help.configSections.ezproxy) }}>1. EZ Proxy</Link>
                    </ListItem>
                </List>
                <div ref={ezProxySection}>
                    <EZProxy />
                </div>
            </Box>
        </Box>
    )
}

export default ConfigHelp
