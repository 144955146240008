import { SortFieldType, SortOrderType } from "../Common/SortModel"
import { PaginationResponse } from "./SearchResponses"

export interface WorkbookList {
  workbookNames: string[],
  pagination: PaginationResponse,
  searchTerm: string
}

export interface TickerData {
     id: number,
     symbol: string,
     description: string,
     dataCount: number,
     accessibleSeriesID?: number
}

export interface WorkbookDetails {
  Metadata: TickerData[]
  DictionarySerializer: DownloadSettingDictionarySerializer,
  SeriesMinDate?: string,
  SeriesMaxDate?: string,
  Pagination: {
    PerPageCount: number,
    PageNumber: number,
    TotalCount?: number
  },
  TotalDataCount: number,
  PageDataCount: number
}

export interface DownloadProperties {
  hasSecurities: boolean,
  hasCorporateActions: boolean,
  hasSplitAdjusted: boolean,
  hasInflationAdjusted: boolean,
  hasPerCapita: boolean,
  hasPercentGDP: boolean,
  seriesMinDate?: Date,
  seriesMaxDate?: Date
}

export interface DownloadSettingItem {
    key: string,
    value: string
}

export interface DownloadSettingDictionary {
    item: DownloadSettingItem[]
}

export interface DownloadSettingDictionarySerializer {
    dictionary: DownloadSettingDictionary
}

export enum StatusCode {
  Initialized = 0,
  InProcess = 1,
  Finished = 2,
  Failed = 3,
  All = -1
}

export const Statuses = [
  'Initialized',
  'Processing',
  'Finished',
  'Failed'
]

export interface DownloadQueueEntry {
  queueID: number,
  createTime: Date,
  startTime?: Date,
  finishTime?: Date,
  worksheetName: string,
  filePath: string,
  status: StatusCode,
  source?: string
}

export interface DownloadQueueResponse {
  worksheetQueue: DownloadQueueEntry[],
  pagination: PaginationResponse,
  sortField: SortFieldType,
  sortOrder: SortOrderType
}

export interface PowerDownload {
  id: number,
  shortName: string,
  longName: string
}

export interface PowerDownloadsResponse {
  powerDownloads: PowerDownload[]
}

export interface AvailableCurrencyConversionDate {
  targetCurrency: string,
  startDate?: Date,
  endDate?: Date,
}

export interface AvailableCurrencyConversionDatesResponse {
  availableCurrencyConversionDate: AvailableCurrencyConversionDate
}
