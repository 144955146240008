import { pathParts } from "../../../Models/DataModels/Common/RedirectionModel"

export const keywords = [
    //search
    { label: 'Search (search)', value: pathParts.help.searchHelpItems.basic },
    { label: 'Tools (search)', value: pathParts.help.searchHelpItems.toolsSearch },
    { label: 'Engine (search)', value: pathParts.help.searchHelpItems.advanced },
    { label: 'Find (search)', value: pathParts.help.searchHelpItems.basic },
    { label: 'Info (search)', value: pathParts.help.searchHelpItems.basic },
    { label: 'Ticker (search)', value: pathParts.help.searchHelpItems.basic },
    { label: 'Stocks (search)', value: pathParts.help.searchHelpItems.basic },
    { label: 'Constituent (search)', value: pathParts.help.searchHelpItems.constituentMembership },
    { label: 'Events (search)', value: pathParts.help.searchHelpItems.eventsInTime },
    { label: 'Options (search)', value: pathParts.help.searchHelpItems.advanced },
    { label: 'Universe (search)', value: pathParts.help.searchHelpItems.basic },
    { label: 'Filter (search)', value: pathParts.help.searchHelpItems.advanced },
    { label: 'UK (search)', value: pathParts.help.searchHelpItems.USUKStock },
    { label: 'US (search)', value: pathParts.help.searchHelpItems.USUKStock },
    { label: 'GFD (search)', value: pathParts.help.searchHelpItems.basic },
    { label: 'Membership (search)', value: pathParts.help.searchHelpItems.constituentMembership },

    // graphing
    { label: 'Basic Graphing (Graphing)', value: pathParts.help.graphingSections.basic },
    { label: 'Advanced Graphing with Options (Graphing)', value: pathParts.help.graphingSections.advanced },
    { label: 'Tools Specific Graphing (Graphing)', value: pathParts.help.graphingSections.tools },
    { label: 'Tips and tricks (Graphing)', value: pathParts.help.graphingSections.tips },

    // autoTrac
    { label: 'Basic Workbooks and Download Queue (AutoTrac)', value: pathParts.help.autoTracSections.basic },
    { label: 'Advanced Workbook (AutoTrac)', value: pathParts.help.autoTracSections.advanced },
    { label: 'Tips and tricks (AutoTrac)', value: pathParts.help.autoTracSections.tips },

    //financial tools
    { label: 'Tools (financial tools)', value: pathParts.help.financialToolsSections.basic },
    { label: 'CAPE Ratio (financial tools)', value: pathParts.help.financialToolsSections.CAPEratio },
    { label: 'Market Capitalization (financial tools)', value: pathParts.help.financialToolsSections.capitalization },
    { label: 'Currency Converter (financial tools)', value: pathParts.help.financialToolsSections.currencyConverter },
    { label: 'Fama-French Stock Screener (financial tools)', value: pathParts.help.financialToolsSections.famaFrench },
    { label: 'Custom Index (financial tools)', value: pathParts.help.financialToolsSections.myIndices },
    { label: 'Yield Curve Graph (financial tools)', value: pathParts.help.financialToolsSections.yieldCurve },

    //actions
    { label: 'Basic (actions)', value: pathParts.help.actionsSections.basic },
    { label: 'Toolbar (actions)', value: pathParts.help.actionsSections.toolbar },
    { label: 'QuickActions (actions)', value: pathParts.help.actionsSections.quickActions },

    //config
    { label: 'EZProxy (config)', value: pathParts.help.configSections.ezproxy }
]

export default keywords
