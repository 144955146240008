export const errorRedColor: string = '#e00000'

export const globalStyles = {
    siteThemeColor: '#4b4b4b', // Gray Black
    secondarySiteThemeColor: '#8348bd', // Dark Purple
    headerColor: 'white',
    headerFontColor: 'black',
    headerSelectedToolbarColor: 'gainsboro', // Gray looking color // #dcdcdc
    disabledSiteThemeColor: 'grey',
    companyCloverColor: '#04aa9a',
    siteThemeHoverBackgroundColor: '#04aa9a', // Teal Green, same as Finaeon Green 4 Leaves
    searchColor: '#04aa9a', // Teal Green, same as Finaeon Green 4 Leaves
    BtnHeaderEnabledDisabledColor: '#8348bd', // Dark Purple
    circularProgress: {
        height: '24px',
        width: '24px',
        color: '#1976d2'
    },
    disabledButton: {
        backgroundColor: 'grey', 
        color: '#FFF'
    },
    mainPageBody: {
        scroll: {
            overflow: 'auto'
        }
    }
}